import Lukas from '../img/humans/normal/lukas.png';
import Frederique from '../img/humans/normal/frederique.png';
import Kelly from '../img/humans/normal/kelly.png';

import LukasHover from '../img/humans/hover/lukas.png';
import FrederiqueHover from '../img/humans/hover/frederique.png';
import KellyHover from '../img/humans/hover/kelly.png';

import Q1H from '../img/answers/1/human.png';
import Q1B from '../img/answers/1/background.png';
import Q1F1 from '../img/answers/1/001.png';
import Q1F2 from '../img/answers/1/002.png';
import Q1F3 from '../img/answers/1/003.png';
import Q1F4 from '../img/answers/1/004.png';
import Q1F5 from '../img/answers/1/005.png';
import Q1F6 from '../img/answers/1/006.png';
import Q1F7 from '../img/answers/1/007.png';
import Q1F8 from '../img/answers/1/008.png';
import Q1F9 from '../img/answers/1/009.png';
import Q1F10 from '../img/answers/1/010.png';
import Q1F11 from '../img/answers/1/011.png';
import Q1F12 from '../img/answers/1/012.png';

import Q2H from '../img/answers/2/human.png';
// import Q2B from '../img/answers/2/human.png';
import Q2F0 from '../img/answers/2/000.png';
import Q2F1 from '../img/answers/2/001.png';
import Q2F2 from '../img/answers/2/002.png';
import Q2F3 from '../img/answers/2/003.png';
import Q2F4 from '../img/answers/2/004.png';
import Q2F5 from '../img/answers/2/005.png';
import Q2F6 from '../img/answers/2/006.png';
import Q2F7 from '../img/answers/2/007.png';
import Q2F8 from '../img/answers/2/008.png';

import Q3H from '../img/answers/3/human.png';
// import Q3B from '../img/answers/3/human.png';
import Q3F0 from '../img/answers/3/000.png';
import Q3F1 from '../img/answers/3/001.png';
import Q3F2 from '../img/answers/3/002.png';
import Q3F3 from '../img/answers/3/003.png';
import Q3F4 from '../img/answers/3/004.png';
import Q3F5 from '../img/answers/3/005.png';
import Q3F6 from '../img/answers/3/006.png';
import Q3F7 from '../img/answers/3/007.png';
import Q3F8 from '../img/answers/3/008.png';

import Q4H from '../img/answers/4/human.png';
// import Q4B from '../img/answers/4/human.png';
import Q4F0 from '../img/answers/4/000.png';
import Q4F1 from '../img/answers/4/001.png';
import Q4F2 from '../img/answers/4/002.png';
import Q4F3 from '../img/answers/4/003.png';
import Q4F4 from '../img/answers/4/004.png';
import Q4F5 from '../img/answers/4/005.png';
import Q4F6 from '../img/answers/4/006.png';
import Q4F7 from '../img/answers/4/007.png';
import Q4F8 from '../img/answers/4/008.png';
import Q4F9 from '../img/answers/4/009.png';

import Q5H from '../img/answers/5/human.png';
// import Q5B from '../img/answers/5/human.png';
import Q5F0 from '../img/answers/5/000.png';
import Q5F1 from '../img/answers/5/001.png';
import Q5F2 from '../img/answers/5/002.png';
import Q5F3 from '../img/answers/5/003.png';
import Q5F4 from '../img/answers/5/004.png';
import Q5F5 from '../img/answers/5/005.png';
import Q5F6 from '../img/answers/5/006.png';
import Q5F7 from '../img/answers/5/007.png';
import Q5F8 from '../img/answers/5/008.png';
import Q5F9 from '../img/answers/5/009.png';
import Q5F10 from '../img/answers/5/010.png';
import Q5F11 from '../img/answers/5/011.png';
import Q5F12 from '../img/answers/5/012.png';
import Q5F13 from '../img/answers/5/013.png';
import Q5F14 from '../img/answers/5/014.png';
import Q5F15 from '../img/answers/5/015.png';
import Q5F16 from '../img/answers/5/016.png';
import Q5F17 from '../img/answers/5/017.png';
import Q5F18 from '../img/answers/5/018.png';
import Q5F19 from '../img/answers/5/019.png';
import Q5F20 from '../img/answers/5/020.png';

import Q6H from '../img/answers/6/human.png';
// import Q6B from '../img/answers/6/human.png';
import Q6F0 from '../img/answers/6/000.png';
import Q6F1 from '../img/answers/6/001.png';
import Q6F2 from '../img/answers/6/002.png';
import Q6F3 from '../img/answers/6/003.png';
import Q6F4 from '../img/answers/6/004.png';
import Q6F5 from '../img/answers/6/005.png';
import Q6F6 from '../img/answers/6/006.png';
import Q6F7 from '../img/answers/6/007.png';
import Q6F8 from '../img/answers/6/008.png';
import Q6F9 from '../img/answers/6/009.png';
import Q6F10 from '../img/answers/6/010.png';
import Q6F11 from '../img/answers/6/011.png';
import Q6F12 from '../img/answers/6/012.png';
import Q6F13 from '../img/answers/6/013.png';

import Q7H from '../img/answers/7/human.png';
// import Q7B from '../img/answers/7/human.png';
import Q7F0 from '../img/answers/7/000.png';
import Q7F1 from '../img/answers/7/001.png';
import Q7F2 from '../img/answers/7/002.png';
import Q7F3 from '../img/answers/7/003.png';

import Q8H from '../img/answers/8/human.png';
// import Q8B from '../img/answers/8/human.png';
import Q8F0 from '../img/answers/8/000.png';
import Q8F1 from '../img/answers/8/001.png';
import Q8F2 from '../img/answers/8/002.png';
import Q8F3 from '../img/answers/8/003.png';
import Q8F4 from '../img/answers/8/004.png';
import Q8F5 from '../img/answers/8/005.png';
import Q8F6 from '../img/answers/8/006.png';
import Q8F7 from '../img/answers/8/007.png';
import Q8F8 from '../img/answers/8/008.png';
import Q8F9 from '../img/answers/8/009.png';
import Q8F10 from '../img/answers/8/010.png';
import Q8F11 from '../img/answers/8/011.png';
import Q8F12 from '../img/answers/8/012.png';
import Q8F13 from '../img/answers/8/013.png';
import Q8F14 from '../img/answers/8/014.png';

import Q9H from '../img/answers/9/human.png';
// import Q9B from '../img/answers/9/human.png';
import Q9F0 from '../img/answers/9/000.png';
import Q9F1 from '../img/answers/9/001.png';
import Q9F2 from '../img/answers/9/002.png';
import Q9F3 from '../img/answers/9/003.png';
import Q9F4 from '../img/answers/9/004.png';
import Q9F5 from '../img/answers/9/005.png';
import Q9F6 from '../img/answers/9/006.png';
import Q9F7 from '../img/answers/9/007.png';
import Q9F8 from '../img/answers/9/008.png';

export default {
    locale: 'fr',
    messages: {
        'meta.title': 'Le Quiz ITHQ',
        'meta.title_401': 'Error 401',
        'meta.title_403': 'Error 403',
        'meta.title_404': 'Error 404',
        'meta.title_500': 'Error 500',

        'errors.title_401': 'Error 401',
        'errors.description_401': 'You are not authorized to access this page.',
        'errors.title_403': 'Error 403',
        'errors.description_403': 'Access to this page is forbidden',
        'errors.title_404': 'Error 404',
        'errors.description_404': 'This page doesn’t exists',
        'errors.title_500': 'Error 500',
        'errors.description_500': 'There was an error',
        'errors.goto_home': 'Go to home',

        'content.next': 'Suivant',
        'content.students': 'Les diplômés',
        'content.start': 'Commencer',
        'content.question': 'À qui est-ce arrivé ?',

        'content.doors': 'Participe à nos portes ouvertes',
        'content.plate':
            'Tu te cherches une job pas plate comme celles de Lukas, Kelly et Frédérique?',
        'content.learn': 'Découvre nos programmes!',
        'content.restart': 'Recommencer',
        'content.play': 'jouer',
    },
    routes: {
        home: '/',
        page: '/:slug',
    },
    pages: [
        { id: 0, slug: 'home', type: 'home', color: 'orange' },
        {
            id: 1,
            slug: 'intro',
            type: 'intro',
            color: 'dual',
            text:
                'Tentez d’associer les anecdotes à la bonne personne. Attention! La vie des diplômés de l’ITHQ est pleine de surprises!',
            textA: 'Tentez d’associer les anecdotes à la bonne personne.',
            textB: 'Attention! La vie des diplômés de l’ITHQ est pleine de surprises!',
        },
        { id: 2, slug: 'students', type: 'students', color: 'orange', title: 'Les Diplômés' },
        { id: 3, slug: 'q1', type: 'question', color: 'teal', title: 'Question 1' },
        { id: 4, slug: 'a1', type: 'answer', color: 'purple' },
        { id: 5, slug: 'q2', type: 'question', color: 'navy', title: 'Question 2' },
        { id: 6, slug: 'a2', type: 'answer', color: 'green' },
        { id: 7, slug: 'q3', type: 'question', color: 'purple', title: 'Question 3' },
        { id: 8, slug: 'a3', type: 'answer', color: 'navy' },
        { id: 9, slug: 'q4', type: 'question', color: 'teal', title: 'Question 4' },
        { id: 10, slug: 'a4', type: 'answer', color: 'purple' },
        { id: 11, slug: 'q5', type: 'question', color: 'navy', title: 'Question 5' },
        { id: 12, slug: 'a5', type: 'answer', color: 'green' },
        { id: 13, slug: 'q6', type: 'question', color: 'purple', title: 'Question 6' },
        { id: 14, slug: 'a6', type: 'answer', color: 'navy' },
        { id: 15, slug: 'q7', type: 'question', color: 'teal', title: 'Question 7' },
        { id: 16, slug: 'a7', type: 'answer', color: 'purple' },
        { id: 17, slug: 'q8', type: 'question', color: 'navy', title: 'Question 8' },
        { id: 18, slug: 'a8', type: 'answer', color: 'green' },
        { id: 19, slug: 'q9', type: 'question', color: 'purple', title: 'Question 9' },
        { id: 20, slug: 'a9', type: 'answer', color: 'navy' },
        { id: 21, slug: 'ending', type: 'ending', color: 'green' },
    ],
    humans: [
        {
            id: 1,
            name: 'Kelly Decoste',
            brokenName: 'Kelly<br/>Decoste',
            img: {
                normal: Kelly,
                hover: KellyHover,
            },
            program: 'Gestion d’un établissement de restauration (technique)',
            bio:
                'Fille déterminée, Kelly est du genre à ne pas se laisser marcher sur les pieds. Fait inusité : elle a une collection de moutardes, condiment qu’elle dit manger à la cuillère!',
        },
        {
            id: 2,
            name: 'Frédérique Savard',
            brokenName: 'Frédérique<br/>Savard',
            img: {
                normal: Frederique,
                hover: FrederiqueHover,
            },
            program: 'Gestion touristique (technique)<br />&nbsp;',
            bio:
                'Débordante d’énergie, Frédérique est toujours positive et adore les rencontres. Elle est toutefois, de son propre aveu, un brin lunatique…',
        },
        {
            id: 3,
            name: 'Lukas Vallée-Valletta',
            brokenName: 'Lukas<br/>Vallée-Valletta',
            img: {
                normal: Lukas,
                hover: LukasHover,
            },
            program: 'Hautes études en gestion hôtelière internationale',
            bio:
                'Souriant et énergique, Lukas est un passionné qui sait relever les défis avec bonne humeur. Il est aussi un méga fan de basket!',
        },
    ],
    questions: [
        {
            hid: 1,
            images: {
                background: Q7H,
                human: Q7H,
                frames: [Q7F0, Q7F1, Q7F2, Q7F3],
            },
            question:
                'Une barmaid que j’avais embauchée est venue me demander comment on faisait un Gin Tonic. J’ai dû lui expliquer qu’un Gin Tonic, c’est du gin… pis du tonic!',
            answer:
                'Un client s’est aussi fâché parce qu’il voulait un Rhum & Coke sans rhum et qu’on lui servait du coke. La gestion en restauration, c’est aussi la gestion des clients!',
        },
        {
            hid: 1,
            images: {
                background: Q8H,
                human: Q8H,
                frames: [
                    Q8F0,
                    Q8F1,
                    Q8F2,
                    Q8F3,
                    Q8F4,
                    Q8F5,
                    Q8F6,
                    Q8F7,
                    Q8F8,
                    Q8F9,
                    Q8F10,
                    Q8F11,
                    Q8F12,
                    Q8F13,
                    Q8F14,
                ],
            },
            question:
                'Une cliente est venue dans notre restaurant qui est 100 % végétarien en nous disant qu’elle était allergique aux fruits et aux légumes. Elle a finalement dîné avec de l’alcool et du pain.',
            answer:
                'L’alcool a peut-être aidé, mais la cliente est repartie très satisfaite. Une bonne gérante s’assure toujours de satisfaire sa clientèle!',
        },
        {
            hid: 1,
            images: {
                background: Q9H,
                human: Q9H,
                frames: [Q9F0, Q9F1, Q9F2, Q9F3, Q9F4, Q9F5, Q9F6, Q9F7, Q9F8],
            },
            question:
                'Une cliente m’a demandé c’était quoi du ketchup. Elle n’avait jamais entendu parler de ketchup de sa vie, alors j’ai dû lui expliquer ce que c’était et lui faire goûter.',
            answer:
                'Étant une grande fan de condiments, je connaissais même l’histoire du ketchup, alors j’ai pu lui donner des détails! C’est important de bien connaître son métier!',
        },
        {
            hid: 2,
            images: {
                background: Q4H,
                human: Q4H,
                frames: [Q4F0, Q4F1, Q4F2, Q4F3, Q4F4, Q4F5, Q4F6, Q4F7, Q4F8, Q4F9],
            },
            question:
                'J’ai amené des clients à une plage portugaise très reculée. En route, un voyant s’est allumé sur le tableau de bord de notre van. Je l’ai ignoré en me disant que ça ne devait pas être important. Finalement, ce l’était : on est tombés en panne, et mes clients ont dû attendre 2 heures au soleil qu’une remorqueuse vienne nous sauver!',
            answer:
                'Heureusement, on a remonté le moral des clients en profitant de la situation pour faire des exercices et de la danse en ligne sous le soleil du Portugal. La gestion, c’est aussi gérer l’imprévu!',
        },
        {
            hid: 2,
            images: {
                background: Q5H,
                human: Q5H,
                frames: [
                    Q5F0,
                    Q5F1,
                    Q5F2,
                    Q5F3,
                    Q5F4,
                    Q5F5,
                    Q5F6,
                    Q5F7,
                    Q5F8,
                    Q5F9,
                    Q5F10,
                    Q5F11,
                    Q5F12,
                    Q5F13,
                    Q5F14,
                    Q5F15,
                    Q5F16,
                    Q5F17,
                    Q5F18,
                    Q5F19,
                    Q5F20,
                ],
            },
            question:
                'L’accès au Machu Picchu est très contrôlé. Une fois, après une visite, une cliente nous a fait part d’un petit problème : elle avait oublié son passeport au sommet du Machu Picchu!',
            answer:
                'Heureusement, j’ai eu l’idée d’aller au poste de police où un bon samaritain avait ramené le passeport. Fiou! Une bonne gestionnaire sait gérer l’urgence!',
        },
        {
            hid: 2,
            images: {
                background: Q6H,
                human: Q6H,
                frames: [
                    Q6F0,
                    Q6F1,
                    Q6F2,
                    Q6F3,
                    Q6F4,
                    Q6F5,
                    Q6F6,
                    Q6F7,
                    Q6F8,
                    Q6F9,
                    Q6F10,
                    Q6F11,
                    Q6F12,
                    Q6F13,
                ],
            },
            question:
                'À chacun de mes voyages, j’arrête dans un salon de tatouage et on se fait tatouer avec mes clients. Ma mère est découragée : à mon premier tatouage, je me suis fait tatouer une sardine!',
            answer:
                'Des clients se sont aussi fait tatouer des fleurs de coton. Travailler ET se créer des souvenirs (littéralement) indélébiles, c’est le top!',
        },
        {
            hid: 3,
            images: {
                background: Q1B,
                human: Q1H,
                frames: [Q1F1, Q1F2, Q1F3, Q1F4, Q1F5, Q1F6, Q1F7, Q1F8, Q1F9, Q1F10, Q1F11, Q1F12],
            },
            question:
                'J’ai monté les valises du président Trump à sa chambre… Il avait 45 valises! Ça a été un casse-tête!',
            answer:
                'Pour nous remercier, il nous a offert des boîtes de chocolats autographiées. Connaissez-vous beaucoup de programmes qui mènent à des métiers où le président des États-Unis vous fait des cadeaux?',
        },
        {
            hid: 3,
            images: {
                background: Q2H,
                human: Q2H,
                frames: [Q2F0, Q2F1, Q2F2, Q2F3, Q2F4, Q2F5, Q2F6, Q2F7, Q2F8],
            },
            question:
                'J’ai accueilli Novak Dkojovic à l’hôtel la fin de semaine où il a gagné la Coupe Rogers. Je l’ai recroisé dans un bar le soir où il fêtait sa victoire, et il nous a invités à célébrer avec lui!',
            answer:
                'Il m’a même payé le taxi pour retourner chez moi à Vaudreuil! Comme quoi la gestion hôtelière peut venir avec de GROS avantages sociaux!',
        },
        {
            hid: 3,
            images: {
                background: Q3H,
                human: Q3H,
                frames: [Q3F0, Q3F1, Q3F2, Q3F3, Q3F4, Q3F5, Q3F6, Q3F7, Q3F8],
            },
            question:
                'Un client est venu se plaindre parce qu’il avait trouvé des souris dans son bain… En allant garer sa voiture, j’ai trouvé une boîte à souliers pleine de souris. C’était lui qui les avait mis dans le bain pour avoir un rabais!',
            answer:
                'Heureusement, c’est mon superviseur qui a pris en charge la situation cette fois-là!',
        },
    ],
    endings: [
        {
            start: 0,
            end: 3,
            text:
                'Tu ne connais pas très bien les programmes de l’ITHQ… peut-être que la journée portes ouvertes pourrait t’aider à en savoir plus?',
        },
        {
            start: 4,
            end: 6,
            text:
                'C’est pas trop mal, mais il y a toujours moyen de se perfectionner. N’hésite pas à consulter le site de l’ITHQ pour en savoir davantage sur ses programmes!',
        },
        {
            start: 7,
            end: 10,
            text:
                'Selon ton pointage, tu sembles être fait(e) pour l’ITHQ! Ne tarde pas à t’inscrire dans l’un des 3 programmes de gestion!',
        },
    ],
    colors: {
        green: {
            light: '#034638',
            medium: '#04382b',
            dark: '#063d2f',
            veryDark: '#04261d',
        },
        teal: {
            light: '#559ea2',
            medium: '#58a6ae',
            dark: '#419896',
            veryDark: '#316666',
        },
        purple: {
            light: '#5b3250',
            medium: '#512a44',
            dark: '#44243b',
            veryDark: '#2d1828',
        },
        navy: {
            light: '#002e5d',
            medium: '#022c4f',
            dark: '#022542',
            veryDark: '#021f33',
        },
        olive: {
            light: '#76a068',
            medium: '#6b9560',
            dark: '#5b7c52',
            veryDark: '#384933',
        },
        blue: {
            light: '#5264d1',
            medium: '#5461c8',
            dark: '#4a5cc6',
            veryDark: '#2d3d9b',
        },
        orange: {
            light: '#e46a54',
            medium: '#e46a54',
            dark: '#e46a54',
            veryDark: '#e46a54',
        },
    },
};
