import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import useStartDrawAnimation from '../../hooks/useStartDrawAnimation';

import styles from '../../styles/icons/default.scss';

const propTypes = {
    ready: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    ready: false,
    className: null,
};

const LeQuiz = ({ ready, className }) => {
    const itemsRef = useRef([]);

    useStartDrawAnimation(ready, itemsRef);

    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="610.7px"
            height="125.2px"
            viewBox="0 0 610.7 125.2"
            className={classNames([styles.container, { [className]: className !== null }])}
            xmlSpace="preserve"
        >
            <path
                ref={el => {
                    itemsRef.current[1] = el;
                }}
                fill="none"
                d="M35.4,124.3l-34.1-51l11.4-7.6l26.9,40.1l22-14.7l7.3,10.9L35.4,124.3z"
            />
            <path
                ref={el => {
                    itemsRef.current[2] = el;
                }}
                fill="none"
                d="M78.2,97.4l-24-56.4l35.7-15.2l5.1,11.9l-23.2,9.9l4.6,10.7l21-9l4.7,11.1l-21,9l4.6,10.8l23.2-9.9l5.1,11.9
    		L78.2,97.4z"
            />
            <path
                ref={el => {
                    itemsRef.current[3] = el;
                }}
                fill="none"
                d="M173.3,7.5c17.2-1.2,32.6,10.3,34,29.7c0.6,8.2-1.5,15.3-5.5,20.8l8.1,7.7l-8.4,8.2l-8.1-7.8
    		c-4.5,2.9-9.9,4.7-15.6,5.1c-17.3,1.2-32.7-10.3-34.1-29.6C142.3,22.2,156,8.7,173.3,7.5z M176.8,57.8c2.1-0.1,4.1-0.6,6.1-1.5
    		l-9.7-9.2l8.5-8.2l9.7,9.2c1.3-2.8,1.9-6,1.6-10c-0.9-12.8-10.4-17.9-18.9-17.3c-8.5,0.6-17.3,7-16.4,19.8
    		C158.7,53.3,168.2,58.4,176.8,57.8z"
            />
            <path
                ref={el => {
                    itemsRef.current[4] = el;
                }}
                fill="none"
                d="M223.6,47.7l7.8-38.4l13.4,2.7l-7.6,37.3c-1.5,7.3,1.9,11.8,8.2,13c6.4,1.3,11.2-1.5,12.6-8.8l7.6-37.3
    		l13.4,2.7l-7.8,38.4c-3.1,15.1-15.3,20.9-28.5,18.2S220.5,62.8,223.6,47.7z"
            />
            <path
                ref={el => {
                    itemsRef.current[5] = el;
                }}
                fill="none"
                d="M280.4,84.7l22.3-57.1l12.9,5l-22.3,57.1L280.4,84.7z"
            />
            <path
                ref={el => {
                    itemsRef.current[6] = el;
                }}
                fill="none"
                d="M309.5,98.1l3.6-12.6l38.1-25.6l-27.2-7.8l3.6-12.5l45.2,13l-3.5,12.1l-39,25.9l29.4,8.5l-3.6,12.5L309.5,98.1
    		z"
            />
            <path
                ref={el => {
                    itemsRef.current[7] = el;
                }}
                fill="none"
                d="M377.5,114.6l0.3-13.1L408,67l-28.3-0.6l0.3-13.1l47,0.9l-0.3,12.6l-31,35l30.6,0.6l-0.3,13.1L377.5,114.6z"
            />
            <path
                ref={el => {
                    itemsRef.current[8] = el;
                }}
                fill="none"
                d="M445.3,113.8l-2.4-12.9l22.6-40l-27.8,5.2l-2.4-12.8l46.2-8.7l2.3,12.4l-23.1,40.6l30.1-5.7l2.4,12.8
    		L445.3,113.8z"
            />
            <path
                ref={el => {
                    itemsRef.current[9] = el;
                }}
                fill="none"
                d="M510,100.1l-4.5-12.4l15.9-43.1l-26.6,9.6L490.4,42l44.3-16l4.3,11.9l-16.4,43.8l28.8-10.4l4.4,12.3L510,100.1
    		z"
            />
            <path
                ref={el => {
                    itemsRef.current[10] = el;
                }}
                fill="none"
                d="M599.8,39.6l-8.4,4.8L567.9,9.2l13.7-7.8L599.8,39.6z M598,46.4c3.6-2.1,8.2-0.8,10.3,2.8
    		c2,3.5,0.7,8.1-2.9,10.2c-3.6,2.1-8.1,0.7-10.1-2.8C593.2,53,594.4,48.5,598,46.4z"
            />
        </svg>
    );
};

LeQuiz.propTypes = propTypes;
LeQuiz.defaultProps = defaultProps;

export default LeQuiz;
