import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import useDrawAnimation from '../../hooks/useDrawAnimation';

import styles from '../../styles/icons/centered.scss';

const propTypes = {
    ready: PropTypes.bool,
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    ready: false,
    color: 'rgba(255,209,0,0)',
    className: null,
};

const Bravo = ({ ready, color, className }) => {
    const iconRef = useRef([]);
    const itemsRef = useRef([]);
    useDrawAnimation(ready, iconRef, itemsRef);
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="553.1px"
            height="133.2px"
            viewBox="0 0 553.1 133.2"
            className={classNames([styles.container, { [className]: className !== null }])}
            xmlSpace="preserve"
        >
            <polygon
                ref={iconRef}
                fill="none"
                stroke={color}
                strokeWidth="2"
                strokeMiterlimit="10"
                points="105.5,2.1 50.3,57.3 21.4,28.4 2.1,47.7 50.3,95.9 58.6,87.5 69.6,76.6 124.8,21.4 "
            />
            <path
                ref={el => {
                    itemsRef.current[0] = el;
                }}
                fill={color}
                stroke={color}
                strokeWidth="2"
                strokeMiterlimit="10"
                d="M102.4,31.5c10.3-3.1,18.1,1.1,20.6,9.1c1.6,5.4-0.4,10.9-4.3,13.5c5.1-0.3,11,2.7,13,9.5
		c2.6,8.6-2,16.8-11.9,19.8l-21.1,6.4L82.8,37.4L102.4,31.5z M106.9,53c4.1-1.2,6.1-4.2,5-7.7c-1-3.4-4-4.9-8.6-3.5l-6.2,1.9
		l3.4,11.3L106.9,53z M114.4,74.6c4.5-1.4,6.5-4.5,5.4-8.3c-1.1-3.6-4.5-5.3-9.1-3.9l-7.3,2.2l3.7,12.3L114.4,74.6z"
            />
            <path
                ref={el => {
                    itemsRef.current[1] = el;
                }}
                fill={color}
                stroke={color}
                strokeWidth="2"
                strokeMiterlimit="10"
                d="M160.4,55.3l-4.3,0.3l1.6,20.3l-12.2,1l-4.3-54.5l21.6-1.7c10.8-0.9,18.3,6,19.1,15.7c0.6,7.4-3,13.3-9.5,16.2
		l12.8,21.2l-13.4,1.1L160.4,55.3z M162.5,44.6c5-0.4,7.5-3.4,7.2-7.2c-0.3-4.1-3.2-6.4-8.2-6l-7.2,0.6l1.1,13.2L162.5,44.6z"
            />
            <path
                ref={el => {
                    itemsRef.current[2] = el;
                }}
                fill={color}
                stroke={color}
                strokeWidth="2"
                strokeMiterlimit="10"
                d="M228.8,68.5L207.9,65l-5.7,10.3l-12.6-2.1l29.3-50.6l13.9,2.3l11.1,57.3l-13.1-2.2L228.8,68.5z M213.7,54.7
		l13.1,2.2l-3.3-19.7L213.7,54.7z"
            />
            <path
                ref={el => {
                    itemsRef.current[3] = el;
                }}
                fill={color}
                stroke={color}
                strokeWidth="2"
                strokeMiterlimit="10"
                d="M299.2,46.5l12,5l-39.4,42.9l-11.6-4.8l2.4-58.2l12.4,5.1l-2.5,40.6L299.2,46.5z"
            />
            <path
                ref={el => {
                    itemsRef.current[4] = el;
                }}
                fill={color}
                stroke={color}
                strokeWidth="2"
                strokeMiterlimit="10"
                d="M342.1,65.2c14.6,4.8,23.4,19.6,17.9,36.1c-5.5,16.4-21.4,22.9-35.9,18.1c-14.6-4.9-23.5-19.6-18-36
		C311.6,66.8,327.5,60.3,342.1,65.2z M327.9,107.9c7.2,2.4,16.5,0.1,20.1-10.7c3.6-10.8-2.5-18.3-9.7-20.7
		c-7.2-2.4-16.6-0.1-20.2,10.7C314.5,98.1,320.7,105.5,327.9,107.9z"
            />
            <path
                ref={el => {
                    itemsRef.current[5] = el;
                }}
                fill={color}
                stroke={color}
                strokeWidth="2"
                strokeMiterlimit="10"
                d="M400.6,74.6c15.4,0.2,28.2,11.6,28,28.9c-0.2,17.3-13.4,28.3-28.8,28.1c-15.4-0.2-28.3-11.6-28.1-28.9
		C372,85.4,385.2,74.4,400.6,74.6z M400,119.6c7.6,0.1,15.8-4.9,16-16.3c0.2-11.4-8-16.7-15.5-16.8c-7.6-0.1-15.9,5-16,16.4
		C384.3,114.3,392.4,119.5,400,119.6z"
            />
            <path
                ref={el => {
                    itemsRef.current[6] = el;
                }}
                fill={color}
                stroke={color}
                strokeWidth="2"
                strokeMiterlimit="10"
                d="M461.4,68.1c15-3.2,30.1,4.9,33.8,21.9c3.7,16.9-6.7,30.6-21.7,33.8c-15.1,3.3-30.2-4.9-33.8-21.8
		C435.9,85.1,446.3,71.4,461.4,68.1z M470.9,112.1c7.4-1.6,14.3-8.3,11.9-19.5c-2.4-11.2-11.5-14.5-18.9-12.9
		c-7.5,1.6-14.4,8.4-12,19.6S463.4,113.7,470.9,112.1z"
            />
            <path
                ref={el => {
                    itemsRef.current[7] = el;
                }}
                fill={color}
                stroke={color}
                strokeWidth="2"
                strokeMiterlimit="10"
                d="M544.2,81.7l-8,3.4l-17.2-33.5l12.9-5.5L544.2,81.7z M542,87.6c3.4-1.4,7.4,0.1,8.8,3.6
		c1.4,3.3-0.2,7.3-3.6,8.7c-3.4,1.4-7.3-0.2-8.7-3.5C537,93,538.6,89.1,542,87.6z"
            />
        </svg>
    );
};

Bravo.propTypes = propTypes;
Bravo.defaultProps = defaultProps;

export default Bravo;
