import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Human from './Human';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../styles/partials/humans.scss';

const propTypes = {
    items: AppPropTypes.humans.isRequired,
    color: PropTypes.string,
    sideways: PropTypes.bool,
    withHover: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string,
};

const defaultProps = {
    sideways: false,
    color: null,
    withHover: false,
    className: null,
};

const Humans = ({ items, color, sideways, withHover, onClick, className }) => {
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            {items.map(h => (
                <Human
                    className={styles.item}
                    key={`human-${h.id}`}
                    item={h}
                    color={color}
                    sideways={sideways}
                    withHover={withHover}
                    onClick={() => {
                        onClick(h.id);
                    }}
                />
            ))}
        </div>
    );
};

Humans.propTypes = propTypes;
Humans.defaultProps = defaultProps;

export default Humans;
