import { useEffect } from 'react';
import anime from 'animejs';

const useDrawAnimation = (ready, iconRef, itemsRef) => {
    const initialColor = 'rgba(255,209,0,0)';
    const endColor = 'rgba(255,209,0,1)';

    useEffect(() => {
        const items = itemsRef.current.concat(iconRef.current);
        anime.set(items, { fill: initialColor, stroke: initialColor });
        if (ready) {
            anime
                .timeline({
                    loop: false,
                    duration: 300,
                    easing: 'easeOutSine',
                })
                .add({
                    targets: iconRef.current,
                    stroke: endColor,
                    duration: 500,
                })
                .add({
                    targets: itemsRef.current,
                    fill: endColor,
                    duration: 300,
                    delay: anime.stagger(90),
                });
        }
        return () => {
            anime.set(items, { fill: initialColor, stroke: initialColor });
            anime.remove(items);
        };
    }, [ready]);

    return null;
};

export default useDrawAnimation;
