import { useEffect } from 'react';
import anime from 'animejs';

const useStartDrawAnimation = (ready, itemsRef) => {
    const initialColor = 'rgba(255,209,0,0)';
    const endColor = 'rgba(255,209,0,1)';

    useEffect(() => {
        if (ready) {
            anime
                .timeline({
                    loop: false,
                    duration: 120,
                    easing: 'easeOutSine',
                })
                .add(
                    {
                        targets: itemsRef.current,
                        stroke: endColor,
                        strokeDashoffset: [anime.setDashoffset, 0],
                        delay: anime.stagger(80),
                    },
                    0,
                )
                .add(
                    {
                        targets: itemsRef.current,
                        fill: endColor,
                        delay: anime.stagger(80),
                    },
                    200,
                );
        }
        return () => {
            const items = itemsRef.current;
            anime.set(items, { fill: initialColor, stroke: initialColor });
            anime.remove(items);
        };
    }, [ready]);

    return null;
};

export default useStartDrawAnimation;
