import React, { useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import anime from 'animejs';

import styles from '../../styles/icons/star.scss';

const propTypes = {
    color: PropTypes.string,
    ready: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    color: '#FFF',
    ready: true,
    className: null,
};

const Facebook = ({ color, ready, className }) => {
    const iconRef = useRef(null);
    useLayoutEffect(() => {
        anime.set(iconRef.current, { scale: 0 });
        if (ready) {
            anime
                .timeline({
                    loop: false,
                    duration: 500,
                    delay: 3000,
                    easing: 'easeOutSine',
                })
                .add({
                    targets: iconRef.current,
                    scale: 1,
                });
        } else {
            anime.set(iconRef.current, { scale: 0 });
        }
        return () => {
            anime.set([iconRef.current], { scale: 0 });
            anime.remove([iconRef.current]);
        };
    }, [ready]);

    return (
        <svg
            ref={iconRef}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="361.1px"
            height="358.5px"
            viewBox="0 0 361.1 358.5"
            className={classNames([styles.container, { [className]: className !== null }])}
            xmlSpace="preserve"
        >
            <g>
                <circle
                    fill="#022B1C"
                    stroke="#FFD100"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    cx="36.8"
                    cy="36.8"
                    r="36"
                />
                <g>
                    <circle fill="none" stroke="none" cx="36.8" cy="37.3" r="29" />
                    <path
                        fill={color}
                        d="M15.1,52.3L13,53.9l-0.5-0.7l5.1-3.8l1.4,1.9c0.7,0.9,0.5,2.1-0.3,2.7c-0.9,0.6-2,0.5-2.7-0.5L15.1,52.3z
			 M16.5,52.9c0.5,0.6,1.1,0.7,1.6,0.3c0.5-0.4,0.6-1,0.1-1.6l-0.8-1l-1.8,1.3L16.5,52.9z"
                    />
                    <path
                        fill={color}
                        d="M20.8,59.3l-2.2-1.8l-1.6,1l-0.7-0.6l6-3.4l0.8,0.6l-2,6.6l-0.7-0.6L20.8,59.3z M19.4,57.1l1.7,1.4l0.9-2.9
			L19.4,57.1z"
                    />
                    <path
                        fill={color}
                        d="M26.2,61.5l-0.9-0.4l-1,2.4l-0.8-0.4l2.5-5.9l2.2,1c1.1,0.5,1.5,1.6,1,2.5c-0.4,0.8-1.2,1.3-2.1,1l0.3,3.1
			l-0.9-0.4L26.2,61.5z M26.9,60.9c0.7,0.3,1.3,0.1,1.5-0.5c0.3-0.6,0-1.2-0.7-1.5l-1.2-0.5l-0.9,2L26.9,60.9z"
                    />
                    <path
                        fill={color}
                        d="M33.7,60.5L32.9,66L32,65.9l0.8-5.5l-2.1-0.3l0.1-0.8L36,60l-0.1,0.8L33.7,60.5z"
                    />
                    <path
                        fill={color}
                        d="M40.8,64.3L38,64.5l-0.5,1.8l-0.9,0.1l1.9-6.6l1-0.1l3.1,6.1L41.6,66L40.8,64.3z M38.2,63.7l2.2-0.2L39,60.7
			L38.2,63.7z"
                    />
                    <path
                        fill={color}
                        d="M49.8,63.3l-0.4-0.8c-0.1,0.6-0.5,1.3-1.5,1.7c-1.5,0.6-3.3,0.1-4.1-1.9c-0.8-2,0.2-3.7,1.7-4.3
			c1.3-0.5,2.6-0.2,3.3,0.7l-0.6,0.6c-0.6-0.7-1.5-1-2.4-0.6c-1.1,0.4-1.8,1.6-1.2,3.2s2,1.9,3,1.5c1.3-0.5,1.4-1.7,1.2-2.3
			l-2.1,0.8l-0.3-0.7l2.8-1.1l1.2,2.9L49.8,63.3z"
                    />
                    <path
                        fill={color}
                        d="M53.3,61.2l-3.9-5l3.1-2.4l0.5,0.6l-2.4,1.8l1.2,1.5l2.1-1.7l0.5,0.7l-2.1,1.7l1.2,1.5l2.4-1.8l0.5,0.6
			L53.3,61.2z"
                    />
                    <path
                        fill={color}
                        d="M58.4,56.8l-0.7-0.5l-1.4-5.9l-2.3,3l-0.7-0.5l2.9-3.8l0.7,0.5l1.4,5.9l2.3-3.1l0.7,0.5L58.4,56.8z"
                    />
                </g>
                <g id="UIoFPj.tif_1_">
                    <g>
                        <path
                            fill={color}
                            d="M39.6,20.8c1.6,0,3.1,0,4.7,0c0.4,0.1,0.5,0.4,0.5,0.8c0,1.4,0,2.7,0,4.1c0,0.6-0.2,0.8-0.8,0.8
				c-1,0-1.9,0-2.9,0.1c-0.3,0-0.7,0.1-1,0.3c-0.5,0.3-0.6,0.8-0.6,1.3c0,1,0,2.1,0,3.1c0,0,0,0.1,0,0.1c0.1,0,0.2,0,0.3,0
				c1.3,0,2.7,0,4,0c0.6,0,0.8,0.2,0.8,0.8c0,1.5,0,3,0,4.5c0,0.6-0.2,0.8-0.8,0.8c-1.3,0-2.7,0-4,0c-0.1,0-0.2,0-0.3,0
				c0,0.2,0,0.3,0,0.4c0,3.9,0,7.8,0,11.6c0,0.6-0.2,0.8-0.8,0.8c-1.5,0-3,0-4.6,0c-0.7,0-0.9-0.2-0.9-0.9c0-3.9,0-7.8,0-11.6
				c0-0.1,0-0.2,0-0.4c-0.1,0-0.2,0-0.4,0c-1.1,0-2.2,0-3.3,0c-0.6,0-0.8-0.2-0.8-0.8c0-1.5,0-3,0-4.5c0-0.6,0.2-0.8,0.8-0.8
				c1.1,0,2.2,0,3.3,0c0.1,0,0.2,0,0.3,0c0-0.2,0-0.3,0-0.4c0-1.3,0-2.7,0.1-4c0.2-3.1,2.2-5.3,5.2-6C38.9,20.9,39.3,20.8,39.6,20.8
				z"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

Facebook.propTypes = propTypes;
Facebook.defaultProps = defaultProps;

export default Facebook;
