import React, { useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';

import Background from '../partials/Background';
import Next from '../partials/Next';
import Label from '../partials/Label';

import Rectangle from '../buttons/Rectangle';

import Star from '../icons/Star';
import Facebook from '../icons/Facebook';
import Yoohoo from '../icons/Yoohoo';
import PasMal from '../icons/PasMal';
import Ouf from '../icons/Ouf';

import data from '../../data/root';

import { sendEvent } from '../../lib/analytics';

import { resetQuiz as resetQuizAction } from '../../actions/SiteActions';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../styles/pages/ending.scss';

const propTypes = {
    page: AppPropTypes.page.isRequired,
    visible: PropTypes.bool.isRequired,
    current: PropTypes.bool.isRequired,
    ready: PropTypes.bool.isRequired,
};

const EndingPage = ({ page: { id, color, endings }, visible, current, ready }) => {
    const { answers, questions } = useSelector(state => state.site);
    const [tracked, setTracked] = useState(false);
    const count = answers.reduce((acc, answer) => acc + answer.value, 0);
    const ending = endings.find(e => count >= e.start && count <= e.end);

    // Start again
    const dispatch = useDispatch();
    const resetQuiz = useCallback((q, value, h) => dispatch(resetQuizAction(q, value, h)), [
        dispatch,
    ]);

    // Scroll back to top
    const innerRef = useRef(null);
    useEffect(() => {
        if (visible && innerRef.current) {
            innerRef.current.scrollTop = 0;
        }
    }, [visible]);

    // Events
    const onClickDoors = useCallback(() => {
        sendEvent('click_link', 'interactions', 'Portes ouvertes');
    });

    const onClickProgrammes = useCallback(() => {
        sendEvent('click_link', 'interactions', 'Programmes');
    });

    const onClickFacebook = useCallback(() => {
        sendEvent('click_link', 'interactions', 'Facebook');
    });

    useEffect(() => {
        if (!tracked && visible && current && ready) {
            sendEvent('end_score', 'score', count, answers.length);
            setTracked(true);
        }
    }, [visible, current, ready, tracked]);

    let message = null;
    switch (count) {
        case 0:
        case 1:
        case 2:
        case 3:
            message = <Ouf className={styles.banner} ready={ready} />;
            break;
        case 4:
        case 5:
        case 6:
        case 7:
            message = <PasMal className={styles.banner} ready={ready} />;
            break;
        case 8:
        case 9:
        case 10:
        case 11:
            message = <Yoohoo className={styles.banner} ready={ready} />;
            break;
        default:
            message = <Ouf className={styles.banner} ready={ready} />;
            break;
    }
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.visible]: visible && current && ready,
                },
            ])}
        >
            <Background id={id} color={color} visible={visible} ready={current && ready} />
            <div className={styles.inner} ref={innerRef}>
                <div className={styles.score} style={{ color: data.colors[color].dark }}>
                    <h3 className={styles.text}>
                        <span className={styles.underline}>Total</span>
                        <span className={styles.big}>
                            {count} / {questions.length}
                        </span>
                    </h3>
                    <Star className={styles.star} ready={current && ready} />
                    <div className={styles.cover} />
                    <a
                        className={styles.facebook}
                        href="https://www.facebook.com/sharer/sharer.php?u=https://quizithq.urbania.ca"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={onClickFacebook}
                    >
                        <Facebook />
                    </a>
                </div>
                <div className={styles.bannerContainer}>{message}</div>
                <div className={styles.bottom}>
                    <div className={styles.group}>
                        <div className={styles.message}>
                            <p className={styles.text}>{ending.text}</p>
                        </div>
                        <div className={styles.buttons}>
                            <Rectangle
                                text={{ id: 'content.doors' }}
                                external
                                href="https://www.ithq.qc.ca/ecole/futurs-etudiants/venez-nous-rencontrer/?utm_source=urbania&utm_medium=paid&utm_term=quizz&utm_content=article&utm_campaign=Dialekta_Ecole_1mars2020"
                                onClick={onClickDoors}
                            />
                        </div>
                    </div>
                    <div className={styles.group}>
                        <div className={styles.message}>
                            <p className={styles.text}>
                                <br />
                                <Label>{{ id: 'content.plate' }}</Label>
                            </p>
                        </div>
                        <div className={styles.buttons}>
                            <Rectangle
                                text={{ id: 'content.learn' }}
                                external
                                href="https://www.ithq.qc.ca/admission/?utm_source=urbania&utm_medium=paid&utm_term=quizz&utm_content=article&utm_campaign=Dialekta_Ecole_1mars2020"
                                onClick={onClickProgrammes}
                            />
                        </div>
                    </div>
                </div>
                <Next to="0" text={{ id: 'content.restart' }} onClick={resetQuiz} />
            </div>
        </div>
    );
};

EndingPage.propTypes = propTypes;

export default EndingPage;
