import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useFonts } from '@folklore/fonts';
import { defineMessages, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import Logo from '../../img/logo.png';
import PageMeta from '../partials/PageMeta';
import Dots from '../partials/Dots';

import styles from '../../styles/layouts/main.scss';

const messages = defineMessages({
    metaTitle: {
        id: 'meta.title',
        defaultMessage: 'Le Quiz ITHQ',
    },
});

const propTypes = {
    page: PropTypes.object.isRequired, // eslint-disable-line
    pages: PropTypes.array.isRequired, // eslint-disable-line
    answers: PropTypes.array.isRequired, // eslint-disable-line
    children: PropTypes.node.isRequired,
    isPrerender: PropTypes.bool,
    fonts: PropTypes.shape({
        google: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
        custom: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
    }),
};

const defaultProps = {
    isPrerender: false,
    fonts: {
        // google: {
        //     families: ['Open Sans:400,600,700'],
        // },
        custom: {
            families: ['CircularStd'],
        },
    },
};

const MainLayout = ({ page, pages, answers, fonts, children, isPrerender }) => {
    const { loaded: fontsLoaded } = useFonts(fonts);

    const dots = pages
        .filter(p => p.type !== 'question' && p.type !== 'ending')
        .map(p => {
            if (p.type === 'answer') {
                const answer = answers.find(a => a.qid === p.id - 1);
                if (answer) {
                    return { filled: true };
                }
            }
            if (page.id > p.id) {
                return { filled: true };
            }
            return { filled: false };
        });

    const ready = fontsLoaded || isPrerender;
    const innerStyle = {
        opacity: ready ? 1 : 0,
    };

    return (
        <div className={styles.container} style={innerStyle}>
            <PageMeta title={messages.metaTitle} />
            {page.id > 0 ? (
                <Link className={styles.link} to="/">
                    <img className={styles.logo} src={Logo} alt="logo" />
                </Link>
            ) : null}
            {children}
            {page.id > 0 && page.id !== pages.length - 1 ? <Dots items={dots} /> : null}
            <div className={styles.cover} />
        </div>
    );
};

MainLayout.propTypes = propTypes;
MainLayout.defaultProps = defaultProps;

const WithStateContainer = connect(({ site: { isPrerender, pages, page, answers } }) => ({
    isPrerender: isPrerender || false,
    pages,
    answers,
    page,
}))(MainLayout);

export default injectIntl(WithStateContainer);
