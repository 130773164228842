import React, { useRef, useLayoutEffect, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import anime from 'animejs';

import Human from '../partials/Human';
import Next from '../partials/Next';
import Title from '../partials/Title';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../styles/pages/students.scss';

const propTypes = {
    page: AppPropTypes.page.isRequired,
    visible: PropTypes.bool.isRequired,
    current: PropTypes.bool.isRequired,
    ready: PropTypes.bool.isRequired,
};

const StudentsPage = ({ page: { humans }, visible, current, ready }) => {
    const itemsRef = useRef([]);
    useLayoutEffect(() => {
        anime.set(itemsRef.current, { opacity: 0 });
        if (ready) {
            anime
                .timeline({
                    delay: 2000,
                    duration: 1000,
                    easing: 'easeOutSine',
                })
                .add({
                    targets: itemsRef.current,
                    opacity: 1,
                    delay: anime.stagger(500),
                });
        }
        return () => {
            anime.remove([itemsRef.current]);
        };
    }, [ready]);

    // Scroll back to top
    const innerRef = useRef(null);
    useEffect(() => {
        if (visible && innerRef.current) {
            innerRef.current.scrollTop = 0;
        }
    }, [visible]);

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.visible]: visible && current && ready,
                },
            ])}
        >
            <div className={styles.inner} ref={innerRef}>
                <Title className={styles.title} title={{ id: 'content.students' }} />
                <div className={styles.humans}>
                    {humans.map((human, i) => (
                        <Human
                            className={styles.human}
                            key={`human-student-${human.id}`}
                            ref={el => {
                                itemsRef.current[i] = el;
                            }}
                            item={human}
                            withBio
                        />
                    ))}
                </div>
                <Next />
            </div>
        </div>
    );
};

StudentsPage.propTypes = propTypes;

export default StudentsPage;
