/* eslint-disable react/jsx-props-no-spreading, react/no-danger */
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import AnimatedHuman from './AnimatedHuman';
import Label from './Label';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../styles/partials/human.scss';

const propTypes = {
    item: AppPropTypes.human.isRequired,
    color: PropTypes.string,
    sideways: PropTypes.bool,
    withName: PropTypes.bool,
    withBio: PropTypes.bool,
    withHover: PropTypes.bool,
    onClick: PropTypes.func,
    className: PropTypes.string,
};

const defaultProps = {
    color: null,
    sideways: false,
    withName: true,
    withBio: false,
    withHover: false,
    onClick: null,
    className: null,
};

const Human = React.forwardRef(
    (
        {
            item: { id, name, brokenName, img, program, bio },
            color,
            sideways,
            withName,
            withBio,
            withHover,
            onClick,
            className,
        },
        ref,
    ) => {
        const page = useSelector(state => state.site.page);
        const onClickAnswer = useCallback(() => {
            onClick(id);
        });
        const [hover, setHover] = useState(false);

        const hoverIn = useCallback(() => {
            setHover(true);
        }, [setHover]);

        const hoverOut = useCallback(() => {
            setHover(false);
        }, [setHover]);

        return onClick ? (
            <Link
                className={classNames([
                    styles.container,
                    {
                        [styles.sideways]: sideways,
                        [styles.withHover]: withHover,
                        [className]: className !== null,
                    },
                ])}
                ref={ref}
                to={`/${page.id + 1}`}
                onClick={onClickAnswer}
                onMouseEnter={withHover ? hoverIn : null}
                onMouseLeave={withHover ? hoverOut : null}
            >
                <AnimatedHuman
                    className={styles.animatedHuman}
                    img={img}
                    hover={hover}
                    withHover={withHover && !sideways}
                    sideways={sideways}
                />
                {withName ? (
                    <>
                        <p
                            className={classNames([styles.name, styles.desktop])}
                            style={{ backgroundColor: color }}
                        >
                            {name}
                        </p>
                        <p
                            className={classNames([styles.name, styles.mobile])}
                            style={{ backgroundColor: color }}
                        >
                            <Label isHtml>{brokenName}</Label>
                        </p>
                    </>
                ) : null}
            </Link>
        ) : (
            <div
                className={classNames([
                    styles.container,
                    {
                        [className]: className !== null,
                    },
                ])}
                ref={ref}
            >
                <AnimatedHuman className={styles.animatedHuman} img={img} withFlash />
                {withName ? <h3 className={styles.bigName}>{name}</h3> : null}
                {withBio ? (
                    <p className={styles.program} dangerouslySetInnerHTML={{ __html: program }} />
                ) : null}
                {withBio ? <p className={styles.bio}>{bio}</p> : null}
            </div>
        );
    },
);

Human.propTypes = propTypes;
Human.defaultProps = defaultProps;

export default Human;
