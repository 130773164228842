import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import useEndDrawAnimation from '../../hooks/useEndDrawAnimation';

import styles from '../../styles/icons/centered.scss';

const propTypes = {
    ready: PropTypes.bool,
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    ready: false,
    color: 'rgba(255,209,0,0)',
    className: null,
};

const PasMal = ({ ready, color, className }) => {
    const itemsRef = useRef([]);
    useEndDrawAnimation(ready, itemsRef);
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="725.4px"
            height="143.3px"
            viewBox="0 0 725.4 143.3"
            className={classNames([styles.container, { [className]: className !== null }])}
            xmlSpace="preserve"
        >
            <path
                ref={el => {
                    itemsRef.current[0] = el;
                }}
                fill={color}
                stroke={color}
                strokeWidth="2"
                strokeMiterlimit="10"
                d="M40.9,108.8L55,130.6l-13.5,8.7L2.2,78.6l22.9-14.8c12.3-8,26.1-5.3,33.4,6c7.4,11.5,4.1,24.9-8.3,32.9
		L40.9,108.8z M41.3,92c5.1-3.3,6.7-8.6,3.7-13.3c-3.2-4.9-8.5-5.5-13.7-2.2l-7.9,5.1l10,15.5L41.3,92z"
            />
            <path
                ref={el => {
                    itemsRef.current[1] = el;
                }}
                fill={color}
                stroke={color}
                strokeWidth="2"
                strokeMiterlimit="10"
                d="M125.8,78.6l-26,10.2l0.5,15.6l-15.8,6.2l-1-77.2l17.4-6.8L152.4,84L136,90.4L125.8,78.6z M99.4,73.2l16.3-6.4
		L98.5,46.7L99.4,73.2z"
            />
            <path
                ref={el => {
                    itemsRef.current[2] = el;
                }}
                fill={color}
                stroke={color}
                strokeWidth="2"
                strokeMiterlimit="10"
                d="M190.1,27.6c-1-3.3-4.6-8.5-13.2-7.2c-6,0.9-9.2,5.2-8.7,9.1c0.5,3.4,3,5.6,8.1,5.8l9.9,0.4
		c14,0.5,22.3,8.2,23.8,18.4c1.7,11.2-6.1,24-23.1,26.5c-19.8,2.9-30.1-8.7-32.6-18.6l14.1-5.7c1.6,6.5,6.9,11.6,16.6,10.1
		c6.3-0.9,9.6-4.5,9-9c-0.5-3.6-3.8-5.8-8.7-6l-10.1-0.4c-12.4-0.5-21-7.5-22.6-18.1c-2-13.5,8.5-24.8,22.2-26.8
		c17.9-2.6,26.2,7.1,28.9,15.2L190.1,27.6z"
            />
            <path
                ref={el => {
                    itemsRef.current[3] = el;
                }}
                fill={color}
                stroke={color}
                strokeWidth="2"
                strokeMiterlimit="10"
                d="M317.8,89.7l11-46.7l-29.3,42.3l-12.7-3L279.2,32l-10.8,46.1l-14.9-3.5L270,4.2L290.4,9l8.2,52.1l29.5-43.2
		l21.5,5.1l-16.5,70.4L317.8,89.7z"
            />
            <path
                ref={el => {
                    itemsRef.current[4] = el;
                }}
                fill={color}
                stroke={color}
                strokeWidth="2"
                strokeMiterlimit="10"
                d="M396.4,106l-26.3-9.5l-9.9,12l-15.9-5.8L394.6,44l17.5,6.4l0.7,77.1l-16.5-6L396.4,106z M380.1,84.5l16.5,6
		L397,64L380.1,84.5z"
            />
            <path
                ref={el => {
                    itemsRef.current[5] = el;
                }}
                fill={color}
                stroke={color}
                strokeWidth="2"
                strokeMiterlimit="10"
                d="M484.1,119.1l-27.9-1.3l-5.9,14.4l-16.9-0.8L464,60.4l18.6,0.9l23.4,73.5l-17.5-0.8L484.1,119.1z M462.2,103.3
		l17.5,0.8l-7.5-25.4L462.2,103.3z"
            />
            <path
                ref={el => {
                    itemsRef.current[6] = el;
                }}
                fill={color}
                stroke={color}
                strokeWidth="2"
                strokeMiterlimit="10"
                d="M570.3,109.5l-27.5,5.2l-2.4,15.4l-16.6,3.1L537.2,57l18.3-3.4l39.8,66.1l-17.2,3.2L570.3,109.5z M545.4,99.2
		l17.2-3.2l-13.1-23L545.4,99.2z"
            />
            <path
                ref={el => {
                    itemsRef.current[7] = el;
                }}
                fill={color}
                stroke={color}
                strokeWidth="2"
                strokeMiterlimit="10"
                d="M615.4,112.8l-24.4-68l15.2-5.4l19.2,53.6l29.4-10.5l5.2,14.5L615.4,112.8z"
            />
            <path
                ref={el => {
                    itemsRef.current[8] = el;
                }}
                fill={color}
                stroke={color}
                strokeWidth="2"
                strokeMiterlimit="10"
                d="M710.1,46.5l-10,5.5l-27.3-41.7l16.2-9L710.1,46.5z M708,54.6c4.3-2.4,9.7-0.8,12.1,3.4
		c2.3,4.2,0.8,9.6-3.5,12c-4.3,2.4-9.6,0.8-11.9-3.4C702.2,62.3,703.7,57,708,54.6z"
            />
        </svg>
    );
};

PasMal.propTypes = propTypes;
PasMal.defaultProps = defaultProps;

export default PasMal;
