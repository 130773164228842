import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '../../styles/icons/default.scss';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

const LogoBig = React.forwardRef(({ color, className }, ref) => (
    <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="710.9px"
        height="473.9px"
        viewBox="0 0 710.9 473.9"
        className={classNames([styles.container, { [className]: className !== null }])}
        xmlSpace="preserve"
    >
        <g>
            <path
                fill={color}
                d="M559.1,330.5c-12.8,0-23.3,11.2-23.3,24.9c0,9.6,5.2,17.9,12.7,22.1l15.2-21.2c0,0,6.5,5.2,15.7,11
            c1.8-3.5,2.9-7.6,2.9-11.9C582.4,341.6,572,330.5,559.1,330.5"
            />
            <path
                fill={color}
                d="M473.9,237V0H237v237H0v237h710.9V237H473.9z M130.9,409.4H98.7v-108h32.2V409.4z M287.9,333.7h-30.2v75.7
            h-32.2v-75.7h-30v-32.2h92.4V333.7z M445.6,409.4h-32.2v-37.7h-28.8v37.7h-32.2v-108h32.2v38h28.8v-38h32.2V409.4z M619.5,415.9
            c-1-0.1-13-1.7-32.6-9.2c-0.8-0.3-1.7-0.7-2.6-1.2c-7.6,3.8-16.1,6-25.2,6c-31,0-56.1-25.2-56.1-56.2c0-31.1,25.1-56.2,56.1-56.2
            c31,0,56.1,25.2,56.1,56.2c0,9.6-2.4,18.6-6.6,26.4c6.9,2.6,10.7,3.3,10.8,3.3V415.9z"
            />
        </g>
    </svg>
));

LogoBig.propTypes = propTypes;
LogoBig.defaultProps = defaultProps;

export default LogoBig;
