import React, { useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import anime from 'animejs';

import styles from '../../styles/icons/default.scss';

const propTypes = {
    color: PropTypes.string,
    ready: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    color: '#FFD100',
    ready: false,
    className: null,
};

const QuestionMark = ({ color, ready, className }) => {
    const iconRef = useRef(null);

    useLayoutEffect(() => {
        anime.set(iconRef.current, { fill: 'none', stroke: color, scale: 0.9 });
        if (ready) {
            anime
                .timeline({
                    loop: true,
                    direction: 'alternate',
                    duration: 10000,
                    easing: 'linear',
                })
                .add({
                    targets: iconRef.current,
                    rotate: '2turn',
                });
        }
        return () => {
            const items = [iconRef.current];
            anime.set(items, { fill: 'none', stroke: color, scale: 0.9 });
            anime.remove(items);
        };
    }, [ready]);

    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="120.8px"
            height="102.7px"
            viewBox="0 0 62.8 102.7"
            className={classNames([styles.container, { [className]: className !== null }])}
            xmlSpace="preserve"
        >
            <g>
                <path
                    ref={iconRef}
                    fill="none"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    d="M33.5,74.7c-0.5-1.6-0.8-3-1.2-4.7c-2.1-9-0.4-16.6,6.4-23.9l5-5.5c3.9-4.2,5.4-9.7,4.1-15
    			c-1.9-8-8.9-14.3-19.8-11.8c-11.9,2.8-15,12.8-13,21.4c0.5,2.3,1.6,5.1,2.5,6.5L3.8,43.1c-1-1.8-1.8-3.9-2.3-6.4
    			c-2.9-12.5,2.6-30.1,23.6-35c19.1-4.4,33,6.5,36.2,20.4c2.5,10.9-1.7,19.8-8.4,27l-5.3,5.7c-3.6,3.8-4.2,9.2-3,14.5
    			c0.2,0.7,0.3,1.2,0.7,2.6L33.5,74.7z M41.8,83.8c4.9-1.1,10,2,11.1,6.9c1.1,4.9-2,9.8-6.9,11s-9.8-1.9-10.9-6.8
    			C33.9,90,36.9,85,41.8,83.8z"
                />
            </g>
        </svg>
    );
};

QuestionMark.propTypes = propTypes;
QuestionMark.defaultProps = defaultProps;

export default QuestionMark;
