import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// import anime from 'animejs';

import styles from '../../styles/icons/default.scss';

const propTypes = {
    color: PropTypes.string,
    filled: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    color: '#FFFFFF',
    filled: false,
    className: null,
};

const Dot = ({ color, filled, className }) => {
    const circleRef = useRef(null);
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="11.2px"
            height="11.2px"
            viewBox="0 0 11.2 11.2"
            className={classNames([styles.container, { [className]: className !== null }])}
            xmlSpace="preserve"
        >
            <circle
                ref={circleRef}
                fill={filled ? color : 'none'}
                stroke={color}
                strokeMiterlimit="10"
                cx="5.6"
                cy="5.6"
                r="5.1"
            />
        </svg>
    );
};

Dot.propTypes = propTypes;
Dot.defaultProps = defaultProps;

export default Dot;
