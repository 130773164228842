/* eslint-disable no-inner-declarations */
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../styles/partials/flipping-human.scss';

const propTypes = {
    img: PropTypes.object, // eslint-disable-line
    ready: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    img: { background: null, human: null, frames: [] },
    ready: true,
    className: null,
};

const FlippingHuman = ({ img, ready, className }) => {
    const backgroundRef = useRef(null);
    const humanRef = useRef(null);
    const itemsRef = useRef([]);
    const requestRef = useRef(null);
    const started = useRef(false);

    useEffect(() => {
        if (ready) {
            let i = 0;
            let previous = 0;
            let progress = 0;
            let start = null;

            function step(timestamp) {
                if (start === null) start = timestamp;
                progress = timestamp - start;
                if (progress > 100) {
                    start = null;
                    if (itemsRef.current[previous]) {
                        itemsRef.current[previous].style.opacity = 0;
                    }
                    if (itemsRef.current[i]) {
                        itemsRef.current[i].style.opacity = 1;
                    }
                    previous = i;
                    if (i >= img.frames.length - 1) {
                        i = 0;
                    } else {
                        i += 1;
                    }
                }
                if (ready && started.current) {
                    requestRef.current = requestAnimationFrame(step);
                }
            }

            if (!started.current) {
                requestRef.current = requestAnimationFrame(step);
                started.current = true;
            }
        }
        // else {
        //     if (requestRef.current) {
        //         console.log('KILL FAST');
        //         started.current = false;
        //         cancelAnimationFrame(requestRef.current);
        //     }
        // }
        return () => {
            if (requestRef.current) {
                // console.log('KILL');
                for (let i = 0; i < itemsRef.current.length; i += 1) {
                    if (itemsRef.current[i]) {
                        itemsRef.current[i].style.opacity = 0;
                    }
                }
                started.current = false;
                cancelAnimationFrame(requestRef.current);
            }
        };
    }, [ready, itemsRef.current, started.current]);

    return (
        <div
            ref={humanRef}
            className={classNames([
                styles.container,
                {
                    [styles.ready]: ready,
                    [className]: className !== null,
                },
            ])}
        >
            <img
                ref={backgroundRef}
                className={styles.background}
                src={img.background}
                alt="background"
            />
            <img ref={humanRef} className={styles.human} src={img.human} alt="human" />
            {img.frames.map((src, i) => (
                <img
                    key={`${src}-${i + 1}`}
                    ref={el => {
                        itemsRef.current[i] = el;
                    }}
                    className={styles.frame}
                    src={src}
                    alt={`alt-${src}`}
                />
            ))}
        </div>
    );
};

FlippingHuman.propTypes = propTypes;
FlippingHuman.defaultProps = defaultProps;

export default FlippingHuman;
