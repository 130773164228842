import React, { useRef, useLayoutEffect, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import anime from 'animejs';

import Circle from '../buttons/Circle';
import TextBlock from '../partials/Text';
import LeQuiz from '../icons/LeQuiz';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../styles/pages/intro.scss';

const propTypes = {
    page: AppPropTypes.page.isRequired,
    visible: PropTypes.bool.isRequired,
    current: PropTypes.bool.isRequired,
    ready: PropTypes.bool.isRequired,
};

const IntroPage = ({ page: { text, textA, textB }, visible, current, ready }) => {
    const playRef = useRef(null);

    useLayoutEffect(() => {
        anime.set(playRef.current, { translateY: '-150vh', rotate: -180 });
        if (ready) {
            anime
                .timeline({
                    loop: false,
                    delay: 1400,
                    easing: 'spring(1, 300, 30, 20)',
                })
                .add({
                    targets: playRef.current,
                    translateY: 0,
                    rotate: 0,
                    duration: 1500,
                });
        }
        return () => {
            anime.remove([playRef.current]);
        };
    }, [ready]);

    // Scroll back to top
    const innerRef = useRef(null);
    useEffect(() => {
        if (visible && innerRef.current) {
            innerRef.current.scrollTop = 0;
        }
    }, [visible]);

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.visible]: visible && current && ready,
                },
            ])}
        >
            <div className={styles.inner} ref={innerRef}>
                <LeQuiz className={styles.logo} id="intro" ready={current && ready} />
                <div className={styles.single}>
                    <TextBlock text={text} withBottomBar />
                </div>
                <div className={styles.dual}>
                    <TextBlock className={styles.text} text={textA} withBottomBar />
                    <TextBlock className={styles.text} text={textB} withBottomBar />
                </div>
                <div className={styles.button}>
                    <Circle
                        className={styles.circle}
                        ref={playRef}
                        text={{ id: 'content.play' }}
                        href="/2"
                    />
                </div>
            </div>
        </div>
    );
};

IntroPage.propTypes = propTypes;

export default IntroPage;
