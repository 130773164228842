import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from './Button';
import Label from '../partials/Label';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../styles/buttons/rectangle.scss';

const propTypes = {
    text: AppPropTypes.text,
    href: PropTypes.string,
    external: PropTypes.bool,
    isActive: PropTypes.bool,
    color: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
};

const defaultProps = {
    text: null,
    href: null,
    external: false,
    isActive: false,
    color: null,
    onClick: null,
    className: null,
};

const Rectangle = ({ text, href, external, isActive, color, onClick, className }) => (
    <div className={classNames([styles.container, { [className]: className !== null }])}>
        <Button
            className={classNames([styles.inner, { [styles.isActive]: isActive }])}
            href={href}
            external={external}
            style={{ color }}
            onClick={onClick}
        >
            <span>
                <Label>{text}</Label>
            </span>
        </Button>
    </div>
);

Rectangle.propTypes = propTypes;
Rectangle.defaultProps = defaultProps;

export default Rectangle;
