import unionBy from 'lodash/unionBy';

import data from '../data/root';
import { getPages } from '../lib/utils';

import {
    SET_ERRORS,
    RESET_REQUEST,
    SET_PAGE_ACTION,
    SET_ANSWER_ACTION,
    RESET_QUIZ_ACTION,
} from '../actions/SiteActions';

const initialState = {
    errors: null,
    statusCode: null,
    messages: null,
    page: {},
    pages: [],
    questions: [],
    answers: [],
};

const SiteReducer = (previousState, action) => {
    let state = previousState || initialState;
    if (typeof state.hydrated === 'undefined' || !state.hydrated) {
        state = {
            ...initialState,
            ...previousState,
            hydrated: true,
        };
    }
    switch (action.type) {
        case SET_ERRORS:
            return {
                ...state,
                errors: action.payload,
            };
        case SET_ANSWER_ACTION:
            return {
                ...state,
                answers: unionBy(
                    [
                        {
                            qid: action.payload.qid,
                            value: action.payload.value ? 1 : 0,
                            human: action.payload.human,
                        },
                    ],
                    state.answers,
                    'qid',
                ),
            };
        case RESET_QUIZ_ACTION:
            return {
                ...state,
                answers: [],
                pages: getPages(data, true),
            };
        case SET_PAGE_ACTION:
            return {
                ...state,
                page: action.payload.page,
            };
        case RESET_REQUEST:
            return {
                ...state,
                errors: null,
                statusCode: null,
                messages: null,
            };
        default:
            return state;
    }
};

export default SiteReducer;
