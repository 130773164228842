/* eslint-disable react/jsx-props-no-spreading, react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Label from './Label';
import QuestionMark from '../icons/QuestionMark';
import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../styles/partials/title.scss';

const propTypes = {
    title: AppPropTypes.text,
    text: AppPropTypes.text,
    color: PropTypes.string,
    visible: PropTypes.bool,
    ready: PropTypes.bool,
    withQuotes: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    title: null,
    text: null,
    color: null,
    visible: false,
    ready: false,
    withQuotes: false,
    className: null,
};

const Title = ({ title, text, color, visible, ready, withQuotes, className }) => {
    const marks = visible ? (
        <>
            <QuestionMark className={classNames([styles.question, styles.left])} ready={ready} />
            <QuestionMark className={classNames([styles.question, styles.right])} ready={ready} />
        </>
    ) : null;
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            {title ? (
                <div className={styles.title}>
                    <Label>{title}</Label>
                </div>
            ) : null}
            {text ? (
                <div className={styles.box}>
                    <div className={styles.black} style={{ backgroundColor: color }}>
                        <div className={styles.text}>
                            <span
                                className={styles.quotes}
                                dangerouslySetInnerHTML={{ __html: withQuotes ? '«&nbsp;' : null }}
                            />
                            <Label>{text}</Label>
                            <span
                                className={styles.quotes}
                                dangerouslySetInnerHTML={{ __html: withQuotes ? '&nbsp;»' : null }}
                            />
                        </div>
                    </div>
                    {marks}
                </div>
            ) : null}
        </div>
    );
};

Title.propTypes = propTypes;
Title.defaultProps = defaultProps;

export default Title;
