import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import useDrawAnimation from '../../hooks/useDrawAnimation';

import styles from '../../styles/icons/centered.scss';

const propTypes = {
    ready: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    ready: false,
    className: null,
};

const Faux = ({ ready, className }) => {
    const iconRef = useRef([]);
    const itemsRef = useRef([]);
    useDrawAnimation(ready, iconRef, itemsRef);
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="523.3px"
            height="134.4px"
            viewBox="0 0 523.3 134.4"
            className={classNames([styles.container, { [className]: className !== null }])}
            xmlSpace="preserve"
        >
            <polygon
                ref={iconRef}
                fill="none"
                stroke="none"
                strokeWidth="2"
                strokeMiterlimit="10"
                points="84.5,14.5 72.3,2.3 43.8,30.8 15.3,2.3 3.1,14.5 31.6,43 3.1,71.5 15.3,83.7 43.8,55.3 72.3,83.7
	84.5,71.5 56,43 "
            />
            <path
                ref={el => {
                    itemsRef.current[0] = el;
                }}
                fill="none"
                d="M90.9,87.7L77,34.8l34.1-8.9l2.9,11.2l-22.3,5.8l2.9,11.2l19.6-5.1l2.8,10.8l-19.6,5.1l5.2,19.8L90.9,87.7z"
            />
            <path
                ref={el => {
                    itemsRef.current[1] = el;
                }}
                fill="none"
                d="M162.3,64.9l-21.1,1l-3.4,11.3L125,77.9l17.9-55.7l14.1-0.7l23,53.6l-13.3,0.7L162.3,64.9z M144.6,54.7
		l13.3-0.7l-7.5-18.6L144.6,54.7z"
            />
            <path
                ref={el => {
                    itemsRef.current[2] = el;
                }}
                fill="none"
                d="M189.1,57l7-34.3l11.9,2.4l-6.8,33.3c-1.3,6.5,1.6,10.5,7.3,11.7c5.7,1.2,10-1.4,11.3-7.9l6.8-33.3l11.9,2.4
		l-7,34.3c-2.7,13.5-13.7,18.6-25.4,16.2S186.4,70.4,189.1,57z"
            />
            <path
                ref={el => {
                    itemsRef.current[3] = el;
                }}
                fill="none"
                d="M247.3,71l14.9-31.6l11,5.2l-14.4,30.7c-2.8,6-0.9,10.6,4.4,13c5.2,2.5,10,1,12.8-5l14.4-30.7l11,5.2
		l-14.9,31.6c-5.8,12.4-17.7,14.9-28.5,9.8C247.1,94.1,241.4,83.4,247.3,71z"
            />
            <path
                ref={el => {
                    itemsRef.current[4] = el;
                }}
                fill="none"
                d="M302.1,98.3l9.3-33.7l11.8,3.3l-9.1,32.7c-1.8,6.4,0.9,10.6,6.5,12.1c5.6,1.5,10-0.7,11.8-7.1l9.1-32.7
		l11.8,3.3l-9.3,33.7c-3.7,13.2-14.9,17.7-26.5,14.4C305.8,121.1,298.5,111.6,302.1,98.3z"
            />
            <path
                ref={el => {
                    itemsRef.current[5] = el;
                }}
                fill="none"
                d="M362.5,112.1l0.2-35l12.2,0.1l-0.2,34c0,6.6,3.6,10,9.4,10c5.8,0,9.5-3.3,9.5-9.9l0.2-34l12.2,0.1l-0.2,35
		c-0.1,13.7-9.8,20.9-21.8,20.9C372,133.2,362.4,125.9,362.5,112.1z"
            />
            <path
                ref={el => {
                    itemsRef.current[6] = el;
                }}
                fill="none"
                d="M450,97.6l22.6,23.3l-14.5,2.8l-14-15.4l-7.1,19.5l-14.1,2.7l12.1-30.3L412.4,77l14.4-2.8l14,15.4L448,70
		l14-2.7L450,97.6z"
            />
            <path
                ref={el => {
                    itemsRef.current[7] = el;
                }}
                fill="none"
                d="M515.4,87.8l-8,3.1l-16.2-34l13.1-5.1L515.4,87.8z M513,93.6c3.5-1.3,7.3,0.4,8.7,3.8c1.3,3.4-0.4,7.3-3.8,8.6
		c-3.5,1.3-7.3-0.4-8.6-3.8C507.9,98.9,509.5,95,513,93.6z"
            />
        </svg>
    );
};

Faux.propTypes = propTypes;
Faux.defaultProps = defaultProps;

export default Faux;
