/**
 * Constants
 */
export const SET_ERRORS = 'site@SET_ERRORS';
export const RESET_REQUEST = 'site@RESET_REQUEST';
export const SET_PAGE_ACTION = 'site@SET_PAGE_ACTION';
export const SET_ANSWER_ACTION = 'site@SET_ANSWER_ACTION';
export const RESET_QUIZ_ACTION = 'site@RESET_QUIZ_ACTION';

/**
 * Actions creator
 */
export const setErrors = payload => ({
    type: SET_ERRORS,
    payload,
});

export const resetErrors = () => ({
    type: SET_ERRORS,
    payload: null,
});

export const resetRequest = () => ({
    type: RESET_REQUEST,
});

export const resetQuiz = () => ({
    type: RESET_QUIZ_ACTION,
});

export const setAnswer = (qid, value, human) => ({
    type: SET_ANSWER_ACTION,
    payload: {
        qid,
        value,
        human,
    },
});

export const setPage = (url, page) => ({
    type: SET_PAGE_ACTION,
    payload: {
        url,
        page,
    },
});
