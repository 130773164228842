import React, { useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import anime from 'animejs';

import styles from '../../styles/icons/default.scss';

const propTypes = {
    color: PropTypes.string,
    ready: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    color: '#ffcd00',
    ready: false,
    className: null,
};

const Urbania = React.forwardRef(({ color, ready, className }, ref) => {
    const dropRef = useRef();

    useLayoutEffect(() => {
        anime.set(dropRef.current, {
            scale: 0.1,
            opacity: 0,
            translateX: '520px',
            translateY: '220px',
        });
        if (ready) {
            anime
                .timeline({
                    loop: false,
                    direction: 'alternate',
                    duration: 400,
                    easing: 'easeInOutSine',
                })
                .add(
                    {
                        targets: dropRef.current,
                        opacity: 1,
                        translateY: '540px',
                    },
                    2200,
                );
        }
        return () => {
            const items = [dropRef.current];
            anime.set(items, { scale: 0.1, opacity: 0, translateX: '520px', translateY: '220px' });
            anime.remove(items);
        };
    }, [ready]);

    return (
        <svg
            ref={ref}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="247.1px"
            height="247.1px"
            viewBox="0 0 247.1 247.1"
            className={classNames([styles.container, { [className]: className !== null }])}
            xmlSpace="preserve"
        >
            <circle fill={color} cx="123.6" cy="123.6" r="123.6" />
            <g id="CHIEN_x5F_seul.psd">
                <defs>
                    <rect id="SVGID_1_" x="10.9" y="16" width="222.2" height="219.7" />
                </defs>
                <clipPath id="SVGID_2_">
                    <use xlinkHref="#SVGID_1_" style={{ overflow: 'visible' }} />
                </clipPath>
                <g style={{ clipPath: 'url(#SVGID_2_)' }}>
                    <image
                        style={{ overflow: 'visible' }}
                        width="424"
                        height="389"
                        id="chien_1_"
                        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAagAAAGHCAYAAADlSMYjAAAACXBIWXMAACOGAAAjhgGLCwUUAAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAfYhJREFUeNrsfQf8HVWZ9nvvv6T3
nhBSgABJ6L2H3lEBWSwgrm1dRVf9XFe/7d+6rruuuigqqIBrLyhdpYYaCIFA6CmQEAgkIY30f7vf
PJ7neCc3t8ycmbl35t73+f3Oj5DcMnfmnPd5+yuiUCgUCoVCoVAoFAqFQqFQKDKMnN6CihjirUu8
dbq3VnprrrfWe6vXW2/w79KAvLcmeetIb23hM13mrVe9tVMfo0KhUILKPjq99ffeutBb/biGeas/
SQnCvsdbBW91e2u7t/p878e/3emtz5b8fVJo99bR3vqmt8Z7ayD/HtfX5a0dXPP5u1bqI1YoFIrs
kfT93lpBC6SPQj7swvu2eusVby3y1pXe6kjoeo/w1uPeWuMjzUrXBCJ9zVt/8NYF+rgVCoUiG/iw
t16gxVGIccHiettby711s7dOjOl6R9NCe4VWXJhrwm+Ea/IH3hqqj16hUCjSi5m0mnpjJqdyxLDa
Ww9464MRrvcAWnqbYrDynhXj9lMoFApFyvBub70s1d1jcS981xoHC8a69J6J0dLr5bVcJRqHVCgU
ilBAZhoy6BDgf8lbi7kWeutybx0nJonBBVO9taQOllMlC2YbLTcbE8pVIaYZ3vqxmBhSdwLXss5b
V/N+KxQKRaqQNu35WGr1I701Qkyqd5vv362LChl1SGiA6wxZd6tCfMfh3vq9mHhOIwHCQZxqE/+L
pIdbSKAXiUkdH8R70S+hZwWi2uCtX3rrk1Kf7EOFQqHIFEme662nvfWWhHO7QcjPC/l9t0n8SRFx
WDPbSRabpb6uR3w36ruukWSyDhUKhSKT2MtbC6RYAOsiYOGuC+OiWiiNce+leSlJKRQKhc9quklM
2nOU2AqIZlHI70Zq+UYlpYokda2YomWFQqFoOSAjDe68HTEIVbjDPu1wDY9Jfd1oWSIpxMTu8NYJ
ulUVCkUr4SfeejMkOeC1a8WkhcOdt9j33wfFJBIEBVyBU7x1qZjEBCWlyrVbSDy53ltjdNsqFIpm
RgfJxPavC1NU+pS3DhOTzWcz2aJktD0iJhlBLahgKfFIcX9eTHxquG5lhULRTECa9BMSLnOujyTy
NYm/RgfuRU2ScCOrlbRcQfL/R0wzXYVCocgkPiFm9EPYRAjUOH05oWv6qKh7L46uGLiHS8UU+mpS
hUKhyAxg9TxEIRbWWoG2jgauSRUR7y8me1CJJr6kitvFdPdQKBSKVAPzk5Al51oMC+vpSwlf4wLR
GFScC509EKf6jG5/hUKRZsspCjlhIXtsUsLXeZCYZqlKLvGS1L28twqFQpEqYMLrPIneRgjp5P0S
vla4D9H7Lu4GrK3u7kNd2i/E9FFUKBSKVAACfy616KiCbkmdrnmqaCwqCZJC9uX39EgoFIo4XHJR
gQwupB0fI/FkcxXq9NuXe+tFWlGK+BQVdKBHF4r36e1QKBSNJijMKzokRnLqqePvv5Ak1dOg+48M
x8205F4X0wuvJ+N7CgXV6NZxpuicKYVC0UD8ncTbeBUuwhvq/BsgTJdKfYt3bbwGCSWf89ZEMfOp
zvHWw2K6N4TpupHGVkl3e2tvPSIKhaIRgPBZEbMQxTyoYxrwWzAgcEMdyQmW0vdk12GMfkwX01V8
BUk7a0QFskeyy5UhnkFOdPy8QqGIAXDdJJEFB4E8pEG/6WFJbpBhDz+7lxbnDyWYS3Q/b/1WjPsv
axmHqGf7UYDfOEpMxxG0oEKCDMan/I+3huoxUygULjhY4q8jgpXwXAN/E1LbFyZABPg8uBAx/wod
MtAhfGRIywJj6p+glZeVPoIYp3Kz1E45n+CtfxfTGNjfJBh9/xAf/JWYZsHteuwUCkUtdFCQx92J
AULp3xr821Ag/FTMJAVCeUlMD8CBYjptuGCwmDEhL0s2umDgHj4gtQt38bs+IOVdrH0kujX83dh3
X+V9VCgUit1wmphYUdwCDRpzGrpjD6G7KU6SQifwP3hrdsRrgxVxoreepeBOc2zKxtquCvCbTvfW
6oCfCdfhCt6D74upZ1MoFIo/4TqJZxpuqZWxMEW/cbKYWEhcJIVEhzslvqy2fb31GzHp6Wm2pkDM
Pwnwe44WE2cLS4DIdFzFZ/V10biVQtHSmEJLJ27NHZr22Sn7rQjex+Xug/sS9WJxZqnB8kBB7AuS
3gQKEMivpbZb8wgxLjzXfWXjVq9S0fl/ojErhaKlAOF6LYVO3K6gJMdrRCWp5REJuZef8bmEngkS
KJ5JKUnBcrxVao+Nn8K9FYdljvv9NgkPI1+QIagFwwpFk2MPaqhxW08oWP1Sin/3f0oxw8zVzfVL
SbYB7oFiGvWmrbjXFuzuU+P6kdX4GYl/kCTcn0i+gAvwX5WoFIrmBApKk6h7gjB9RRpX+xTUSnlS
3GM9sJ4+X4frRFwKad1bUkRS2C8PimmFVQ0g7/MlmeQbv1WFbMpvi04AViiaCickJDzg0vlpjCSK
qa4Y94DhebbmaJZEdx/OFPe6L9Q/1Ss7cS8xxbGrJR3JEyDKN8XUOdVSAvDsVtXhekDgi8WMqu+n
R1uhyDbgFnkqAYHXSxKJWtMCYkK9DWIOa6XYGsjW0Kym5vytiN+xzNHFdE2dn9cAb71DTFeGNMSl
3g54D44Sk4BTL+Lcwnv0Hd4zhUKRQZyckPW0nm6dKEBdEWqWarUo6qOgvDbCdy2S8F0cQJgzGqRU
HE1LstEkhRjjdQGuGW7AZ6T+TXtBVOj0gZoqHbSoUGQIHSSAuK0nEMrDEa/tOAqWoNfWR6J9r+P3
fZLCNuzwxUZlJ4Kk4JqNu6FvWALAPf9GgOu1iRKbG3Sd2+j6+5poMoVCkQnsJ8Eq/MO69iAIogSq
O6lt9zh89zOO34ki2zdDkvBNDX5+SO/+UoOEvnVxLhDTfaQW4Oq9lJZ1I2NmsLQfo0KiUChSCswn
SiL2BAHw6YjX9jNxr5lBssORDt+JTMNXQ3wP0ptPTsFzhOsszq4YYS3luRKsxROKeS+U5DL5XNLT
sf//WUWBQtE4N1AloHEqBum1xfh9EJKIi0RJWABxHivuGVjQ1Oc4vjfMOPoOid57Lw5AyH5Mihly
9YYdNRLk3qZlmjD2/HBvHUBlCpbwESouFIp0EBTSohHYjjNoDPfJcm+dyj+7YqZEq5tCxtb7Hd+X
D/n6j6bgGUPwI8PxBjFxlnp/N0hnZ8D9Aau4N2XnA0SFNlyYy/UdKh4KhaKBBIUMsKkSbz8zxEEQ
gN4a4TMgHK6WaHVFeXFLK94RkljxPeOlMVl8pYBb83YxMbS+On93V8Bn3utwj+uFTnoULhczl+sf
VXQoFI0B3BsuCQi1erL9MYZru4mfFfV6EEsaFfK7w8agrPVwbUqeK6xhuKvibidUqxD7NwEVAmQ7
HiPhu5o3Ypz9W/QwjFRxoVDU14L6D29Nl/hiT93UOs+M4VpnSTxtanISPpV4uMM9weuR/bd/Cp41
Rs3PF1P3Uy/00HoK4uKzWXR9GTgzICZkHC6gRa+tkxSKOhAUBPcccZ/6Wo6c0MfuhBg+a5rE17PP
xjvCAELJxeWJ4Po/e2tsg5+11f5tUXPS6KM1dH8GSMdFwYFVOFXMNGDs8X9RcaJQJAsI4RUSX6ou
MsjiCio/LPGlSi91sIb2FLdecbYIFG18HhUzdqNRgXbEw26W2p034nLr3iEmEzQo6tnuKAm33/dE
kygUisQsqEO9NTgmbR3a87tJKlExXuJN2uiT8NliBUfLw2rbGFeC2U0IsKM34CPe+lupb3udN0hQ
9cjmw/2FW/HNFjlHUO7eI6ZZ8SwVLQpFdPgFPtx6/yXxdN5G3OEqMa1+4gAKc8fE9FkFR9LslOht
i2C1DeWCRYY6myulWHRc7lqhmf/BW7fQ8ouSBbmDikN3wvvKWlAbpfnce9UUEbigz/XWQd76lbf+
voV+v0KRKPaXcK18qnUPeETi60GHoty1Ep87BkP9vu1wHa4uvjhcpUhsQKo4Zmfd563LHO8lLLmL
JPluDdgD94pplRUGWXXxVXL5XSOaQKFQRHJNWLgmAZS6ddBn7ySJJxCPrg9xd5mGBfJzh/ft0SBh
A6trEC3Iqd46npbudxyeV1+M1lPBZx0UyuwDKBXLWvhcWZffbd46Q0WNQhEefgF3ukSfiwOXzsdj
EIKwvj4lpmHnFIm33RK0+9cd3vcOScfcIDwzZAReyv//RAhlAES3l8SXqo+1gxbeMN/1Wfded8jP
a4/5WTcS1uV3AvcNyPxuFTkKRXiCwmG6OKIAhtYM98yDju+H0PwHb72LltNoCtS4Rx90U3i6/L60
jGHA80Jd1iViXICfCkFQqHGLM9OsPz8PCRgYQIlGvOgG/l2HvThUmi8LDvcHpQZfFpOAdJOKHYUi
PDm8ItH87vC5n+Tw3RBIt/L7t0iys4vw2S863qMnpb7D9ILGOnDf/k/A37CPt34t8XTjKHctyA5E
Kv0ccSuEhsW8SbIfg6qUdg8C/7SKG4UiGKwQGRvR7QOrBLGn+0O+Dz56jIKAj34qNfwkB/zhOuc7
vjeO1GybQYg42AZaHUi8WCe71niFeX4TSAiTAn5/X0L3OC9F994mCZ+9NoC/ob1Jz1onFYQvislw
zan4UShqu1Ws6yeK73+1hA8E/5YW13Cpn+sMfeiudnzvaxS6Ya+1l4S0kf9FqjhiEculOKdpXzGx
u1kkkQ5+TyefzWD+Xa6C4EPixD+JGatRqEECkxO833aC7mKH9+K6/kLSEedLCm1UBt/HP18pmoau
UNQkqGMjCIY+WgNBe7xByMIPH0dSRliAIJ52fO/skCTeReIGESFr8H9phZUjECRt3FvGIkFMBvGL
D3rrYJJUO0kLAfh+vJ8DSW4gqgcDWClJEFSBv2+1hK/VQpxmIn9vs1sW+H3ISr2Ee+QzKoYUiuoE
dUEEsoAwCtOxez8K3XqTUx+tmB2O7w9aqIukBaRYo83ThRG+z17vXVx+YX4QSetEPsNuWmMLAwrI
JEgA14B2VC6jKEBMBza59VT6DGzDWdTlfUlFkUJRGag1cg2cI3MvTPeJiyl440p66JJgiRWbpJia
HRawOJZKsAJVJFNMa4DACwIQGzrLx53s0UfL6auO1475Y8sk2QSZNC4oM0ic+JCKIIWivOAFDhX3
4PQOCv8gQC3RNySeruTQ2DEp9idiMtlq1dzA0ns8wvcVAlwPyOlwXk89ETSxAorBAom/1ZEVtD+J
YC1ul3RN060H4DKe6q13SrAkF4WiJXGHuA8oDBMQXxij9r7CR6pwvz1b4zcsjShIFtTQ8HE9pTU8
SAA5StJTfApX2kditGCt9QQF5YfiHtvCfTtFTLxue4tZUtaVe51oJ3SFoqwF9RKFu4vm3hXwtdAQ
x0h8AfqCT+O2weYtVTT0zRG/b1gVVxqu402fZYKxC6i3QlwITUO/npLnDStyibjHxSr9dvzO70r4
jDQkeZwlpnfjZ731r2KyO9+SYtp9syNHxQFW1LckPcXgCkVqCGqro3sFgu7mgK8dRYEUF8ZTmFnM
qGKpILssyuj1gk+rryT4/Q1oMbocNS9InR4npl3TiBQ8b5vyvj0m4V+gUvAsLcywQKLAHG/NFJPV
+V9UYv5GjDt2naPilEWSgrWN+Ow3VSwpFLsCGVQuHcOh6R4f8Dvmi7sbsdICQSLe8zLdJJXchwjg
z45I5LdI5YGJ6yloLf6Nghv/htqrGyQ9Xa1BltdIMeU9ysJn3OhIvshGRExylY/87aRj/B1S88+j
xbcugb2TxtVNoj9TRZJCUcSJjgT1JrXfWoBwXt7Ag4/vHhTh/sAFUy3LDIJlru/1sOQepqXygJgk
FFcgzoYGr2dLfF0WkMjxQkSh30PL6b2O14B7sqjCNfSR4JFxiAnEKINYTIWo2YlqK0l/jIolhaLo
LnvN4TDhPXsE+PwraUk06tAvjnh/Omil1Ura6F/yPmS1fS0G6/YhMcW818fkKuykS22Jo8Dvo1Xz
bXGrqWrn99dSinr4PbeQVM/ls3xb0tcXMe6kiR+KzpJSKP4sMF6U8NlTrwQ8RI83UPOFdRN1zAEs
qFeldg3UfNk9EytKYSwssZPporTZcj+KaA36P/sEPvfukAIUnUO+J+4BfVhPzwTcE9btB4L+GV21
X6RVvFWaM+Ovj27ja0Uz+xSKP+HLPPBhDtKyAG4n/Ps9DdR44RY6LuK9OYgkEaRQF4ITHb3vJTEj
Q3KemCy1sEAj2K/4noslh2tjcveBYJDQ8VxAkrLa/fURSHIvEs12B6G9jVY73n8A78P6JrWm7LO+
ngqSQtHSQPeDN0IeoiUBtOiRfF2jNF10uojqzz9Gwo1J76V1YH8zLIA7xWQahiGP46gE9FZwr8UB
WHeHkUzXVLFqLDndIO6F1njfB3kvXfdDHwkbLlXU79mElGa1pNTdp1BQUL0Q8qAHie1MELf4Vlxr
qUTvPfdDB42/XDHr90N8J7peY4Dj5gpuS9QOHRuzmxcZmUhMWFtCVH73ois55Xm9y2KyeHBNO0lO
20h6XdJ8hb7q7lMoiK+GcPP1kdBqYW8x2X5ZTZCwBBV1yF8YUsmTLFZUEbZv87riBoTgSbSolvIa
lvG7XBM02vh7wsa7wt7fl+gSazaLypLUNdK887IUikCCcWnAww2B/dMAn/lFKdYEZZGg2sQtgaTc
wn34eQ0rpI0k9kINYW4J77gE9wOuZRituSiJHsdJsVtJks+6i8rQIinWTjULUflJSi0pRcviF2Ji
JnElH/ycgqNRB3tJxPsxmFZEnH3rEOQfX8F6OZXCPIilkZQVFafbGKNVnk/QcioX/+vmvVkuxS4p
zebu05iUoiWBnnmvBzgsqPafXOOzcIgWNliLRfxrdIT7cRq18TiFjM1GW0oLzy7UWq0NYWmA+JF8
MS2lewnJF8/WkZxK73MPCWoVr6FLsm9RaUxK0fK4K4DVgxqoWr31+kntAtd6VOb/S4R7gSao21Mq
rHppbf1lCvcQCoznNdh69hf8gqQeaBBZqrtPoYgR4+keqdba57EAn4MGsa+m4DAvEbdGtZ3S2BT5
oHGt61O2fw4REwfqknSReTNYUKXlBleruFI0KyrVMCHQjASISmMZcDCCTKfdJuFHMMQNxEHQIHWu
w3thmYyTZEakx/kM06JFI8PsJO6d/VOm3dv7lGuSs2s7oF8opphboWgZggJek/KTV6G9baCFlRVA
MB0akqQgAD4t8bQVSlpQgRgaPRQRFir66yFpY4ZoOnS9zi8yLC8XU7CsULQM0Pj0pTIuEcR0vhzw
M2ZJsBZB9VpIjX9KjAuqFu6R6LVP9fpNt1JQNXKvoOM44pKtMBYjjbFIKIxfULGlaCVg1MEmcc+K
q9QJodEBc7T0QXYZpvAOKdFI4aJalBFyspl8SGrZq0F7BIXYP6UiouTUuGXbae2jYkvRSrhDinVR
0NQWhngvetitlfRqnSBPJHEgEcKmeWdt5hAICo1pZzZgbxxKi7TZ2gtlNWkCyuR1KXD3KhR1xSMU
hEhtPS/E+9AodqUKj8QJ6j66U+sFZHn+I1163foMUkVSSHD6DxVZimZA0EA2Ju5ihATa3vwxxOfb
Cn5F82BPb/2tt97H/ZDTW5Ia4Fmgc/9l9AL8vd4SRZYRdOAcNvuZYhqIdockqD69zYmjUCdFAC69
m8Wk3ys5pfdMj+cz+qreDkUrWFAAap/eG/LzEZuw8SsVZtkWeuiph/iGppBn43mNoyUFJfFLeksU
zWxBuQKHA/GRnXqrM4sBYuKOP1FyyiRJQan8O70dCiWo8viGmKmgiuxhPzHd7VF8O03JKZPnG+6+
k6VxZQgKhTPqkY4K1yCm6s4Wt354iupAjA/jQG7jvY5jT0DzRgsddMxGR/LBdVJmFMmc8YliWk89
I6YGUKFQ+ADNe65oSnJSnSRupxIQh8aNESoosEZBthbeNk/6OeZj/UYtKYVaUOW1fDSOPcVbA0UT
JuIESB+dL34s0TL5QE5IUb7EW5+lFaUFn80BnLdOPlNMGEA27ha9LQolqCIwwhzFpPuKzrCJEyjU
fc5bN0YUYBBc6FD/BQoydek1H0n153PGnnmSFrJCkVrUUwjBikKB53LR2qi4YF18UZNQhopp9orn
M17JqanPOyZmv8NbR+rtUKgFtSvQCR2NZg+mNqeuvugEhVZSiC085fgZaJZ7vpipw5NE3XqtQFKj
aCXDilqnt0ShBFUE+vrB1Yeuy+rqiwbUmS0T0038NYf3Q0lAXBC926YoObUEcjx3SKpBdh8mY2/W
26JIqzZVbyCo/3kx6a7apy8a+ihcVjsqJ4gHXiEmc0/JqbVICpYzemx+iIqKQqEERbwhZkTEDn0E
kQkK6cNvOggouHgQizhVTIaXovXOPoZcosfmkaLudoUS1C74ophZTGpFucMmSWwPSU6IQbzTWx8X
kyChwqk1AVffQWIGk87Q26FIGxrp1kHCBHzgSJjQDhNu5ITaMgS6bwvxvuHeuthb/1c0Y6/VYeNR
OIdw8z4h2pZMoQT1ZzzorTO8tYcKSieCet1bN3lrQQiN+VhvfUXMXCe95wpbxAtlBQNGMTFbkyYU
SlBistCAU9WKCg0UWaKDxLclWKpwjooAguInisadFLvujf4kKSg+j/rOpkLRMKRBg/6DaNsVF4RN
kECLqWO8dZFo1paivCxA4sxpYgZTalxSoQRF7V9dCm4EtS3EvYNLDwPsRqnwUVRAB8kJCUz76e1Q
KEGZvmCvq0shFGz23iYJlgUJwTOFwkdnOikqAYrLIG/N8dbfi0moUShamqCAp8UU8CqCW0+oJXss
4OvRXuok0ZRyRTCSwvwvJNOcoPtFoQQlMo+WlCIYYG2+4q07AwocJEecI5qIogguFybQkpqot0PR
6gQ1QB9FaIKCe29NwHs7XbQRbLMiqUJ3ZHli/MoHaYErFC1LUKrZhxNIsDaRwRdkbAlSh88WE1tQ
NN9e6EuIpGw7rPdw/2jsUtGyBDVXwrXraWVAIKE57BMBXguLCR0CTlEloCmR4zNOKk6Ez0ZyzYm0
whWKliQoLRoNDrj3lnrr9gCvhXtvqpjO1Rrsbh2rSmK0qlA/h2GWl3hrhN5eRSsS1HvFZA4pghEU
4k9BCnSte2+g3raWISe7sE+6YiAqKDZogXQ+LSlVdBQtR1AoHlUfd3AhhDZHteJPECQYp4B0YR0M
2RrI8UznuD+2Sjz1hTibKNxFqcIkvc2KViOovGpmgclpa0DrqR8JaqDe25YElJIhVGYKMVhS8HAg
YQLdSLSAV9FSBHWYWlCBYAt0HwnwWsQL0HtPU/hb15pql2JyTFSCgqyApwOuvhNEO+ErWoSgcJB2
qpYf2IJCtuOGAK+18SdtDKtElfOddRufcnH9wSqbLaah7GQ9s4pWICirmelmD0ZQOyTYeI08tWe9
r4rSPQRy8rv+wtRSWVffX4nGoxQtYkGpey/+54bXaOcIRbl9AUuoU4od8dGseZMEK/zOUaG8Qsxs
sTF6SxXNTlCKcGTeL8DrIIA0QUJRaX/YhY4kz3lrlZi5bEEsqTyJ6X3eeqeoG1nRxAQ1QDX9UIIF
wmBkjde16X1VBDz/yPQ8WYy7bqMY19/OAO+1XUpO99YButcUzUpQg0VdfGEICskPRwR4XYdaT4qA
RAOLfChJqjeEJdWPBPV+MQMxFYpYkQZi2C7JdWQWKQaERSq3gbHujjZJtrdZHArFMKndFw2CY7QS
vyKk8tPmI6wcz0muxnuwH9H1fL23rvbWW3orFc1EUENituRASN1S7LiwQkzvujaSIQ5Vl++AFfj9
iNfAdTZTTPymTXZN0W1LiRujTWr3LrSZWWpBKVzJyr+XCj6yypX8u02a+Asxcayf0wJTKJqCoGpp
aUE/o4vEBEJ6nv+P9QcxjVUxbmIDf/P2MgQFFwc6N19BshrgIyZoiVPF+NwtUaXZhYZrGyFaTKmI
h6i6aCFt574aKrt2f2mjVX+ht5Z56wGf10KhyDRBRZln45+NNJea213e+o3sPkJ+K//bXfJ+ew0b
uT5T8j4cRMzFOVWKYytgaR1FEutsABHkufqqWKX7ivbgU8S339BeC1Oc96AiN9S3923WKPo+vttb
K+m1KOitU2SdoFzcZpaYNnvrPm+96q2/S0hrs+2FfsIFYAz2l8TEeQ7j//eT+rgAbQHuwCquFPzb
NNEYVNZgXbNp602JfYRMvRkkn+VUgtqprA3nn/F3F4upqfqWmPoqhaKu5BA3kP1zhQSf+FqgpfNH
bz3krY+LceP11fGaQYx3eOt3YlJyt1Ko2IzEJIULfudr3rqblmM5TPDWu3hv1c2XDVgrfiUFPvZQ
r+we82kEbBy2k9c2mNbUIipvbbSobHnDPrx21Fdt00eryDJBfVCM+yzI0EKQE+JIv/bWR8TElvoa
eO24ngUkqtX8u/GSbIEsfu8qElSljKnxJKhJSlCZAax/TEn+X28dSaVnKa30NNUYYT/1pxIE1/dC
by0habXx3wZy76El1wui8ShFhM3WaBwekJwgmBGo/Zm3PiHBignrCZDUB7x1PbXg7jpotZWAYPbr
Es8sIEV90Esl5wlaUsvpGehO4bVi78HNDNc2aqDQQPYxbz1OUgW5IqHoLP6bZpMqMmtB7e2t46V6
QB/ktNZbv/DWpyTdwdd76OZAttOYBO4x7gVibnfwnpQDtFgkSRwhmiiRJYKCZbyC1u9UPr8BUsw2
jSPjNW4FF8olEif25L6E28/GpvD3XbSiNusjVmSRoDBf5mipHNDHoUSs5QZvfS4j9xWxsdcoYOLu
1B6EoCAczvTWwaKJEmmH7S6O5wo3GSbXTqfg7/SRExJi1lD5SFMxuS3whVtvCvfbJhLTOhIt4lOL
ReujFCHRnoLvP1mqu/i6aJV8IYLlZAO3/QMcbHwHXImvSDQX2e/5nf8ipo4qboFSCCD0FOknp80U
3qu5Tw4oY/X28jUonzhXjFs8bQ1a8zxnh4pxMSNB4j56Ec4laf1ASUqRJYLqzw2cr3KAYSX8s4RP
hminNorsQPjDPy/GZx7E1TLfW9dQeEALXOb4+64S06vstJgFSq0R3lajVaSfoJAF931v3SzG3f1F
MUlD/ct4Ee701jPcV1MlfQkwttv+YBIVrhFF84gXY7ozEooeFU2aUGSEoLpqCFpoYrdTGwuDKbRe
/oHuEpDUkIC/F9eDLLg5YlJkH/bW13nQtjv8xv/21v5i6pLyMQqCfA2CCmItKhpPUG3cl+u4vkbF
yr9f8O+ot0MR+bVi4lRpLiGwhbvI8oOLG4keyEZE0sQaWoMKReoJakANgoKr7XMS3LWHA3uImBT0
C2idtTscrn58ryUruFRQmIsA8JKQn/eymO4Wl9fRqrHBa0W6YWvaHvYR1mpaGXtIce5XjkoW9uFW
KmBZKB+wXfU7aB1+mH93NS1H7TShqCnIGgls3N4qG9X21wtKtmg/9COSwfgYCNiS1V7e+p63vszP
DQMIIHSg2BDjgbQdByoBQuwVaWyNmCIYQW3gHhlKi2NPKkLdZaxieAU+QILKmnXcTqUPdY8YFz9B
H78i7RaUbdmTqyCEdwQkKLwfmYA/FOPSi/t3tdFVcSYP11cluLvPCiGk2o6V6GnfBd6TahX6SHE/
TDSDLwsWxnDu3Wn0KJxNBaOjwj7M8mBA29fycp4fnNc1ug0UaSWoLVI52wxC+GkJPoL6f2jptCUo
TBDH+qiYYO9tId6LtHB0CDicAikqQUGAravyGsSfJopOOc2KFQWh/bfcG7YBa7MqF/htcF9+hH9G
Yfsq3QaKSpulkdghld1QW6hh1QI0TbSGmVQHgZyjFfQVklWY34lDGEf3iz5+3o4az7VTNEkizSj4
nuNUMZlv/aUYs2nmZ4dzClcmOsJ8nMqU7lVF6giqWtdmHNyXA3zGodTCRtXxcEEDPD/Ee3rp0oia
XmvTjVdI9Ton+30ahE43QaGl0RISUy+t4h0t8tzaaDnCkvokFUyFIlUE1b/KNcDaeC2ARfN/JZm4
UzUgoI3xHv1CCCMQxnqJlrjQQ4H2uxqvg/X5kmi9SdqBmBO6jczhOQBhbW4hxQK/GYkTSPy4MMR5
UihB1QWDpLxbzgr0WgkS2NCzpP7+ehvsPTDEeyB4onZ2hpaN9NxadWHb+TrN4ksvemkNQxFD7NRm
rI6Q1nJ34SyhNReGHc4UdfUpUkRQW6W8qwoH9fkA7z+S1kwjNjW+90MhXg9B9Ja4tyACaaNdDLpF
15qxgzhZ0BEmivrDdgJBl5T7ud9fleIU2pzvdX0tYAlD0UTHlfeKcZ8rSSn+hDRk8fVUIK7rA1z7
P0r0rLgoh+oUkkBXgNeDXJ7mawc4fF83LbBfB3htG69PD3o6yWknLeoDaTVAaYGr6wDupz7ukx1S
TC1va+LnadPtL6c8QCHva7pVFPkUbMxKltWiGu+FK2RGA0kW147Mq6CFu10RLChrPSG9/akA15UT
de+lFXguSIaw7X4GcyGrrb8UkycwW+kBPvcobauyYoFBFqEd0hViOk6M1a2iSCNBFXiAa9VGHMeD
3WgLdJwD2YQFyA0uz98FfL9/fpAiXejmc5nuI6Qe31mEZfWgmLq+p6Q4/t0VvZKdzECbNHE51yjd
LkpQjf5+dFnwp0TDerqzhgWAA3uJmCSLRhNs0hYchBfiE+h2PS/gNcEd1Oy1NFmEjSm1SbGLv23s
C2yjlXwDieoiKQ4sjKJEDcjQXsD9mEyCereYBAqFElRDAGLClNxbxaRgwzeP2qevBNjEw1JCsEmS
pM3a+6WYEQtBu2pAGG0UdfOlDTmSxTjZNXvVumRfoyICVzDaak2R6MXnOcleRxGQKiZCo0bq/BR4
ShQN3AiNxlXcgBgzMNVbf6BVVQvLKIQH01poxCFEQBujEe5OyHICOSEp4ocSPHbVSSEIF+kMyUbX
61YjKZBRtxRbGlmX7FYqPP8hJmEiLTO9en2KYT3PFhJI3icm2/E+cRt3o1CCigS0DEIV+X/xcC4I
KLw/w4MznoJ4H99hb6/Tb8MhOiKEtdUZkDDw+16n5YRZVKtDEht++2zRXnxpRA8tpSfEuPkOIxG1
8ZlhL/fz7eW0XLM0YD/hPqA+ahOvYT6VUoUSVN3wFjcfEgG2hXhfL0kKOEeMv94e7Om0bNp9WmoS
hwufOS3EYas1n8p2Kl/urZ+KGU632kGYbPZp64r0ERQSXv6Z1v+/eet47o9OSWftWqM6PORI3mdT
EcXIG4QD3tZt1BpIi4YddKxGJaD9zy3e+i3XEhLTUgr7Ajd6r+wal6mURVjga7spUPzL/3c4KMis
uzfANSIt/gwxoxU6KhCubVEEl97XfEQTFJaMUex4sYRraKuo35kbR4GLPYu+iqdL5bEzrQ47nXcs
19v0LuzQW6MWVBYBArqPS6ilnkdy6KQgQJYQOkFM4D3wV+x3c/OvpPViYwUFH8HZZAXEyv5fwOtC
UsfsEnKyn7mDh+4hXvdPHX/7AB7iHtEU8zQLXOxJxFeOoBKF546mx3uKumWrWXFHc4/jDN3koMAp
lKBSB1glv+CyhHU0Seo4bnxLTgjCIlCNjEK0oHmmhKB6SggqDOz7cT22/mUrBRSKNjHm+1qJlnk3
kr9thW7tVAPPHi5txFZQQvBFMQ1TPy2asVaN2HFuDvLWZWJCA/dIsC4uCiWoTBGWzbq7vo7fC9JA
Iogd7w0h9aYYN88jEk9KOMgWs3V2SvTJvYpkhS2ezyguWOqP0ZJWgqq9x4/h2UGW6yLRcgolKEVk
QPg8xJUUttH6Q5KINopNL2CRz6TCgq4RV9M6gMtqmOyewdfLZf++lWNV+O3I9oXbHh1nrqLypyTV
hFB/d/MJPggxJEecLsUOBYr0Cdk2Clq0MtpIxQXPCzHS0b6zCWJCTdvjUhxsaLNTcy18/3Av9qFF
tVpqT5lWKEEpGgzEy+AiwhgQZPJlqcVNqwraAVyo8bmRVvDJUkztBiGhZuo/aTG8Qut4TIufX3vv
kHh0MPc+Eo226rZSglKkFxBwSMVFfZZmhWVD0IKE0OILcSgk75wjxZEssJQQp0ICzHViirchhE9R
C/lP9w5EjkJ/jC6Bm2+JklTzQNvgNNdhhesHCRios7EdrLWrefqfG8hnphQLtUtr9RCXgsv2k3y+
Q9Qy3gVIOJnqrY+KGdehozrUglKkEIhnoMv7Z8XUeEEL30ihpwMM00tQgO1YP5oW1DDfv1nlo48E
9SmSmj7PXZVt3Ju9+f/L1JJSglKkC/t769/FpJrDgkKTTbiEkI47XRrXskZRHXYg5UIxcSa06dpL
ds2yzdEyOJqKiHo/KpOULYhHfaEW8ypBKRyAbC3EiZDUYCemuhYBW2A8w2X8LHTCWO6tvxUzKv5E
Ma4/1brTKVhH0eq19VAny64xJp3zFfxeDiLJ4169pCSVXWgdVGOQoyviH8S44GDhLOV/cZjeJLmE
/UyrjQ/jAQUQTEe1/WoSoGre6dwPA0lK6GY+XtJTsGvjYnbqr228nGaStJN5/5L//x0xBfIai1WC
UgQANLyDaNUMk2IPQBAJMvDQsflLEq6uA4cPGXyrqIkPpLC7iqSnGX3p1/yHSzH2lKZRG8/QCoe8
gBt5pqS/Uwnu52iSFO7l1UpS2YMKrMYAabFXiGkQCjdOJ/8L4hrK5/KihO+ph/ch/nQYCW8jyWoU
v0NdQ+m3pNLWKWInFSbUYWF4JjIIj5JsxDNtrdS+smtMSklKCUpRBd08MKdLsd7Ff6iG0wL6o4Qb
QzKQltnR/JxuChR9zgpXQJij6Hsc9+WxtKKy4n2xJLUflbSXROdJKUEpahIULKbzeOhzJQcKzwWu
FRQdvhzyeSJR4jT+2TYfVctJ4UpOaK6MdG24i5H+PkuyV7JgY3z78Fxo4oQSlKIGQEzoyozYUL7M
gRpGze8uCT5SAKR3IAlqsI+clKAUUQiqkxbUIEnXKHoXS2pf/gYlKSUoRQ0ywWE5WHZ3l+RIWsi6
QxxqccDPxOtRwHkBhYmSkyIOoT6wSWSFkpQSlCIgcECmeusEKR9wtlYULC3UxmwIqPEivfZC0XY4
SQPKQHcLnKFmU3L8JIVz9wpJSsd1KEEpfNjJw3K27NrWxn+QQGKjeJDmimkqWgv4LCRJ7KHPN/Hn
t1m0Y3yWSWp/ehxepwLYo7dGCUpRtHbg5kNblulSPisKB8mmhz8npo6jFnZQeJ6kwjPx5yei6ftZ
JykkTozl2VodUAlUKEG1BHbwQCCpYWCF1+Sp5cF197DU9pl3cSHbaopoMXaSZ0fJKfsk1clzgvpD
1A2iJ+I2vTVKUAoTwwCBzKEWl69wiNpJYCCn+QE+F3Ueb9GKGqpCNFEBp2gOkkLrsSP4d8vFZC8q
lKBaHrZ33uFSuTrfuiPwvDD6e12Nz+zlmirFppkKhaLy+cIZQWukA6VYK6UkpQTV8tguJmZ0huxe
tFv6rGBlwd13t9TuMLGV60xpjmJdEHmfWi6KBGE7odtOGS8qSSlBqQVltDfM/9m3irVjXX14PTqf
1+ow0SfFrul7SrbHNOC3IMtqMX//QNGu7IrkrKmBorVSSlCKXawdxI2O99aIKkSS47/DknpKzEDC
aoBQf1ZMA1m8L6u1UT0k5DvFuEEnSfpHPiiag6TinimV5+drw1olqMwA8SJk3qHz+AEBrKiJtIpA
UrXiUUiWwKTW6XRdZPGZ29EJ6NI+VcJnz9mi2h4KhrySm6IBlpRNyMiLprMrQWUM2ylEz5LqMSO7
yVGIO1lM0sSmGp+NJAx0oT5Qspl2bls/dThYTiAn1Lc8KMZFCEIf6fscJSpFtX0XZ2skfwsz7Vyh
BJUp9PEQoHC3Vv2SJamx/O/9NTQyOyARK2udqKMCVtPTYgZAXicmTX+EFLt0aCwrPhS4D3t8lm8z
kZSd0+ZKUm2+e6RQgsocEIfC2PdTpHz7o3IkBSDO9HqV1+JAvUbymyytVbxrBYyNJSAuN0/MWPWD
RQuZ41YGMHn3KZLTsCYkqRx/o2t2n8aelKAybUXZAXH7+gioEvK0AhCTelyqN5QFSa0R05y2Fvk1
I0HhHiFTEjE+tLf5oGhD3bgBN/UPvPVlMfGbwwLs4SztI/ymvflnrZNyg1VcsC92KkFl04qCNXQ4
tfx8jUNjCwwhhOdWcR/0ckPgtSjebSVXX55kBNI/VEw8bqioey8JBWsNLSgk/CArtX+TKTtwl8/g
/8Pdt1UfeyjAY3Gctw4hwe+QKk16laDShwI10V4K0loNX/2uvhdJboUqVtQrtCQaVRtV8FmKBW7O
emTX2UnFHaI99JJUBJCAgozUcVSyOpvwNw7iGcI+XqIkFRozvfUBklQ35dIWJajsYAcfGjT+KVK7
VZF19SG+9IyYrLVKWE/3BDbHxDrvgQIP88u8xk28XhDrOgo1jQll28LooNWEvdisI1+sRb6PkpST
DIB8m+qtd4nJWgaelzJNepWg0gt0VsY0Xcx2Gi3BXH0TKRgWSuX6KEsS2AzHSHHybr02J1xAKLi9
j+s73voZSfN4auBq3WQTvRTYPRTgI6R53aiWpGbwNy9WkgqMLbRATxTjCh5HebeU1rcSVIY0DWij
h/G/tVx9lqRgTVXr19dFC20i18A6kYL14YNEkdBxO/+7gX+/Nw+8WlHZJKe13LO2c0mzyxdLUtN4
nl4oFbCKirKtHy3QaZRXw0hcq/2WlBJUurGNDyxozMh2msjR+lohlZMmQApP02Kx469zdTrUICMk
apxM6xBuxwEkqINFu68nCWvhxH32e2lFwG07vkHP0B/fzNV5P+P8vOqtlaJFuEHwppg6TniI0JgA
7ctQBgPPz/NWbilBpR/r+cBm8yHWsi5svz5YKctIcJWyZJAxiHjQFL6+XskDNrED5ITi4dP5/TjY
ByhBJSrAt/ks1jifdZ6umnE+Janevw37eQmFX786Kl1t/N24B4invqVbLZCiNJHnfyzPPKzRnVR0
1ihBZQdv89DNplBvC2BFjaPltZSaXSXgMD1FgoDm27+OwsUSFQh1Bsmp1Tpd1BM7fZZ13AkMto1P
o5r4wp2NAuxveuseMROop0p93MV2H8PLARfnc6LxqKDKN2TOIbx/7dyXvVTK31aCygZ6SCSbSTpB
kiYsSSEICVfe2hobZQE1mSl1Jin/9TZC824l6wk+fmRNrvLWLGmuWB/I9xZv/VRMLAgK19FSeQho
Enu4H0mqm1aAjo6vjh3ch6iLGuC7h9Mok55SgsrWw1zBzY+kiVouGn/SBA7NIjGZMpX84xup+Y2j
FtNftJC1mWC1/ME+TbWZzj+UOLi0nxTjHkJB9okUfPWCTZrAvUUs5UWpUoSq+JPSBIJCNvFQKcYN
O2iBvqgElT2Seotm8RQJXsQLbXIGDzCC2JUyjTaQyDDZdwSFmc5dah7k+UwnNqG12kVyepj7+DAS
1KAG3OMhFL6Ih72h264qcK9mUom2fGSnh69QgsoeYOk8K8UYU6WYje3UkJeif/xAms4rpXIfLHz+
49RgkOE3SrI9jbeesHO9cpLeUR42VtRMz9MOtHyIChaUMLiJDpfGdKy3M9uwF+Bu1Im8lbGdcuxQ
KXYdsQMdFylBZZekXiDpTKhgSdm2/nmf2TyWm2EV318J23jQF9NamyDpaA9U8G3gNJLTJt7bTdQM
9XwVFaVen8KUhJBDsfevee+30nKaxf1e7+dgYylT+P+LlaSqkjkUidJ44Z+Kn/UAZRewhJ6QYmJD
Z4nWXk5TxmYYzr/7vVT3j+PfEPNaKPVPQ69EAFu4+kv6SAqxQWRbwgX7HO9Zq2ckFiiYYbEvI5EM
kvjdxvAGYCDlvXwG2CtTvTVHzCTptgbt19ekWIT6kmhmXzng2RwpJlGilKBeVoLKviUFYTiCwsD+
t9q02DxdD2/ytZukemEhBO7TFLjjSXL1dhHZGpfHuIKMImnEQRvEZzBOio1pW3m8PPYZ3G4YwfFj
CukhXHF2L4Fy8LwU07t7+NmwoA6SxtTV4bffz317Bs/Zc6JJE6XAszmmAkG9pgSVfSAgjLZGy6WY
PTSuitaYo9V1qrf25/ts2/tKwGc+SaGC141pgDW1mcIO6fAnSf3aM4Vx64BIV1GbX0/BOVJa19UH
sviRt74rphZvEYU09mmcLa3wPTd56wGfAgZlCp1KZktj0umtO3McrTnbaUOTJnbFEJ7no0uUTpyd
+UpQzYEekshSulLwwCtl+OV82j6sokO5GVZJdT85iBBNXuFWnCzF6u96WAj4/P4UNiDW4ZLOFHgI
RriZrqR7B0L5iBSSab0ALXg1rQi4utAJZagUi7Ljsmx2kJzmSTFOiQy+S6X2TLUkz+Tj9HLM4JmB
i/NZUVefH8Morw4vQ1ALlKCaBzikKykI95fao939iROzSVpPSPVmlzj8a/m6ATyEo6U+WX42GzHN
9VkQyHCd3sN7BLcOEln2ltZs34T9B3fsed4631sXe+s9YlLAB8S4Z3DfES9FEfJOfu8J/N7BDbSo
8zwfY/h7J3F/LBId/W4Baxqtzg4skVcgqCeVoJoL2PSIGb1ODXJYAGHexkM8jFr/CqntJ99AIYzY
1IQ6W1NpBn6/jUFBWC6hZXoBXRmtBqsEDeZ9sbV1cbuHO+gNeJuKAZ7DKWLGt/Rr8F6wLl6b2QeL
8jkqLwojOyCrZilBtY5bBQ8XbrCpFAi5AIdpFLV9ENQbAUiqj8L3cWqH3QlaU4WMEF+O92IqLdiX
SFbnB3wOzU5WSU5L7pRi/AkW6zliXGuNlHH5EgWxg/vC9r/sVXH1J8XiVFrabSUEtVAJqjkBTRJ1
TvD3w90XZJaU7ci8Ny2pNyXYbBv42JGksZDW1CgKizhSiQt02WyW7KRs23gZSOoIujAObqAm30rW
62QKu7OlmOmZtkQa28ZnsVSffN0qgJX7LsqenBJUa5HUK9RQ9ggg4G3DVgSVMUgMbsKXJdhsmwIJ
7TF+Ti+Jqk2iuf1sbOEhXlNWmptajR4xh5kSb7wly5Z9kpaw3b+41wMlva2ccCbQZQLF8JowYQjq
bCrTUkJQ6uJrcqynK2FP2bWYt5YlNYZE9Sy1vKABXfjV54px+w3l4RsdQZPtI0miv9oxGbNCbJC8
lTq0l3aMsP+P7LWVtLYHSWtPTLaxKJwxuNKfl9YecHgsrd6BJX8Pz8kjSlCtQVIgmr3oAgk6ldc2
pH2GnxHmEMHHfocUU9JdC3xtzdYxEv+APUX85GSnob5NBaXA/0fB6i+ouEykcG5l2ZOnQN7E+7O+
he/FUWLq1Uq7zkO5vUMJqjWArDtkDu1DayrIVF4QGVyDiElhbMDakCQF4bSGJAVhhTT4USGtKWvR
pS2WoNgdsJRQRP3f3nqURIRY3Pe99UVvzRcTpxxMoTSgxe+XPV9reV9aNWECBbpzSvZDgTLrLiWo
1tFuoaW9zs0wPIDA95PUXtT0XKrgsdH+yENotWedNZW+/SERlQCrkNznrd9xryDw/RKVlD6ufUhQ
w0WzGqEobqEC2IoJE/j9cPGdRJngV3ZQK3arElTrwAoI29E8SODekhSy8xDwRzxrneN3I4kCLh7b
i22Y6KypNKBbioH6KLEhKBxww67kPnmOzxz7ZTmfNSxpFIUju3G0Pvs/zz3CPXtSkotFpXX0C6zp
k0lS/vjyTiq1NytBtRY2UaOdTJIKUrNkSQoENVVMce46x+9HkPxRvn+KZDsWUaBAybIliN+wndp7
m0TPNmwnScGSQnLLUpKTcL8d4q3TZPfGoK1sReG+ryWhr2ux3w8ZgHq1g0uUI5S3wCX8oBJU62Ej
BQe6PAcd+23TpvfgpoKG7BrY3UHhhfejvcmIjAp5kFNPxq1Aq3wMJzlFfQ64FyP5WUij3sznDYXo
CjE1eYNoRXXoUfxz6j0sS7jfkczUKrEo7LVzvXVZGWsae2autx5QgmpN2LlKcyR4I1N/bQ9cfo+J
+xC2LmqN+LyZks0Mvbw0h4sy7gm70IQxzda22uqjEPqAmGzM2aJ1YZac1tEj8SAtqDUk9lYALOgz
uMpl8P1RLajWRTfXLFpEQWMPlqRg9SCo+WoESwqbcAW1p2mSzY7fGj8rf0/aaRXASn+HmIF0w7l3
OvS+/ZmgkBxxlbe+I8YVaq3yVmgki2xiNPM9tMSaxm+Hlwe9PrWbeQvjbSk2lR0RUGjYAszBJDe4
buDu2+B4DbYOBHVSe0l62xn1ktD7RBvihiGqTioeWiZQ2ZMACwoZazt4DoZI8zeSxRlCe6MrxJSe
+PcGCBquzlu9tVQJqrU1uB5ukNkBhAgENALqbVIMqE8kuc2P4JqAtoS+gVOpVaVNmIGc0BR3rhgX
zCgpNgFVoauIcv7QimwePQnIXIO7Cw1uX+J5a1a0UzFGwsygkn+DHLnRW7/Fn5WgWhuIIaExLIbH
7SGVXX022wuvRexqKAU0LJ6R3FSLxH2c9XpqknuTpNIUQO/ltX1BTHeM4bQ+R0uxO4Yi3P3s81lZ
rWxhjqCAfp17CvVAh5GwXm/i3w55Au/LITxHeR9pI2zwGzHZvqIEpQBJIWHhaKns6rPpsFtIRHv5
hPNgkhRmHy2PcB1wE2IaMALpY2IQ/DYNPGoNiJ3rg9/5EzHBWySIjKVwGSRazxWGnCB8X5Zi13cX
SzSOwuI0EFQ7BTQUPCSUICZzFMnpCWnejL7hPDeTaTFahRQuz7tJUGuUoBRCq2cLN80BUjkOlKfl
BHLyu+HsLKmxPFSutRwFblB8/xFSe0RILffJRgrDIRKtYasl520UrCNI6r+n1jtc6jdVuBn2GjrT
ozefrb0KWwvXQ4t7e8Q9kiaSwu9HyQWSSeA6Ry/L5/nfZkI7PTXv9NYlYkILI3znH25OJEfcxfOm
BKX4sxX1OjfMnlX2Rbnu3P5uE6OoAbn6z7dR+BxQ4zqCaOoItF7HAzAh4l7Pk4BR9Y7xAFPFtPJB
rOAZfv5kae0u3UHv4zQK44eoQBxGhSeo4gEX0C0U3tMl+/VUuCdIjpgkxTlq2LOITz0tzZXRh4zh
T3nrSjHuvbH8vTuonEJpQZusefYNSlAKP0nB6jhDdg9cBtUERYodBKJcB9x9J9H6cdGQbaoqNHW4
406XaM1JLQlDcEwkgZ4lpoYLcTm0qjlBtDtC0H3SnwJ5f9k1BlELXbRcv8Xneazs2sMtq/fEn3Rj
LXacoYW0FJsBOD8XeOtDUpyqYH9zgcopLCckR6xRglKUszqgwR1FIZx3OGjDuB4W9yLeHi4kTJQb
UliQ2oPvclJ07UELRTuVgTEKE9ynodTgMctmjpgYlSZMBLcaQPYjQ8qgXlpQiHfO4F7t16T3J0eS
WtYkvwnn+VLZve9ejpYxPBI/F+PiKyhBKcphO8nhBAlf7W+14yH8/ycl2Mj4cthKgjtLdu8yUeA1
Vguu+4kEQnCWxO8Ksppuf9Hu7K73L6x13EbtexqVgyhu4DSjjQoPxsI3Q7IEzgZiTh+W8olY+H0o
VbmJCogoQSnKYSdJ6iDHw5+jpYJN+Do1XZcOzb0klEPKXEfQ1jx5WnMzJL0FwIrw+6uDJGXLIpr5
uS6jB+DtjP+OUVQ2K7nBkaR1s7duExOP2uUQKxR+IDh7jbhXs0OAILbwUTExGlcs99a1Ut5VaK2o
IJqodjFoPpJqj0BO1kWcdrRLcRp11q3Bo0hO5eKFPTzrKF/ZUE7LVCj82EHL50VxL7zFRjxcTEDU
1bW2neb+pjICpY/WXqHFnk2hBX9znOijNbIxwt6uF3BuEK85OuNyGiR7irf2lfIeGbjzbxfTqUWU
oBRBAGL4ibgnOkCzRRLBwVyu2EQXR1cZ7TJMc1kIddsgN6sCvosaJoRrbwvvTVeSxnvgSsLE31+J
SUBIM0lhbyP+ioSl4Rl9VjinZ3rrIikfe8J5RJwN8ae3lKAUQQEt8wkSVV+EzQly+pi41wchffvn
Upz4WnqAgwKCCP30Foh74kajhfJWHuQ7KliVWbNkXPeVa2eQXu6nX3rrH8Q0I027Fd7JMzQzg88Y
zwhF/Si6H1vBeoLCgLqnuyp9iBKUopJARNonim53RNigA7hJXQ8Y3HwruJGjCkRYYl8W0+kii8K9
neSKuq71GSYo23x3VR0twT5+32PcT7YGZwv3WFrvZQcFfBbT6WH9oWPE+VI+9gTrCaEEzMHapgSl
CIsNPNBRCgUhVNE14K/EvQ5pOzXfqMKsQDfCfGrOWdNGkXaMIup/E9PJIucTvllyXeI5vshVL4KC
BY3mo1dzTyPxAAXCi7l6U/7ckc26T4b2Kyw/tG1Csf3wChYvPAI3VbOelKAU1QDLabmYqu4o7hjU
RaHzwmzHz8D33xPBkrNEuTctuR9KeZdhFkgKmuhQKWaw9fosg6y4LmEVnMjVUcd7N5z3Dlb9a9Te
obCsjbC/6wFYTsfRksoKQKbvE9MWrKOCwrCMSkpVBVjroBS1BPskEkxHBOEAvCm+HlshtV+4C86N
YIXZRreox0D85hjJ5gTfcm4SDIz8LQV+FtxAtsC5nh3gbUPjt0nk2NOo9buAwjTNpQj2utD26AlJ
f/bhOG/9JQmqnPUESx/JVz/y1i9qeTOUoBTVsJUb6Fy6GlwPWCeFw20O7pQ+CpeLpNilwgXY68iI
QleJ1bymrHfDxm8aS+16hHpEau7B8XzmXbSmZ0n4jimNAjwJz9PiS/N+vNhbHxfTQLncfoQ8QZeZ
X4tp6FxTs1AoqlkvsHwWR9TcOkgOLskS0Lh2UOuK4oqxXQggnOZ6617JZkZf6W8aLNG7tbcC2mg5
YQ8gHvWgmESgLKTsd1IJOSjl14lu5UdW2Y+w+DE9G81+fx/0oSkU1bCTxHCSuHeOhiCF+wlZU/c7
vH8Qtd1yzWNdCA/xB6QZnyq79/rLKlElpaD0SLH2KM0KbY8Ux7xUSkVvozWOfbiAFvlMCT7uo5HP
t42WxwJJZ8wMSSfotXe5lHft9VHZ/QGtp0AxZSUoRRCCwj5BuugwR2Fo29OsIzGE1Vq7KUROlWjj
FexB30r3wjRq1epJKE/kSMBABiX6KiIVeGhKZQaEHQLu8/lne535Ms8fpLQvradX+edhUuyQn1Zl
xc44QxwqbSM4cCbh2kN7s8kVzhP2D6ZRowHAijBmr0IRxIJBuvj0iHsGbrqnKfjCHk5oZairGBzx
t+D6x5GYHqPbRPv1lScoDGS8U0wSBuJ2e4v7jK4kLScI7v8SU+e2ggKzg8RTul8hPAf4LKkcSQ3K
0whJ79BJWCDLaEWtT9F14X4dQsvpyArWKBRM1Duht+bcsIdVoaiFrTzMaFsSJZNuMA/aHx2EJZIB
3k3t2EXY5kqsuRwJE/+1c4mUpHa1NiCw3+J9svObJsRkcfZK0VWVi7g3MTn5BmrpyygEt1ChGlnm
em1yyaFUVH5Py2QKCbgtxecQE5xfTsn1wG2/n7c+QuWxnLu8QPJHeccvJWQNoo6oVgR1oSyndjrC
8QD7e4v1k/DFstDCNjoIyAIFoX9Ehx2zjS4Xt/HfD5Tsjw+PG1BGkIp9thTna7X5iMWVqOzgwTf4
PCeJexyol4LbH9MAUWEs/B4+0ikn+9q5F5ES/TivaXxK9wHuO6YEYHzM3dL4wmw8e8SFP809MrQC
OW3nvZ0nDr091feuCIrVdPfsjLipR1AghcUGbvRuB2IsZx3ZGi9cjx0vop3Cd793/SngB1Fw2+mn
b4p7sB5uOcSLvkK3z8vink2Ha5rK1c93zT383FqZerZGDklAx/N3pvVZ9OOzSENSB8j//VI9Ng1Z
gfqt73nrAVdWViiCaqo4HGeJu5vPFunBTbHE4f2IG5ws8RSkWlcfiBfD0vahddch6uqrRS6LaM1O
imBND6BVvoBW1H6OHp02Wj0QkutpPcEyHkmLf5zUnqjciOJh1zOIeNuT0thuKDiHH+QaXcHQwT5B
SvnXeb6clD8lKEWYwwFN813iHii3WVTYrLc6aMogkTMkWiZf6fXYbLWHeG17+EiqV2qPl2815Enk
k8V9aKCNR8LqQYB9tpSPFQX9LDy3/fnf13hN02kVg7iOjXHPNFo5gHKHOq5GTdkF4V/qrQ/w+ZXj
kD4qfteLGW2yLcpmUyjCWD9LxL1o1xbLDnOwwkAW2yW4W8lO3bUxqB7Z3dWDwwWf/qfEpJz/PTX6
Hr5nLbXVbn38uzzDzjKWZp/smvhQC5289ydVEXRhFG2Q08EUoCAp60rGnulqknsPeT0oggcjLsvp
c1QIKlm8iAc+LKYR7PqoP1ihCAoc/F9KtDqMNroF9gr5PtvVPGgMzBLaThLNI1J+SF0HrabjKVx/
7iNCxDBulHi7nzdjnMs2rUVfwFd5/3oD7oX2mDw5bVJMilhDSwNuyDd5TT1NcJ/bSQx7N+C74Ya9
TExCyaQq5ARlAJ1nfk1lL/JDVSjCAOm550XQ4nK0SF6mQAt7SIKmmtuiS1t79UUSFWo1SvuvWTdR
fwq308TEufr43xkST8ZrL7XLfMaVQ9u1oeB7nhik+B1a2L1S7Lxez9+ZIyktJymBnN6gQD1Ysp+l
med9xbl5vE7fiXu2p5iROZ+gAtBeZX/jXKNbxM0SQ0GxWlCKMIAg2hDRZWJjGCc6CvitEsyNZF1R
g3m90PDvoyXVVeaaxnjrBGqoNlg+hIczrnIMex3LUqrR9/Ia+2qQE7o2ID0fcbvVJKpBVACu8tbf
iKl1q7drrR+VJ7igjhYTh0KCxHD+tmawXtvqaFjg/MymcofJ2OOrnAXcW8TFbqH1tC4uk1GhCIo+
akVvc7PmI+y7gdTOwsR3EPRGBtlUCZ7JZzX8zRSmX6cVNa7M69ooyLZQ4EJbHRWjItdBgofw/rRE
684eN7podcANepgYN2yugpICYvoayf/z3rrQW3NorfSXYmp6vRNLrFJxPq/nLSnGPO3+zbrXyGaf
2r2aFHA+MWbno3y+1dqc2XonFDvfJeE7xShBKWIDXChIHJgm7uneeW74CRSKQQGBg2azZ4UkqE4e
uE0k13W0mPIlr7PCdSX/Ha7AsTEKWptePYDCfGBKBKZt5Pl9MV0VUBc2ssK14f5cRBK/hfuhQCsF
mV0XS7Eou17zqQo+Yd3me46jy9z/rKOdZ2eQJJPJl6Pyhpgs4k2n1FA2/OQE6/nuuM1FhSIMuil4
TpPdU3cLIQRBN8npmZCHE26bMynkw2AtvwufAbfdPr7rwLJd20fy+kf5LIs2KbqrojQUtZNdIQBe
lujZa3EBwh1ux9+JSR9/l1Qe1W1J9kBq1oeTaG2niUFSLOqtFyHYmps3KLw7fM8pJ+luAutCxkhW
elpicqOVWPiTaTXBMj5Eag/23ElygkV9h8TcaV0JSuFyQGBVXCC7V933UtjXKni0wd7t1MLDfvc7
JXjFv20OuoMHaBMF2SCSBJpYPs8Dv5CWxHL+joF8XS/fs6qKZRGUoPBeJA9MlPQMTMzR2kAR9LEU
Um01Xt/PZyU1+jcgLvldMU1tp0lzz8fKkZCxbxfH+LlQSJBI8tdiGr+OL1EyyimfOCOIR8JtfpMk
MAZEXXwKF217W5nNaE39dQEEnN171sXVG+K7t0r4BIM2n8WFQ4X014/5Dntp8ByWwH60JKBF7ksy
Q3Hxv4upsQlrMfoFTKekq2OFJZyJUkwmKGTI6sC9RKblvVy2Q301RO0n2Ci0kYT3jOnzbF9KJJdc
RpIqbfqKvbCF/x3sOzfo1PFjMW69RGZUKUEpXCyo7goEtZnWR5AWOHkSlG1REwR9Fb47yCHsx8O1
XYpFvJUAd94irnG02NroygBRzaYA3EnCHOZwltIo/G09E1yvh0rjCkLDApbohbScOiVYr7rejBKU
Jak4rhveALjMLxHTHaJSo1ycl7e43/fkd8Ny+hUtp3VJ/VAlKIWr1pUrI3CHcMPnA34GNLUxEq7a
3JJU2OvF92B6atiJvsj8u4Z/xmdcT8I6kgd2MUlsimS7zsYmStzgrT9463/EZPPlywirPp+gTIsF
CLfpqSHkWit3rh9Ia/kcEtMsKm/5KudnMs8pslDh7kZCxG8k4eGJWgelcBUIuQr7KUzfug4J3yMN
1s1aCZdii2saTvKMAnwvOjOjzuZOCmqQLOJoKyWdo7jDWE8osv0ZBdCiMr8HVicyx56hBr0zZXuy
Q8K7Tvsy/tzCAjFcJBlhuOM/eOsICVZQbdtJraTycpPUYbKvEpTCRRC0Vfj7gXSz5ALuvcF0K4QB
0pqfcrCi7OyfOAQ5hPcXxBSr7qCAQ/3HNklPMajtPxhGFkB4nUJL5JyS54zfiUw5tH76JzFZWy9J
9lsI9UhztEGqBex9xK6Qofef3nqHmEzVoF40nDdkeqJbyC/EYbaTCzSLT+EC23JoSBmSCmpB2dcg
5hFmVoztRHGShKuF6k/yuE8idFf2Aa4OZP7BJw/XIVx9Iyjk05I6viOERWFrl5AUgjozf50YhBPG
PPy3t77BPy+ngEOaeWeG93KbZE8OwnJFR5T5Aaw//DbEhJGdiQy9y3h+g+6LAvcRrGa4t28Xkwlb
F2gMStFISwzJBfs4CN4wXc39GiSSGxA/+n5Mv2EFBfbfimmtA6Gxn6QjvtEu5Udw1yKogSUKhPB+
w3L6jRQn126hoOrVrdwQawhZi1PFdP4ohw4SEaymC7lGSbjSBpuZ+yT3OTwGXfXexApFWMQ1Hynv
oL3igKyR8C4+2+361JhdFLAkUAeCUR3n8nvSkqJtE0rC1CrlqigFfuEE1+zkJpIh3T7BnnaAZM6j
1f49KgtCBWMiLXlk6MGNdw6JaaCEj81BAUFJBmJOd0gD3NdKUAoXUumQ+OKXNrEiqEUE98ZqR02u
kxbbiXRVxAVosfDrj6OAiBO2Jinv8D7E614X44KMctbbaO0O5v2H+w9zgdDWaEAT7GlrKViZmPb6
L9vp5HQSyP18Dmh2/GEpdvcYJLt37g9yLxCTW8/P/ZaY3osNcwMoFGEPc19M2pTt8jBEgvu17fe7
ZF61kaAuTEAjRAD5v/hbjpB44jK2tmydg7WC+/MGhcveEc86LDC0NjqDigESKS4gITdDopUtkRDJ
VnEy4oUH8RljX79fTIbeIMffYeNN6KwyV0xX8sca+SOVoBQumziu0QV2DtMECRd4df1u28VhBK2B
ODORdvIwQ+P8KgklH8O9tlN9xwY4r6UdINoknlZEuGdnU0MXKbY4aqYkq1wGr7dDim3F0O3kAAeL
yQJWE4rO0e7rm/QwNDy7UQlK4SI0uyW+4LjL8L5ChMOTJ0GhsPbZmO8NDvg8Hm50gh4aUfDlSHS2
gW212JadxwNCm0hBNY1WTtT0en8XdkW6SMp2bp8kwTq4lNs3UK5QAwd3Ierg7k7LD9Q6KIUL7ODC
vgZ+/0bH789LsVlrEniVGigO+ZaIlqa1+IYH0IztNNMfUtB0U3CNCHDOC473Eu/r4irosag7bEx1
irglHOGZb6PV9B/eujJN5KQEpXBFTwzC12/Fh9XwcahWOFpReR7oMxO8P0ia+KyYdPYNMdynIOMi
bJo4gv3fozUnAd4HIYUY1yIJnxkJUnyGS9PNG0NQcLsiYeVgCR73tLFNlEWg4PpTYjJbt6btB6qL
T+GCAY7uhHJkgWyj0SHfh8OFVjunS/hkBOuuGpPg/bEze66l9XOJuAeug8Km0V9EjbgjxLUimeJB
MbU1HQ7PUNE4IJaKwuqRUrttmLV4N9BS+qm3HpcEm70qQSnqDX/Pszg+CzESNCW9K8T7ttOC6o7w
vUkLVlgmr3jrOjFxpJMk+Y4LIF6kGM+S4J3I/QXTYQkUpLif78+K+sG6VYdy9auxp63V9DDJCWNJ
npaUu2aVoBQuwr09JgFvExb2C/k+BHXR/r83wm+wLW56ExYicJ39hNbJHgkLcmsdhukWkKc1PE7c
RoZo4kT94S/1sFmauRqvR5bsH8W4fx+TOjR6lZgEhEIRds/EOUU17yAY7eDCKAQ1UIqDB5MEtFZ0
Pkc8CkkM9UjddbGEmi1tvBW8GH1Su+0XzghcemhThI4nD2aFnNSCUrii0eMJgmad2XR0FB/aQXbW
vYeY0ChaYkkDc5au5XVgku90JQNFDIpdrQ4ssOAxHgPzvdDo9UnJWLalEpTChRx6GrzRcSh3BiAp
aI/PiWlyijjXmVKMzbTVef+jPul/SZDoKj1Zz5+iguIXxLNl3avlauMK9DBgLM3NXEuyeEP0gChc
rZdCg6+hJyBBIZkC7jWMa0d6+YFSdPGNqvN1W5KCAPpLWlIduqUUUpxoDIyX4OGXcuS0iVYTYp+P
1clLkJiZqFC4HKZGW1DdEmwWDg47mqWiePWX1Cyx79Fe6egGXDs6scPdgjHyS2kJapGrwu7rKO5z
S06IN2F21x1ZJiclKIUL4szi839mzuEwBtnfyFA7RkytBwLEG/ldqBvZv0H3EJbUj8WMMcCwxjRN
4lU0ThaPE7cGvLb9GMjoFjGdTJ5ohj2lLj5FWPTjIYrbNZVL4EDZtkZT+dmwXubRespLY91rECYo
lFxJ0kTR8TDJXtNSRXxw2Y/Y1+jqggSIe2g9LWyWG6IEpQgLCPwDJXoD0qgWVFArCoFkJEh8jK8f
5PvONgk3iypuQLDcR+sO3RzeIyYupp4NRRDAaoJLD2UMiG1iBPyGZvqBSlCKsICWjxk0cXZFKDha
T7mAexxjCP6JhLAHNdUukixaxbzdwPuJmpTHad3B1Ye+amOUpBQVzslOLuxfzG2612c1NZ2bWAlK
ERYQ6HvFvHfCdtMO06rIdgQfL7tOpvUX677d4HuK377cW9/mnz+kJKUoOR+oobOtiuZRmbEjMtY3
6w9XglKERRtJKtfgAxtWWyx1I9pq/LS06sHvwXj2q8Wkx3+YJKVntLXQ57OSrNK2xUdMj9FaanQt
ohKUIpVIQqsvOL4n6gFtk/TVIa3y1nepMaMzOZq4DlJrqmXICbVQSHZ4SoqTq7fSUnpWGl/ioQSl
aLlD2YjR0rZRLdyVz6TsniBhAk09X/LW8WKSJ0aLtkdqVth2XHA13yrG1fuCz4Jq2RIEJShFGPi7
gMdlOXVJY2JAIKix3jrEWzel8F4jrvBbMZlZuD8YIY8pwGE6lSvSCdsJZSeJCbPN4MJb7q1HaSkp
lKAUIYF4DbLg+sV4UDfwgDaCbAdJcqPf47o/qJNCXArxKcyUwnC64aIuvywDpASX3UNisjex/1FY
+5ZowbYSlMIZyIQ7X4q1RHGgl5pko/Z//wzcdwixH4kJkCNGdbkYl5+SVPYsJ2TfoagW3e1/L6aO
qUdvjRKUIjpQRHqsxFcDlaM1sF+E98dxDVkASBzDD79FgZameqmCFGdztYm6ICvdI7hqMTkaba4e
kRSPWleCUmQReSnOVIqLoAbQGnC5jlZLGvC7/EBS6Ig+NgXnGNfyFP98sGiH9krkBIsJ/Rcfl+DD
NnO+z1CCUiiqHJS4m8TaPmIrHfbtsBgJKpchAYDrfI0kBXfRu721L4k+18BrWt/KgjQgOX2T5NQX
Yl/O9taR3PN470LRNHOFouxhce2ZVwk4qHBzPOd48OP4TbYfX2/GngfqZTCyY7m3zuFqVPJEOy2n
NMuUQolFUq/vRA0T3HrfCklO9lrRR/IzYkoi7uXnLJDGT7VWglKkCnlq6XELwO0SvlULXEqbYyAV
CAAkSQwR01E8a8B9u5EED8K6TBpTL2VT9utNAEFJAvG7Ndwvo7mP6yH78L3I1ENN23xHUkHXljG8
7vPEuE9/KCZh5u1mFzpKUIqgQBfzAyTerDcbXN/p+L6oVpSNgQ3LKEEBqCNDofFVJPv3i5mB1VFn
ssil+P6AJDAnCe5kNDqeI6ZDR5JuUShR6JWHIZnzxD1TD8+xk0rAMFrKIKzbxUzNfV4JSqEwM6DO
kPh712FkwDaH98XR8sX24+uf8Wdjkye+I8ZleoEY19AQae1UdOwRdOW42VvXkcCniHG1oTvHaRLv
2Bg/Oa0hidzruL8B1BxOl2LSSY4WFbqL7C1mztk14uYiV4JSNBUg7PYLuWdsv7wciWg7/76NRNcr
xTYvYYD3DxRt/VOKNymIn6Om/V5p7RZJsJ7gWnuMfwaQYHIbBfxJCRAU9vSLJEVYT69G+KxDxUyD
7l+iVHWSvN7praXeetl3tpSgFC0HSyhhUswLPDSbSG4LeWjbKTTP8f17WN88NMrhun/LArG5eygk
EaNAXGqSxFsekCWCQu3YS7QkodQcSOvyWEkmHR57Gi7F6721LKJ1j+c22adg+BM9bNzvTJ6tB5Wg
FK2MvOzuLrJznHI+gdDNfdVBbbKLBxXZRzfy35CR9CT/DYcvbJKEvzA0DvLtbLJnZVPR4fKDq+ls
b50gJo7YSiTVR8LYSmF+qrcu9tYR3hqZwHOHNwAxIUxJfj3iZ/Xj8+rn+y12Wu4IKdYCgmgvoBX1
erM9QCUoRVAhPrBEkwO5oHfYOh4kEBOCwYu9tae3TuZrtvGQDaHVAzJa7a2fS7G2quAgeLokviSJ
UU363EBON9CKgCvo/fytrUJSHbRA0G8RyREf8dbhkkxyhO0rifHr90t4t3UppnprhuzqgtzqIyi7
fxGTOoVn73fSZDVSSlCKICjN4OujxvYLaqibSUQIPi/31gRvveKtd1FIYKTFp0lSv6V2bwXIIJ9m
GMaa6y/REwBsZtQ0b81t0meH5/IItWtYnZeRpFohLtWPVhMEOWI2BydITsgQvIsksToGxekgkqk/
QcJPTH4ZjvN1KIlR2ycpWg5Ijvi9z2qBK+NpauTTpJgG68dRYjLLuqU4JfRparEjpRjXmuRwPYMo
aDdIMRHDZeG6EMT+Qgs8QzwfZLD9C62p7oj3Liurm5bHdilmfsa9cC6QrXeGxJM1iTTyr4iJz5bu
174K3z+X3990m1ahqAVYK2N9WneeLojTxQTiu2TXRAdoekfT/dAuxXRukBkyy87zCQ+XYkNYdLMk
nhhCM7v4/MDzWSGmRRIm9r5Aod3sbXOw/wZKcnO0bCsjxJ1ci3H9wLXCPT6Hfy61rHIVfuOBPHPD
munhaZquIoibBP3AzhWTfWcPiT34OJCIRa33HbATvXUlNfa873CBUMaRwFbw/13qoPC5HyPhRVWy
4J58TJrXxVcKWBOL+bzaqWhoc1d3QDl7wFs/4X2NApwRJD18gmTTL8T7bLgGCUmvqAWlaBXkaQmV
zh+CcoMiwr8SU4/RxtfAzfARklp7mYMETRZTbP9OzJTYMY4W3YQYFCzbBmd9iz1TxClQo3MdFYVe
3ebO+wduZqT1PxHD56G7BQqIj5PwxeM4a4gT79dswkehqAYIcFTjb5Si/9v69bF/EENCZTsCup/y
1mfFuP76V9lzo2hlHe3odrHxq6guG6t5Dm7B5wr3HsaLYxDiGmmR5qMJnA3UPD0o0Qtl20lMSOoY
4rC3rfI3mu9XglK0BGwiwR94IKFto9YDLVbQuaCD1tLnvfXXJJ1a5FHwffY2hz3bT+JxT+P74eJ7
rUWf7VoS1HX8s5JUOOsJsaeHxST/RAViSGdS4XOVy+30SAxvlpusaeaKWgDZ7OmziOzBXEaystlh
5boVWGtruxRrqfDvcDG9QKG4yYGgOmPau3aO0Qst/HyhZHyP9/PDYjIsdSJubSD29DhXd8TPAqHA
NX6SROsLad3xg9SCUrQCOqnZIeHhQh4eEA383B8Sk/CQ59/1k90H/4HA0BcOqc0/kGKhoSUHW0MV
Bm08gHFYUH4CbWVLADVS14qp4dmq2z7QPUPdE1x7T0b8LJwZuMdPj0E5gJIxnQqlEpSi6TGUWh20
O9uBwMaQDpLd07y7STg7pdiOCDU3V5cIP7wfAd0+B+0TRBjnmPOC6BRY2w39R1JsQaWoDFsHuCAG
5QZdLuDaQ6Ft1GzKHM9H07TuUhefohoQcJ0juwdtKyk2IKS3KOD2oZWDdi2YCDpVirEpEBMC8/Mc
rsm6HOPau3FPCc4qoChgrhSG4aGWZpbKh4rYRuspauYeCAmJEWhVNDiGfYjzh6zMpomp6gZU1LKg
ZpbZJ7Z6HWuQj7BsRpwtvm3j+78kxcw7IUGtdjzgw2h9xVW7oxZUEYgH3s7n/kkxIym0VrI8CTwr
0YdcovXSuyX8GJtq17ZR3CftQilB70LUUa0iETe0BEEJSlEJeZ+7oDTxARv3KTHJBe/lPrKJEBBu
w/l+20GiowwpbHc8SINplcVJUJq9VoStkcKzvJLWaisV8lr3ZiU3GdzUc8UkR0QBEosuFdM1Is4h
oD0OpIJnjREkqF/cV4xbfgXPJ5reIiFqIb0jdVXmlKAU1QgKgsn2bCt1P+S4sXEYMNoalfTjpTjJ
9cAqgg2EsIUb3sUt0mpjI+oNZFf+mILz3dSs+7XA7/YrK+X2PPY6miQ/QAHuCpwbdIw4ncqc617e
RkLtz+djPRth47qIf/2NmLEs8Igcwc/o4edhnhbc8W/w3D5JC7I76QeiBKWQKiQCX/YfaCUN4MHC
YUK6ODKPDuHfbeemhWsARbvo8rBPFYKyHRxca6B03yYPpN8jaQJuv8uocAxscsUgJ7t2Dy9nXcFy
ilr3BBc1+lHuJdFcqDhD9/CaTyWBrg/pmRhBYgJZDvPdA39ZyUiSWBcJCgXe82lZ4X4kNodKD7qi
GkEt99ZvxGTsQTjtL8Xmr52+DYxYxeVi6mmgXR5Tw83gkr1nracRor3j6gVozBiPsopKihVizZz9
21ZFqYJChX57KyN8fieVuJMkumsPZxFlHHC1j6JSuIkkEuZaTvORUyXS7vAR2li+B9bkgyRs9LN8
UQlKUU9tEpsRQdNbxfiiv0RtqpRs1tCC2s7X7VfDetoobuOwbRfzON1NcU7nbUas5vNfRcF3jphu
Ba2WPGFTyxfScnHFYST60TFYo/2oDD5LxXB0iGvL0Sr6AP/bHuJ9/bmG8qxjj9wtptbxSYkxpqsE
pai1GXM+QllGTautRMCv92lScFvsW4WgeukSeNjhekCOYbo8B7ESXWNhrWZNP07lA/fqbD7nJIb/
pRVQvtB3b0GEz8D+PZNWSxy1SjhjR9GqQzuyO0NYMaP4HOeIu+vWZu3i/ZdQocUQU8Sr3kzSnFUo
RIpppu8Q4+abIrt3NbcbFZtzOjU6rAlS3hUE194ialthY1CYiop4yMSYBKO9lp/qow4EWMpIiFlO
2WELptuanKgKJOZbxK12zyp7aJB8uUSPPfk/07q93+D1BbHw8iSmy2K6FmtVTaNy2o8EtSHqD1QL
SlENdqzFFJKObdJqa4fyXMjeey+1zFep0e1fYX9BG98qbiMurMaWi1n4aB1UcCB7bSWJCqPkT+Ya
Is1LUlBkUFbxfITPmEkr42CJN4aK8ziJZwPp4UESJHCez5LqmbYuJIUMwENImHD/3cB9ogSliJ2Y
QDDIDIJLDcHXASQj+OI3UTsaw01phxd2UnvDYT6nwmd38b29joexldxKaQWUjCdofdp2P3BdNWsC
BVybj/P3uspZO0ojidEuOZ6rnQGULZzZ88V0r0iiqaydnH0pr+s6EqcSlCK2zQ6NDEWa51IT6qDg
gWBCsPxGuhKQvXcGyWwIXzOZwqpcnKiX75/neF32GuJ23yjcLYtHfUrH6dw7cYxXtwpMPgUKCZQy
xGG3OL7/UJ6JcQkRuLVeahFOOz0hl0iyXULwPSjwvkhMpt9KcUwsUYJSlNvsE6jtjSkjHEAQ8C//
lsQFzXKOmO7mw/jeSs1c8V4kSDzicF1xzoHyC9ht+sgjE8njtKoXUKk5VeIpprbPZkCDZVUUN/BA
no8TJbliZwh/xMhquffgfnu/GDdj0g1lLUldICYN/mHXD1Eo/GiTYmZOaYujHv792TwU6Dhgu0ic
ICaFtk2qN5PdJG4ZPnb6bVwHq4+H+hl95LFgMbVlxGngEjtLds/4DKuQ5Cl0e6VynU49yGkHl6v1
NEeS635iR38gNriuxmtRIHyExNtaqRr6kZg3UdlYqASliGI52djT2WU2sZ3t9CMK90nUxpAUsZTE
FYQUsFG3Ol5fZ4wukj4S7FP66GMDFBh0GPg6lRC4/FzT0XN8X6/veeWk/jEum8HnmtQDpe1wSa64
3Pa1DOJCW0RLZiCV0KCDP21hvc0azIV4hiDmM3jOXpSQ40mUoFobdthgD7UdZPVcLMXZNHZj7uSf
0ZPrKr4PrzmGm8/GnNpqHCRow684Xutwuij6x3iwd0j4ib6K2sLsCQp1uP4uIlG5WBDYZ0O4P1fz
/8fWmaRs1qmLKxgxp2mSbIso6wlYF+C1sGC+SbJAJh9cfTNJVpWIx57beTzjx0k4V2WO1i8yPR+V
kK4+JajWBA7MbJr7QssIm/y9JIG1UszWe5SbE68fxI29nFbTr/n3IwN8py3QfcTxmhEPOyVGghLR
abpJwqajv8L7fLa4uelssfhOaVyGIL7bZYgjztJRkmyj3V7e45UBX7uA5x31jLNIUieReKqVcFgi
hGyYGPJZwFI7kmu+hGhzpgTVmoCwv8Jb7+TGg+mPFiUotEUNw2a6aB7ggua1Jzczina/JSZr62Ee
jOEBNqydAfWs4zXD3TNJ4kuSiFKPpQh+j2FFfYPC8TxHksIzH+8jrHqiQIEatnckLJIZXEnK2W4q
fqtDvGc7z+1Knm8ooX8lJm5YaulaN92pJCgXV6X9jBMoM+YrQSkqHfLptIymU4tql2Iw+y7+91Fa
KiAT+M9P5AYdTYKaR8ETJgW4j8S31vH68xJv5pH13auLrz4kdRWVjHMdrGAbjyr4iKJeY82tW3F0
yPchjftAST4hoYfE4bqPt5GkumjpnVXm+eT5dxN9/x8WeF4ICRyqBKUoBbQXuPPQgRjuuGtoNR0v
xU7FiMc8yI16P1+3kcQ0WYq1LXALXEILCrOCpgbcsD0khC5HISH8zikxWVF29o8OK6wPEJdCL7uT
JZqbdiuf3Yg6WVPYayidGBfyfRDEcfaNrKYAuLog/Z8xn+f/hCrPJ4qL1SoZw3lPAtVFKUE1P3Cw
/oLulYPpCoDLbgFJaZDsmp3zJveFFd5buaHs5hxBLWs4teHBAQ/AG7TMXLVYm3UXV2Gt7WKuhbr1
s6Te5AriEq4ms+zerFcv0faQsjJPi2uMJBs3i5oCX6pAokxgOZ9PW0L3cRQ/f3XQNyiaF3i+aIf/
cTEuvQ5qMdCSfsRNabVEuCTeT6ENvzkCvPtzQ/k1VXwGGkLuJcHTvnsjEhRI8ABqsrmYDvZ2cXc3
KtywzWcBuWrhg6X61NskkAv5PUN5bpKeW4ZzhQkDK2L6vGepvM6UZFyTHZQbewYlqLyemaYFLB1U
caN78mSSia11mkVXyyqSFAgKrrMT+WdsUCRNIANpDynfvbx/iP0DgfK2hAvk+gF3IzL4plX5Tjvu
Gt+zRaq77qw2P1+3SV0BqyJqmnhOqheDJwFbvB5UaM+gQpW0e69XitmScWA9lbak3N5WYd47zBsU
zQe4FpAy/h6S0cCSwzaDm/t5bph2HngEoF/iv6Go7mISXRzugw0RLBY7qHBQFU0WPm10hbhZjFvz
L6VybzI7w+ol3Sp1wxAqO3HGjnp8cixJS8q6poZJsLKE/UhQSctX66bui/F3Jmn1WdfnyDBvUDSf
IEDxLNx6h8rutQ22I8NAWhwF314AscGNhsFn6BBxq0SvE7L1Tw+IWwdzG1wdJ8H94rkAB7tHogWW
FeGA+OcxEl8dm3VvLZPkJyJ3knD2C/h6EFmUOFsYQoFXYc+YPm8kCSTJ2F5bmM9XC6q5AJcCYkdX
cON2VFFMoBFijMY6KXaBwKEaT3JqpzVyiQRrY1RLkPw+gtZlr69Hil0vcmV+O4Yq7ivFsRzVCGq7
xDBQTRH4GR4o8c5Cso2H+7jXk5Rl+OzZJKi5AcisXs1trTdkegyfhfO0P4m4I+FrHkhFZUeQjaNo
HqBO4YNi0ls7amzGoTxIt/uspHYp1pvAZYa6pe6I1wSCQrr66hg+B0T3mwob21qG+F2DauxtO+p9
nW6ZugBC9DCJt2GqjZviWb8lyZYL5ChUhwewAMfwHHbU4b7aMRsTeW1RMIDK3bQ6WFDDJOBcLCWo
5gEe+LFiUsCrxWps/U8b/4uWRS/QMsnzvUP57yMibtYCSW6FFOMFLofQjhTfQVKJKoxswae6+JIH
XM6ovztJ4m1ThT0xlase6ebtJJ9aRDCLXoyOOt1f3FNk5R4VQZ53UnacJMkMMSx3pgOb3ormAALQ
cMeNrEFOSPV9gwIa70FGzS9pUbTzwJ/CvYH/3xSBEGD1wF14bwShAO3wQGqwcGVcGFHQWetQO0gk
Dyg6aBT7LjFtquKWN9gfcEnXo4FsJ4lndo3XTeE+rVf4BN+Dbi9ICjrSgaxBuqiR/KSYfnxJd+iA
TECm7dagP06RfSCB4FJqUh01hDNSy+HW259aF4KicJttp+m9JzVexJ9e4n8/4ahZ9ZCgHnP8XSBb
FAOfSBdEp9R239WCnQo8T7dNooCgRpd7FIkfnaDgq5eS7Y9D3SOVa7naJPmswlJrZBDvNb4X3ToQ
m0Mh/isVrtO6+OF2PZUKqW3LlPR12+L/HUFvuiLbyFEYXCrBGnHiACE54DZqXvtyc6+kNtWfnwdL
aj2trS4HgipwI66Q2pM+qxHvxbTy7KGPY5Q4SPN+3TqJEQYC7e+hZr63FGvwsn7O+lFpgjt9c5V9
b1c9SQqEc46Y4YirSFS2ZKT0tVhIkoJbD5m+Q+rIBTbEEKhYWwkq+xjFTTk+gDaJjTmCmuCjfP77
cLO8QMHSw80Oy+V/xbgCexwOnC0ivDvCbxvI64vTPRS1aFhRGYNplaM4/HQqPM0kY/xxqEoEBeXn
5Qb8dluE35+KKtyMO6tYULbYvp7Wnp/AA99wRba1VWzEd1ewcOxmyPuEMwQzCnRnUrOFRYW4FJrH
nsCD95qY5rI/pqtgNTXHMP7tXlplj0QQBkOk2AcwLo10Jy1DTZCIF3B9nUHF5hiJ7opNIzqk6Oar
1L0BVstztEwaJV9tKvfAlN0/yJ9NVchdCarJABcYkgb2LfMsC9wMa2TXoG2e1tbhJcQFf/X3SQqw
WsbSBYBRCSjYnSoBU0OJnrCbsQTDeY1x9gSL2hNQsTuG8Tn9Ba2mCdIcLr1K8nImz8ddFV6zngpd
tyQ/aiNr6KFyvCSMBq7ILpDhdkEF6wmb4QVaQVt9zxtJEBdTC7RV3VN48H4oJqEBBa+oh0AAdTsP
46YQpnkvrTDX7hFCgjzNd8jjEHjWgnxKt04s1juC7Mj++hIJak8pX0TdLLBd/zuq/MbNtK7Wi45y
Kee9eIpySQmqBdBWxZViXWz3S7EQ1/qpR1KQ2B58o6gFg7SQMDGamjHSVk+W4uTNoG39bVHtHREE
AeJge0m8NS52aOKbunWcAYUB6ch/7a0veOvTYlzDQ1pEntQqNLVj1dGIWN3IRdjuLTh7G8OYrIrs
YhvJY2KFZ1mooMWVan9wyaDA8HNigtzWatmHVhRI7rdiYgtTAmjIO2mprIkgBCAABsasjdumtW/p
1nEipsO4B1DQeQSViGa2mCrtzREk5Erua7iwEIc6W+ItTs4yumk9PSuaJNEyQDzlRjGpvGNLBAWe
LVrMXCK1U8Sh+SJTbrwUs3oKUsxamkqz/FmSYWcN6+k1kpqrew/Xca7EW9Vu3XsLJPnmos2EobSu
QUgn0qoeIuHjTD1NInOsm6+9hjDeoftsF0V5M2XCE2HeqASVbcDP/Tsx8aLS8RJttIr2kWDZPHnZ
1UVje9tNp1D6lZhY1JEkrWpjLxAI/X2E34VA+/kSbxaSnzgVtYXwSFrPKOiGC+9gWrUuCRB2GjIw
TrLtCsxJ7Xq8Hp8HYahoKAWEbUfKb1GCai3NBC6+X4spipzqOziWYKJU8Pcn+SFpAt0m7qTAurwC
edh5Ur8Rd/def1pzQYqOwwpJ9PFbodumqjyACxfZeBhyeQH/O0CqJwYEVRCa5czVKjTtpaWAbNE9
JH3p3vW+X6g7nBvWelKCag7Yzgh/EDOkMM6O0XbE9j48aC+SfM6h4MqVuQ709bvN8fs6SICXSLiU
9iCHJHSAtkUspfG0lEZQmZlBZWcIBWtbTN8zwvfnrAvcbqnd5X8xrfWjeH5a1YqCq/MBEtQ2JajW
BGJR11OgwDUW17C0ghS7Rp9M6wgLCRMfkaJL0dYX3SzFxrNhgcxBBJVPIQHGOS7bzg56ULfKn/YF
XKh7UaHAeBa0yRrFZ92PVmycRJLzPc+sE5QdH1Orvg8ZfHBrPU6rNKlkCZsp2JnSe7WSCutCV5Ne
kX1gk6ITxDfEZKhdRoEfhaSsaY7PQwLG6bTSoBki7oUC4QHUJO0mBEkuD/k9IFMUBCN1+Z0kw7ib
Vvbyuu5rUSsJe2GOmPgPrFTUK6GkAC153sXnm/TIimaQNTbYj/hSkG7cKLVYQOVuYgLkXPAR5ciU
kT+UQrj5b6Vi2N2qm0ZhsJMkdRUJ6zJuWldhb6u+Ucv0SQq5ySQoxHHQEf08/j+KgVHg+2JITR6f
9z4xGXsoFB6SkKDE4Vgv7nGxLKKdRASLdG9apRN4fzv57ydJsZWUojYZoFgdiUKPSrBU6e1Uig6n
Qjcwwndb91hp6UV7iu8VZMcNYmbOtaxWo9h1Y8CauZruG7TQv8jnvglrdeAz4M47ikJuNl0W6Jb8
HTFp5yCosA1hbazjgySoUQnuRWhycD/Ok+av7LfWEjT2PXiP4TYdIfHFk1qZnG7n2VoU4r3PkNRw
hvZyVAYQx7mT13G27NpdZajvz2kjp2vE1IO1tNmt2H2DIN7yfWrQb1B7m8KNHbSw0s6ZgTWGLMHP
iEk576VFAuvqJQfTfQK1ebiYLic55cr8hj7+vc2Y6hW3olBLUM3cfw/3BC6k03lfcX+RCWm7W6uF
5H6WsP83kiC+5a0nJUShKV/7KJU4lGe4ZKfijCEDDm7Fo2XXGGEuZfcL5AR3//843KvdoBpVcx+u
jdRgXiWhbKKG3SPFEe/5Cpu8lwcCJAQf8hoekudLSCQMIEQ/7q2/ojVWbkQIPhPuuBepOaIqfxkP
+TQJX4djx37cJs3p4sM9RNYj4ndIXJnD+wzlolZqeD1nFmXx/GD/Yzghxs78KoLARXkDOpjApT3V
wTAo8P1LqKTVc2Kvi5X5P5QVhagfqhZU8wOH40ZubGjX9/PPCIwfz83uj1MVSF4r6ZpAQHgVD2lU
DR/k9CEpFvrmK7gzsLm/KyaBAkka2yksQK6nSriMpZwUm+I2ExBfRGcHtB56N+/pQLWWYgGUpC1U
zGA1zRXHIL8PT0hxkvVUqT752p5DnIWdfKZTeJYf5rlNU4spO0YDbr1vxkVOSlCtBWz0p7lspwCQ
1Z6y+1gAEBTcYog3vR7Dd0N4foDkNLaKEO0jIcEdcnPJv43gAThMqneyKEdQw2h9Pd0Ez9EmlyAJ
5h1iamxcG7Wq9bQ7Kdh6uXu99VOege6Yzt9C7mvMzZpZQ/6CnP7IM3qQzxNgZ6ydLfGWYrjCds2w
MafIbj0lKEWB2tiddfguO5L+PSSWfABrZwCtJH836C3UZDH7CskVQfv02flXCFLflPHnNpaC6XBa
TaP0DMdmAYAQNpAQoCDBpfxinMJWjIvuHlpQU6lYVFISsPeR2HMtyewg/j0SkxbQk9CvwTIE9wzu
91tJ5s/F/SW6uRVJYwyF6p5S281ms9BO40H2T+OFFouGtehkcVEIgrLdMCZl+B520FK6lAvu0rg7
vbeiktZDiwl9Ah8kKWEtipmYLDZxD/+KFtXJUhx9U/osu/mMT+Gz30uKBb+DErq+MIT+Nu/ZjVQc
E2khpgSlSBKwhI6mUB0UYk8OJVGVwlbxr5JwxaV5KWa07cjYPRxIiwkZj3DpjRBNbopKTF20yDH+
4SEx06TnJ2AxlftuCPJXaU09TJJCLHFYCVHhzydwwWsA197rtLymNEh2W0vzNSqQsJqeSPJMKUEp
kkKOVhPqnKaFEKo5uj6mUjiX9u8CQcHPPSvEZ+J1o3gdL2ToHo6jtXiWmOy8wWo1OQvWnRSkW7h/
4D57nAJ2UwNI8ilaarbLN4qmj+MztlbSCb7Xv0iSwtiTg6W+7r0C799aWku2WHlJ0l+smpgiKSDu
82FaT2EEq98lZ10J62XX2NVouj76hfhMLCRgPJah+4e0cUyuPUTib//UCpYS3GTI/kR5ARIOfk7h
iuJzxE0WU/A28hrfIEkiloOOC8vFxF8H0IDo5N5v4/+P5d6IQ3bjfPXQM9Enu48RsS7Q9bSYfuSt
X4iJ0a2rl5arUMSNDmqE36PV4pJh1kVXAlrFLOSBQLYQuiMgtfoS2T37sBogqBC/uiID9w/FzB8T
M+NroiqSoTX9nRSsC6npv0HF5BlpbOwmiDzGs4dLdzr3OayoESSngo+sogLk/So9CrhXKOnYQ4qD
T0FYcH0+QktpPu9nT71viEIR956a6q3Pi0ktjzILx/q8rRaM9F+0XBrjYFHgc5DFd1m9D1kItJPQ
QaLIVMz6cL96wU6w3UBCeoKWNzLenuL+KWTsN4GEkLl3HBdiuUN8VlVnhL3RR3L6mZgCdiiDw6gM
jeDn2lZncIeukga1CdMYlCIJ7R+uqXeFtHDKIU+Cw0IMaV8e3JzjZ+GAjybRpZHYUd90pRTHXyg5
VbaUuqUYV0J8BskOyyhQn5NdSxSyiF7+lmf42w7h/kVsaiatrNFSdP3ZBsA5KmB99GSU7iH8G+Jw
iMH9lt8hvtf1ldznhmtsCkVcQHAXQdz3+1wFcSEfUWD/f/bO7cXKKgzj7548jglNGGVRWaGVBQlG
JEERQUZ2EdFNF91Fl133dxR000XRgUgJOtiBIDMhqRCMsiKStNKhUMPIbMw5tH+sZzXbA7Sd/e39
rW/v54HFzOh22Hu5vvdZ71rP+7xjyrxuKpCgCCTrlDk9Fuf3JzTma28IsBRd71ZmvV9Z09EhzQ73
arT0jHGKgBH0pfo5b94QDqGA5UjumF6Xs/Asqz8ocno/0h3cXAchFnmkYBhVgYdhS/Tei6ofGNMR
Br2ndhb2vjjWeyqSYm/C5HTOjn9KgZreR7le6Rvt/v8YobnIThdfa+TNDcdz12uTs1IkhLBhTZzp
upItzFAPTka5R90mKKNy5Pbhd0f/uof2SgQT2mmWNGcc6z3ZHo+anM7JHLg7wpyYeqHDypy4rN8X
zbtT6uc8HdX44qy/+7zpH84EZVS5liai2s6eVbttV6WAqgrsbjkOfbzh5JTvhPKOvtXD78lFtBDT
rkjHeFj7HDEpjWZQMYwqwPHe5uhNtXf2zpBL8BVDmlUgIMEfMDdsbPJnzL5xfAak0UsW8Dtyzdsn
IqZvRUxH/WiZoAyjV3C892D0rtzLO2kkwz9GugweH7K5IpNbExfuslEqspEvxLTxAgmq00GcAtAX
IgkeTvuRMkxQRhVoKSgttO3D+UDQXjak88WlNm7UmOIuHYLPw7HeBq2BxV3+m6zIO6asCaeCfjiI
GyYoY8QxrkygqmAL4U0okA+biwKfjYr9h2Nh7b9LBNZU9+v7brIn1GOo73aImDgeLN3lwTBBGQ0F
x3sPRfeO5d2g17qnkrMNlHvrh+j5ywXV3WZNyJzxxMPZg/qeWT9ChgnK6BdQ7t0RC7scHzUsVwY1
PkKfOav8qGNC+vyyMqcjXg6GCcoYxA4acmrCcVV2bK7rOCkfhy4ekbXBPGdrnZ0xLxv/y4+NYYIy
BhWEZqL6uqVhBHdrt49AtpmzJu6aUOc9H6mQ1MRkmKCMgYL6FaxnVhceeFsxf7dVl/cY93TY0tQh
/pjR6KWYtlty+lOEhBDiw/Bdk2GCMmoCNSzvRvK5Kz0zyG4SdREUHYLxQ7tmwM8f5HBIGwkk7v2Q
t2cniOMiJWqa9oisDMMEZdSWQbFDpjHcxQWvq5ayh6VRXzsG3Lc58tqouRoWzIiIPlXWxPjSj4bR
C9yp06hq53xaXznmK7WXEUH0gLK9kzW9B+YJJV/VsvxuyJlC6uuiWkFLdoI42B5vR+qi/JayNcNw
BmUUAdqxv6g19XSkvjQlEuk/Cqh1gSJVbJxQtq2KwYpKLqpwU5prmhBBfNwe70VygtjvR8FwBmWU
iCkFLjz5SnRJgJwoEt1W8/tgXnAyvyWap+bLJE+/Ie6ZXmqP1yMV3v7uR8AwQRklBy9AN931hQXf
WWV5H0RqelcnTiqYY4R7eTTjJKNTNr6zPV7VeCfS3aNhmKCM4kHwpUUCrdVXa42VkEmx66cD6zOR
hAp1kyX1QBzzXSFCL5mkICaEMLhAvNYer4TvmQwTlNFAEHxRcyE1XiWSWlQzSfGekMJztPdmlGFK
ynHoQWUktKKf0DyNFfZ/CYkiFd8ayaKI+eOeacZL3TBBGU0EjQZ/itRK4UqNujKErDLDaufZKMv/
jXn6RYOsE2FJvrubrZGsppXhcSSK4hFl3huRmgie8vI2TFBG08GR2uFIR0PXtsclMTi/vs4MCXLa
K3LaXeA8TSkj+UqEvkSDeauyv1Y3c5aP8r6P+WO8bSL3v72kjUHDvmlGvwEx3RPJveARZQjjfQy8
c9r9E+wpyqXP0HPtsT3Kt9qhLuq29rixPa5qjyc0f3m+xuJMo9tWBXOVZfeM7yLJxakVo9D2uJev
YYIyhh0EVnogbW6PtSKqyxR4q8ziZ5QB4AG3VQT1QyTV2WzDnkvu7rZEKqzdFMl14moRfCbhlXGm
UrLVxfxMiZSYj5PK3KhjOqFMble43bphgjJGFKjWHmiPmyO5KfAzrd2X97AepxVsJyPV5iB9/qgj
S2gysePKsUFfb22PGyK5oU8qS9wkks8OFXy/KOYNYaeVHfH1kMj6Z5FVzpo+C4seDBOUYfy37sig
7hNBrYtU3LuiY022FGiXdWRZMwrE0xr5zxBkbFe2xH3JgSGcL+YBpR+S9I3KFCGaO0Vkp0RQy/Wa
tZpPXrNHWdOvmp/fws7ihgnKMP4XkA8iintjvstsbotBoL1LJLZYwXafsocTyo4ItIgxdihojxLG
ROJzHdkiRrh0OF6j+ZzUnM15qRlNw78CDADKlG/7SdwDDwAAAABJRU5ErkJggg=="
                        transform="matrix(0.3112 0 0 0.3112 58.4798 64.8365)"
                    />
                </g>
            </g>
            <image
                ref={dropRef}
                style={{ overflow: 'visible' }}
                width="145"
                height="240"
                xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJEAAADwCAYAAAAAcIbcAAAACXBIWXMAAAsTAAALEwEAmpwYAAAG
X2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0w
TXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRh
LyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxNDggNzkuMTY0MDM2LCAyMDE5LzA4LzEz
LTAxOjA2OjU3ICAgICAgICAiPiA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3Jn
LzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPiA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0i
IiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtbG5zOmRjPSJodHRw
Oi8vcHVybC5vcmcvZGMvZWxlbWVudHMvMS4xLyIgeG1sbnM6cGhvdG9zaG9wPSJodHRwOi8vbnMu
YWRvYmUuY29tL3Bob3Rvc2hvcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNv
bS94YXAvMS4wL21tLyIgeG1sbnM6c3RFdnQ9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9z
VHlwZS9SZXNvdXJjZUV2ZW50IyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgMjEu
MCAoTWFjaW50b3NoKSIgeG1wOkNyZWF0ZURhdGU9IjIwMjAtMDItMDZUMTI6NDk6MjYtMDU6MDAi
IHhtcDpNb2RpZnlEYXRlPSIyMDIwLTAyLTEwVDE0OjEzOjQxLTA1OjAwIiB4bXA6TWV0YWRhdGFE
YXRlPSIyMDIwLTAyLTEwVDE0OjEzOjQxLTA1OjAwIiBkYzpmb3JtYXQ9ImltYWdlL3BuZyIgcGhv
dG9zaG9wOkNvbG9yTW9kZT0iMyIgcGhvdG9zaG9wOklDQ1Byb2ZpbGU9InNSR0IgSUVDNjE5NjYt
Mi4xIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOmNmOWEzYzE4LTQxNWEtNDBiMC04ODNjLWMw
NmIxMjdkZDgyZCIgeG1wTU06RG9jdW1lbnRJRD0iYWRvYmU6ZG9jaWQ6cGhvdG9zaG9wOjZmOTA4
NGNkLWI1MzctZGI0My1hMjcyLWQyNGZhYTlhY2U5OSIgeG1wTU06T3JpZ2luYWxEb2N1bWVudElE
PSJ4bXAuZGlkOmVjZmFjZTdlLTQ2OWUtNDVlMC05Zjc4LWRlOWFhYTAzZTA0YSI+IDx4bXBNTTpI
aXN0b3J5PiA8cmRmOlNlcT4gPHJkZjpsaSBzdEV2dDphY3Rpb249ImNyZWF0ZWQiIHN0RXZ0Omlu
c3RhbmNlSUQ9InhtcC5paWQ6ZWNmYWNlN2UtNDY5ZS00NWUwLTlmNzgtZGU5YWFhMDNlMDRhIiBz
dEV2dDp3aGVuPSIyMDIwLTAyLTA2VDEyOjQ5OjI2LTA1OjAwIiBzdEV2dDpzb2Z0d2FyZUFnZW50
PSJBZG9iZSBQaG90b3Nob3AgMjEuMCAoTWFjaW50b3NoKSIvPiA8cmRmOmxpIHN0RXZ0OmFjdGlv
bj0iY29udmVydGVkIiBzdEV2dDpwYXJhbWV0ZXJzPSJmcm9tIGFwcGxpY2F0aW9uL3ZuZC5hZG9i
ZS5waG90b3Nob3AgdG8gaW1hZ2UvcG5nIi8+IDxyZGY6bGkgc3RFdnQ6YWN0aW9uPSJzYXZlZCIg
c3RFdnQ6aW5zdGFuY2VJRD0ieG1wLmlpZDpjZjlhM2MxOC00MTVhLTQwYjAtODgzYy1jMDZiMTI3
ZGQ4MmQiIHN0RXZ0OndoZW49IjIwMjAtMDItMTBUMTQ6MTM6NDEtMDU6MDAiIHN0RXZ0OnNvZnR3
YXJlQWdlbnQ9IkFkb2JlIFBob3Rvc2hvcCAyMS4wIChNYWNpbnRvc2gpIiBzdEV2dDpjaGFuZ2Vk
PSIvIi8+IDwvcmRmOlNlcT4gPC94bXBNTTpIaXN0b3J5PiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9y
ZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuCCH4IAABjWSURBVHic7Z15
kGVVfcc/973X26zdA8zCDLPBzAgEBIKgKBIVIUKIIhJB1JhRSkjUMovlH6mKAZOKVpnNkJShUsRs
VgQ0C2Qtk5TRCONGIo7sMOMADsOIArP09HT3zR/fPvTrN285567nLt+qqaaafu+dd+/3/vYlCMOQ
KuPq7Ts6fxUALWAx0ARmgSlgEpjp/OM7bjs/5RP6j1beB/AIATAMrAJOBc4EjkcE2g3cDzwCvABM
mxddvX1H5YmUOYm6PPk+IAAWAacD7wJeC6wGxub+3xHgMeBO4AvAHuBoLif1ELUkElpI+nwQuAxY
yvy1CYElwHHAyYhcnwa+P/f/Ko9G3gfwAAGwDLgCuBwYB4bmfh+w8BodB7x77m+Xml96Kl0zQ00i
Gc8nAW9EBBp0TVYA7wG2IpJVHjWJpLY2Yk+KBnAasptG0jtWcVCTSMTZglSaLUaAS4EJamlUkwgR
4jTcnIwGcBYytJtQbbuoJpHsofNxlygrgDNoI19ViVR1Eo0BPwNsjvDaJiLfIiqu0qpMogDYBvw8
ilRHef0FwEpqElUWy4H3AacQnQRrkW1UaZVWVRI1gdcAV8/9d1SMAj9NW+CxiqgqidYA70cJ1jho
ABcBG2gjY9WkURVJNIKM6deRzPdfC7ySaHZVKVBFEm0A3ou8qiQwBPwscvkraWBXjUTDwFtRrVCS
N/wVKGY0ZH5x9fYdlVFrVSoFCVA86DqSVz0TwC8A9wH7aCsRGUSkMhS0VUkSDaFM/bYU3jtAdtab
UQCzUqgSiU5ANzmOS98Pi4CPAK+iYkZ2VUjUAs4GziHd77wZ+BVgXYqf4R2qQqIlwBtwK/eIAhM3
egsVCkBWhUQnAK8mPVXWjsXIyN5IRVz+qpBoC8qRZfl5rye5WJTXqAKJRpAtlKV6GQGuoiIByCqQ
aAI4j+xjYmcBLycbFZoryk4iUzN0Tg6fvRjFjhbn8NmZouwkGgUuRoVjWaOBgpsbKPl1LvWXQx7S
ZbTltDKG6WcrdXqpzCQaAi5BnRx5nuHnKLm7X1YSBcAm4FrsGwzT6qt/OapdKm2jY1lJNIrU2NnY
S4C0JMUIarten+Jn5IoykihA6uMa8rOFOnE2GgJRygx/GUk0hDLpp+HPkz+COku20XHNy1C4VkYS
LUN5Mt/iM1tQc8BE3gdJGmUkkWlv9kUKGTRRKuRCOuqNii6NykiiCdSB4RuJQEOyPkjJ6o3KSKJV
SBr5CNN6fRn+qdvIKBuJhpAn5HNMZgQ1C5yIn9LSGWUj0YnAlfh9cwKUEL4ExbOAYttFZSLREPAm
8k1z2GIIxbHW4DfhrVAmEq0HrsefAGM/BMw3DhR+okhZSDQCvAN1thYFY6gbd0HzQBGJVAYSBahy
8XqKVXJhOkM2U/D7UOjDz2El8GEUG8oDIVoccwQtk3HBKjSFttDNjkUnkRkTcyn5GqhHgS8Buxxf
10A9ausp8L0o7MERaV6GOk4Xkx+JzHaiIeBuuqyzGvDaU9HUtgUTRYqEIpPoeOAGNAk/bzQQEUaB
vY6vHUMzAo5L+lBZoagkGkEF+NeSvzFtKiLH0PzGh3CrkgzQpLUFSeMiSaMikqiBpM+v4ke/e9D2
cx1y2Q85vsfxKFDqw/dxRhFJNAFsR7XLvp2/gWycvbhJowZak7WBAkoj327CIAwhNfYu/O0sXYQM
fRcDG1TSeylt+bSioGgk2gR8ABmhvuacAnS+g46vawFvp6PWqAizH4tEoqUoKn1e3gexQAu5/a5t
SGeiWqNCFfQXhURDqKz03RQjuhsgteSq0oZQe1GhUiFFOGgDGZy/jIZVFQVmh6zra05Hc7EXFNb5
rNKKQKJlKB50If7aQb3QIJo0eifqDinC/fH+kENop9j1FEONdSIApiK8bhuKYhcibuQ7idYiNeZr
98YghCgU4ZrdbyJptJECxI18JtFSpMYuwu9z9kOApGmU829GRWtLEj1RCvD15jSBn0Qdo4ULvnUg
qgQ1caOT6ZBGvkkkX0m0BvglNCQqrZEvWSDu2TejeiOfW6C8JNEIunBvQucroi1kEPfsQyg2dgb+
pnm8I1ETTV39EBWZAW2B9cjI9tZT841EJwA3IjFeZAmUJJrA24BzabtfPtlGPpFoDKmwNDcBFRVr
0LbIuDtrU4EvJGqgeukbgPF8j+IlAlQ1eSEdzZk+SCRfSLQcNR+enfdBPMZy5LGuxjNV7wOJWqi8
4zqK0QKdFwI0Ae7teOZ0+ECilSg3tirvgxQAQygAexYe2Y15k2gELbO7BM9EtKcw87m302Fk52kb
5Ukic0Hej/JDNYns0ETB2FfjSUooTxItQS79OQwmUIh9CmEW9xoeUCu0a6tPXphAnuwxRnYe0igv
EjVQzcz12NUT25ZShMCPiEaiITwzWPsgAF6L3P7cpVEm3aNdno4JFMq3aYE2UzdsDMkQxZnytvWy
wAjwi8DXgPvJMVGdx8VuIpf+GuyIccTy70BPaJPq2Fenoi1GC2qOslZpeZBoFfIubBbZzQLP40ai
osNForTQw3gaOc4kyJpEo6hS0dalP4AHOj9juD4I68m5AjIIw/RUaYdYDVCV3q1o/9cgzAAPIpFd
BRsnDh5Fmf776eOE3HHb+al8eJY3x4xesf0mz6GnqybQYGwkx1VYWd2gAHli78XOjZ5Fc37ymsNY
NLSQJDqJHB66VIyxLt7BEuBq1N05CCHwNOp6zXuAVZGwFXm9TyCPNjNkwdoWKvG4BjtSTAPPUrJN
PBnAzMUeJ2MvNQsSjaPyBZsdqCHyyHzamlgkvAY5IplK8LRJNIyk0Fux+2IBKr7yukXGY0yg8uJl
g/4wSaRJogDtHXsndoFFg9obi44GmiiS6VzsRMVeh0E9jBb6Xo6/xAgpn9o8CYVSdhJtmIQz0ry5
K9HAJl+3IEK0bH8UZJkczdzdT+tDmqgz4SL8ftKzItFTZCQVmN808FNkNI4nERJ1Kc1cjSa8etu1
OYejGX3OD3EfBBoHi1D14woyGE2ThiRqoGW5F6T0/kkiq2W+P0H2KYkL0CDR1N39NG7yGlTj4rsU
guxUbZPsqxHGkVOTergkaRK1UDvL6/DbFqoCGqjkZhMdPfxpfFCSWIrYP5Hw+9aIhk1o+UyqBnaS
JDL1Qhcn/L41oqOFSkTG0/yQJG/2MMrdrE/wPWvEg2m9PpkUH+zYlY1tOnYl8FkULbVJtNY2UzaY
Bj4F/BaOYQbbSsik2BmgzPu52JFjimLPYiwSWmhP7jpSkkZJvekwilDbpDhCYA96Qmq4w6Ub2GAr
8HpSmrqSFIlWYD9vOgS+BRxO6LOrhBCVDj/n+LphVFm6mhSkkXM0s0ucwaiyM7FTZdNI7dWlr9Gw
D/gqcCVu1/BcFMW+E/cJ/32RBCtNyYdttr6FuhMKtdOrD6Kol6iqPAAeAb6IuzQyde6Jb2pKgkQr
0HAB2/dqUK1W526II4VPBiaBe3An74Vod26iA7LiksgEGBes464Ysv7ea4GP4G5XBiiTcAUJd8vG
JdEIakb0cjRuhsiaSOehpcMP4iaNmiiOt5UEDey4bzSOvDJv5gcWELO41zW1gFegOiXX156ECJhY
Pi0OiQLgRNxUWd4BxlkPztAOM3spiqE9jCoYn3R8XRN139i0cFkhDolM2cdqh9dU1W7qB7N0OApW
I2/NpczXlM+eR0IaJA6JliDXvmhrNX0icoAexqhnGkKhkh84vm4Ulc8mUrITh0QnoMV2RSj7MLGc
Bn6psyRwOvAN3KXRBUgixb5/Ud+ggbYlb4p7gAzQWTHgkyRKAhPoO77g+LoTUEdI7LLdqCQaQWmO
otVRR4ku+44Gqhl6wPF1LeCNiEyxHiwnErXlzcZR2aWtYZb3jUs0V+QhDBFce9tOR6OgYxnYUSRR
gLyCMxxfkxdM5tsQuWzqDHQfzwSecXzdMpSyitUREoVEDeAU3Ba6DJJEaUqqgPl4TBkJZLAIBR5d
DOwW6sxZ0OToiigkGkHxIVv22pTCpn1zGyhpWWYE6MF2+Z4hUmdbiKHSrEnUZg9N4Oba+/D0B2h4
VtmxCHlptpLdzIM6mxiVBa6SKEAF+VuwI4cv3pAPRM4CJlPv4kg0UNA48qYnVxI10Kbooi24C1Df
fVZTQPLEKG73tYFqjFYTUaW5kmgYDSco4pT7Mcrv6hu4SpQ12GuXY+BKomWIREUs/YiTo+oHH9R1
XIwiZylSHtSVROPYrZdqhy/2SEA6eb4jFF/Cmc1PS4lwv1wuqgkyusyX9oVAaWISeJFiS6QQJWOP
I8KD5mqAbaQ42wm7IQ1JtAw3t9pHBCh1soEIDY5WF3UuRjSKItX1jOmFaCDpXHSpO4rsXed4kcuT
uQRFN2sci4Dik6iBWyZiwQv7oi1SvQzVDxX9YtXoDtPJ/FLQ0XaqmkvD4UYUaKxRXqxD7V9OKs2W
RGNoh/2425lKgUmKbTS7YClqRnWKA9qQqDH3xm+2/PsyIQT2ov6uKhCphbYUJU6iJnL9ipYvSwIB
8+MDq0CiABnXLw3bsLGLbOcJDZPSgKQCoIGCcFVxKE5BTpT197UhkRmqVPTQfhyUwYW3QYCM6zU4
qDRbEh2k2iSqEsZwNK5tDWXfetjTRhmSqlExhCpXrYOOrrmzquAgcCjvQ+SEBnAOKpu1UuG2xBiy
fcOSYAXZbSDyEVvRxJcWdF1FtgC2JIo7vNwHVeh6hio9NJ0YR32FVnbRQBLNTVWPWxXoww0pe8tQ
khhCKs3KLrKVRGUYXO6DNCwKAlTpuAILjtiSaJjiq7OyjDzOChvRaL6B9z0rj8sHEvmgUouE5ag0
ZGCmwqUUJA4Okd3S3hrJoIE6YwdK8Ky8sx9R7/KIg7DtZ1ZS3TQ1Dgx1ZNVP/wzV6D5NE7uAp8jW
NNiARWesLYniNCuGaPhSkbtE8oYZ93wIkSmrlMw4qmZNhERxYAZu1luF4mEYtTqvRH1uWX3mNuba
5ntFrV0M66hitEGMiRM1FiBA13IJ2ZgHxi7qa1y7GNZRSRRSraKuLNAku3kIW1GRWk+uuESso+rh
AOnWmkTFxBp0/3qaIy42URyvoIijaGoISxiwB8TFxS96ArZGNAwhg75n5DoLw7pGd6Ttpr9AMq1O
Tdo8tG5wMaxrJIsGIlIapcfTwG3AduDHCbzfFuShddUoLoZ1rZKSR2PuX9LX9sfAo2gXWhLvvRZ1
x3bli0sAsAw1RVXBCuA3gP8mmTLf5YhETbrEp2xJ5DqpvUa+aKDI9lUkI4nGkJvfNTZlq86mcF8+
UiN/JKUmW4iUsWyiSdz3adUoDxoom9+VL7YkOgA8m9SJahQSa+hh/tiS6BDwfWpXP0mE6LrGNROy
uicr6NH9YUuig8DjVLe1uBvCjp9RsB+4BXh+wN/NAE90+awZYE/MM9iggXaGxFJnU2h1dk2ieQQd
P6O8vgF8BT2k/dBAG6e7Xf+sasKWElOdzQC7qeuk2xH36Q9RPuotyN7oBzNsq/MzzdantAPBZvV6
LBKF6En4YUKHKgPi3rgA5aOutHyvbt5RQLThY1EegGav17mIwmeBhx0/2Jd9Zz4iRHXnyyz/vkVy
qsv1ATBSM1acCFTX+y3cItcBdbqkF6JKkc73yCKnaey3rjaxC4mmgPvQACjXA9QoPkzDxTFwIdEs
8ADqIXNRUUXcjVbjWPSsKXPVsT8AvtHrzXqglkR+IY6NGtsmAtlFX6aOFxUZUR/qnk6SK4mmkXG9
L+JBahQXsQv1DULgMdxVWtKYyvnzfcE02QaAE1FnoAkf/0S+o2KmgW9SnvBB1HjaPqKRKMTNJDGe
WWIkmgW+htIgeWER6sy8j3LYZ1FH96wievmrS/VAQJ+GgigkCtFkin8h3xu4HDgTeDrHMySFqMZu
k4hryHEjkZFcsYON7TgE/CNSbXliBD2NRZ/ClnVjaIDbDMu+mYeoJAqBb6MyhrwNXJM6yPscRYPr
qJ8ZEpZEoJjRF/BjPnRV12h1wsVAd5Vgh+khjeKQaBoZ2N+N8R5JIqCWRmlhFjVEdk2+xy0teBpJ
I1960uoUSzrXIEClQKmQaBL4V+StucDMcaxxLOLEvtJ6iEIkMLo6MEkUOT2Ggo8u7n6A3NM9CXx+
2eDjbMsZNLk2FUkEKjL/e9S54GKTNFCI4HsJnKFGegiRdHySHtojCRKFwE7k7ruK083ADsqTvigj
AtT9vI8UXPx27AduZ3DrSzvMJNRTca+WrJEtdiHvLLG0RzfMAvcC34nw2tMSPEeN7ogT+ghRReuL
pCyJQGWzX8Q9BdGzKa6kyDqWFXcvyzTwv/QJKidJoiPAv+PucZnGuCrgKLIvsiRS3BjeIeB++oRk
klYju1B2P+q+1bJHnE07clZB0SSu5x5U9tMzhJM0iV4E/oHo2f2yR5yHydb+myL+cp9vo87nniSy
UiNzy4R7om1xSIhE39eBSyk/KXzHJNHrjUD20L0MqJ5M46nYD9w56INrHIOkVfkU8TcZ7MeiejQN
Ek0D/4UCkDXskbTUfo54q+iNVtnNAI87ERJ1UXdPA3876MMHwLWYvMY8jEcW5/7OAP+JhX2blpE3
CdyN+xSRdpiSzEOJnCh/ZOl5PofG48WRbvtRvdhLKaletnGansJu4HPEr3/+usXf+D7CZpJk1iPY
Yor41Z47gIewiDOlSaIjyN1/KMZ7hNh9EdPS4iuG0TDxLBASf7/cYeCf0SzJgQ9n2jGLR4C/Iro0
aqE4hw1BfM6/pbG/oxdC3Do5ur3+YeCrWBYOpn3hp4C7UM1QFEnRQGuSbM5Zx6SEnnOELDGN7Nnd
WJoIWTy9jwN/gw4XJR3yMmqCuCCJuvm7cSjrCcIweXu0y+rrbYhI5+BOiFmyGyuXNMzFzfLscbL2
M8AfAzchD+8l9MtaZGVH7AI+S7Ti/CztiSQRIjfZpVAvCcS5Vk8Bn2fwcPYFSIVEXVh7BBXz35vG
53mMx/A79NCOWVQrvxPH8pEsPZo9wK1UZ1vRDAr4Lcn7IJYwcb0Dri/MkkTTqGjtLvyO6SSFEFhH
MVTxFLJZHyBCEVvWsZXn0EKUJ2K+TxHyak3ixWuygunWuZ0IUghSJNEdt53fzTaaRfW6v0f8nNhu
/G41KoIEAt2HW9Hi4UgPZh5R3iOof//viF7/G6ANyX3LNnNGEcISIfBvKMURuf4rDxKFaDjA7wP/
R3TvZRht3rHK79Q4BiFy6W9B3a2RkVe+aRaNpLkJDViPihYau1fm5se0KhSmgM+gGupY0jzPpOUR
VPR0M9HdfrO4ZAS/7aM4SEMthsA9wB0kEHLJO/N9EH2RW4hXk22mjPhqH/mGZ4FPo0xCbCmXSu6s
H7rk1Rpo8+DHgPcQv5hqlvwfDp9xFPgD4HfoUvo6qLOnG3y42LPAXuC3UV123I7NnttwagDahnAb
CVZa+kAimN+qfDMiUlz7xnfXOi/sBz6JynMSe9B8IRFIIj0O/CbJ1GbXWIijqJLiKyQ86tC3QQqG
SDehluztFCN14DvM6J+/pEeZRxRbyMAnSWRgiHQzCkgeoLZx4uJp4HdR7XTiHmzmkqiT8V28NYNn
0Rd/BvgocGKqBysvDgN/hLqSUwnK+qbO2hEiF/TPUVj+42g0X20022Ma5Sn/GpkHCxBHhbUj8zhR
P/SQSmYI1quQ0f1a6uXENgjRlswbUI7yGI83KRL5aBN1IkSexT3AB1CEux6kPhhPotjbTlJOCRWB
RAZHUeXdrwF/SMQCqgogRIHETwD/QQYLfIpEIphfD/AplCbZS+25deII8Ceoa+MYOygNFI1EINLs
A/4MqTdfthz5gGkU8f8MHX1jaaKIJDJ4AbUh3QB8CX82HeWFKUSgj6Nis8wktFfeWS/0iSWBPLfN
KJZ0LdWMcB9GbvwnUXmH1QOVlHfmc5zIFtOoSfBj6AJ+CDg+zwNljP3AnyI7aB85SORCSCKDARKp
gWZEXwH8Olp5XmR13Q8hIst3UG3Q3Ui9OxGolkTHYhYlFz+PukA+ClyMSmfLhBmUCrodJVQfQB5Z
btKgTCQyOIoy1h8GrgPeh/JuRZZKplnzGTR97nNICr2IByGOMpIIRKTHUZPkPcCNaDh7UYxuMx4m
RM2FjyJP9C4keQ7gkTdaVhKBntwXUdT2u8hWuhE4HeXefEzkGlvnMGo1vwf4MsqBPTn3+9wlTyfK
TCKDGdTb9heoqu9twDtQWCBuU0AchG0/j6Lg4E7gf9Dk1u+hnRqT6Dt4Rx6DQnlngzDAewNJnzFg
C3A5cBUqLxklG8lk1NQMUkm7kJTZgdYfPIXIlImhXMpSkKRgQSYQmdYB5wNvAF6J+vsXMV9qErX9
qN2mmUWxrAOIJDuBbyLD+BEkbQ6RQ89cTSJLWBCqiQZRrUUq7gxkN21CQculiHAN5tWfmdBqLp5R
N1NINT2PKjMfR4HQh1HYYS+SNJNzf5vrxa/jRMlhBt3054EHkSE+CiwGlqE40wgiW4t5AhlJY1Z+
zyACTSOSHJz7d2ju97EkTVI3PA38PzvNpgvL3CYCAAAAAElFTkSuQmCC"
            />
        </svg>
    );
});

Urbania.propTypes = propTypes;
Urbania.defaultProps = defaultProps;

export default Urbania;
