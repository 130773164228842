import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import useEndDrawAnimation from '../../hooks/useEndDrawAnimation';

import styles from '../../styles/icons/centered.scss';

const propTypes = {
    ready: PropTypes.bool,
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    ready: false,
    color: 'rgba(255,209,0,0)',
    className: null,
};

const Ouf = ({ ready, color, className }) => {
    const itemsRef = useRef([]);
    useEndDrawAnimation(ready, itemsRef);
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="552.8px"
            height="134.6px"
            viewBox="0 0 552.8 134.6"
            className={classNames([styles.container, { [className]: className !== null }])}
            xmlSpace="preserve"
        >
            <path
                ref={el => {
                    itemsRef.current[0] = el;
                }}
                fill={color}
                stroke={color}
                strokeWidth="2"
                strokeMiterlimit="10"
                d="M28.5,17.9c19.4-6,40.2,3,47,24.9c6.8,21.8-5.3,41-24.7,47c-19.5,6-40.3-3-47.1-24.8
		C-3.1,43.1,9,23.9,28.5,17.9z M46.1,74.7c9.5-3,17.9-12.6,13.4-27C55,33.2,42.7,29.9,33.2,32.9c-9.6,3-17.9,12.7-13.5,27.1
		C24.1,74.4,36.5,77.6,46.1,74.7z"
            />
            <path
                ref={el => {
                    itemsRef.current[1] = el;
                }}
                fill={color}
                stroke={color}
                strokeWidth="2"
                strokeMiterlimit="10"
                d="M97,50.2L95.3,4.1l16.1-0.6l1.7,44.8c0.3,8.8,5.4,13,13,12.7c7.6-0.3,12.4-4.9,12-13.6l-1.7-44.8l16.1-0.6
		l1.7,46.2c0.7,18.1-11.7,28.2-27.6,28.8C110.8,77.4,97.7,68.4,97,50.2z"
            />
            <path
                ref={el => {
                    itemsRef.current[2] = el;
                }}
                fill={color}
                stroke={color}
                strokeWidth="2"
                strokeMiterlimit="10"
                d="M171.5,76.3l13.8-71l45.7,8.9l-2.9,15l-29.9-5.8l-2.9,15l26.3,5.1L218.8,58l-26.2-5.1l-5.2,26.5L171.5,76.3z"
            />
            <path
                ref={el => {
                    itemsRef.current[3] = el;
                }}
                fill={color}
                stroke={color}
                strokeWidth="2"
                strokeMiterlimit="10"
                d="M229.8,89.5l27.1-67l43.2,17.5l-5.7,14.2l-28.3-11.4l-5.7,14.2l24.9,10.1l-5.5,13.6l-24.8-10l-10.1,25
		L229.8,89.5z"
            />
            <path
                ref={el => {
                    itemsRef.current[4] = el;
                }}
                fill={color}
                stroke={color}
                strokeWidth="2"
                strokeMiterlimit="10"
                d="M293.7,116.5l18.4-69.9l45.1,11.9l-3.9,14.8l-29.5-7.8l-3.9,14.8l25.9,6.8l-3.7,14.2l-25.8-6.8l-6.9,26.1
		L293.7,116.5z"
            />
            <path
                ref={el => {
                    itemsRef.current[5] = el;
                }}
                fill={color}
                stroke={color}
                strokeWidth="2"
                strokeMiterlimit="10"
                d="M365.3,131.5l2.1-72.3l46.6,1.3l-0.4,15.3L383,75l-0.4,15.3l26.8,0.8l-0.4,14.7l-26.7-0.8l-0.8,27L365.3,131.5
		z"
            />
            <path
                ref={el => {
                    itemsRef.current[6] = el;
                }}
                fill={color}
                stroke={color}
                strokeWidth="2"
                strokeMiterlimit="10"
                d="M436.6,130.6l-11.3-71.4l46-7.3l2.4,15.1l-30.1,4.8l2.4,15.1l26.5-4.2l2.3,14.5l-26.4,4.2l4.2,26.7
		L436.6,130.6z"
            />
            <path
                ref={el => {
                    itemsRef.current[7] = el;
                }}
                fill={color}
                stroke={color}
                strokeWidth="2"
                strokeMiterlimit="10"
                d="M542.3,81.5l-10.8,3.8l-20.1-45.6l17.5-6.2L542.3,81.5z M538.8,89.1c4.6-1.6,9.7,0.8,11.3,5.4
		c1.6,4.5-0.8,9.6-5.4,11.2c-4.6,1.6-9.6-0.8-11.2-5.3C531.9,95.8,534.2,90.8,538.8,89.1z"
            />
        </svg>
    );
};

Ouf.propTypes = propTypes;
Ouf.defaultProps = defaultProps;

export default Ouf;
