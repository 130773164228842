import isObject from 'lodash/isObject';
import Cookies from 'universal-cookie';

export const pascalCase = str =>
    str
        .replace(/[^a-z0-9]+/gi, ' ')
        .replace(/(\w)(\w*)/g, (g0, g1, g2) => `${g1.toUpperCase()}${g2.toLowerCase()}`)
        .replace(/\s+/gi, '');

export const isMessage = message => isObject(message) && typeof message.id !== 'undefined';

export const getComponentFromName = (name, components, defaultComponent = null) => {
    const componentName = pascalCase(name);
    return components[componentName] || defaultComponent;
};

export const random = seed => {
    const x = Math.sin(seed++) * 10000; // eslint-disable-line
    return x - Math.floor(x);
};

export const shuffle = (array, seed) => {
    let m = array.length;
    let t;
    let i;
    // While there remain elements to shuffle…
    while (m) {
        // Pick a remaining element…
        i = Math.floor(random(seed) * m--); // eslint-disable-line
        // And swap it with the current element.
        t = array[m];
        array[m] = array[i]; // eslint-disable-line
        array[i] = t; // eslint-disable-line
        ++seed; // eslint-disable-line
    }
    return array;
};

export const getPages = (data = {}, reset = false) => {
    const cookies = new Cookies();
    let uid = cookies.get('user_id');
    if (!uid || reset) {
        uid = Math.floor(Math.random() * 100000);
        cookies.set('user_id', uid, { path: '/' });
    }
    const questions = shuffle(data.questions, uid);

    let index = 0;
    return data.pages.map(p => {
        if (p.type === 'students') {
            return { ...p, humans: data.humans };
        }
        if (p.type === 'question') {
            return { ...p, question: questions[index], qid: index, humans: data.humans };
        }
        if (p.type === 'answer') {
            const page = {
                ...p,
                qid: index,
                question: questions[index],
            };
            index += 1;
            return page;
        }
        if (p.type === 'ending') {
            return { ...p, endings: data.endings };
        }
        return p;
    });
};
