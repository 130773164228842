import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from './Button';
import Label from '../partials/Label';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../styles/buttons/circle.scss';

const propTypes = {
    text: AppPropTypes.text,
    href: PropTypes.string.isRequired,
    isActive: PropTypes.bool,
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    text: null,
    isActive: false,
    color: null,
    className: null,
};

const Circle = React.forwardRef(({ text, href, isActive, color, className }, ref) => (
    <div className={classNames([styles.container, { [className]: className !== null }])} ref={ref}>
        <Button
            className={classNames([styles.inner, { [styles.isActive]: isActive }])}
            href={href}
            style={{ color }}
        >
            <span>
                <Label>{text}</Label>
            </span>
        </Button>
    </div>
));

Circle.propTypes = propTypes;
Circle.defaultProps = defaultProps;

export default Circle;
