import React, { useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import anime from 'animejs';

import styles from '../../styles/icons/star.scss';

const propTypes = {
    color: PropTypes.string,
    ready: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    ready: false,
    className: null,
};

const Star = ({ color, ready, className }) => {
    const iconRef = useRef(null);

    useLayoutEffect(() => {
        anime.set(iconRef.current, { fill: 'none', stroke: color, scale: 0.9 });
        if (ready) {
            anime
                .timeline({
                    loop: true,
                    direction: 'alternate',
                    duration: 1000,
                    easing: 'easeInOutSine',
                })
                .add({
                    targets: iconRef.current,
                    scale: 1.12,
                });
        }
        return () => {
            const items = [iconRef.current];
            anime.set(items, { fill: 'none', stroke: color, scale: 0.9 });
            anime.remove(items);
        };
    }, [ready]);

    return (
        <svg
            ref={iconRef}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="361.1px"
            height="358.5px"
            viewBox="0 0 361.1 358.5"
            className={classNames([styles.container, { [className]: className !== null }])}
            xmlSpace="preserve"
        >
            <g>
                <polygon
                    fill={color}
                    points="231.1,79.9 300.9,40.3 270.2,114.5 350.5,111.9 288.8,163.3 361.1,198.3 282.6,215.2 330.3,279.7
            	253,258.2 265.3,337.6 206.8,282.6 180.8,358.5 154.6,282.6 96.2,337.8 108.3,258.4 31,280.2 78.5,215.5 0,198.9 72.2,163.7
            	10.4,112.4 90.6,114.8 59.7,40.7 129.6,80.1 136.7,0.1 180.3,67.5 223.7,0 "
                />
            </g>
        </svg>
    );
};

Star.propTypes = propTypes;
Star.defaultProps = defaultProps;

export default Star;
