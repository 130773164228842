import React, { useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import Background from '../partials/Background';
import Title from '../partials/Title';
import Humans from '../partials/Humans';

import data from '../../data/root';

import { sendEvent } from '../../lib/analytics';

import { setAnswer as setAnswerAction } from '../../actions/SiteActions';

import useWindowSize from '../../hooks/useWindowSize';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../styles/pages/question.scss';

const propTypes = {
    page: AppPropTypes.page.isRequired,
    visible: PropTypes.bool.isRequired,
    current: PropTypes.bool.isRequired,
    ready: PropTypes.bool.isRequired,
};

const QuestionPage = ({
    page: {
        qid,
        question: { question, hid },
        humans,
        color,
    },
    visible,
    current,
    ready,
}) => {
    const backgroundColor = data.colors[color].veryDark;
    const { width } = useWindowSize();
    const dispatch = useDispatch();
    const setAnswer = useCallback((q, value, h) => dispatch(setAnswerAction(q, value, h)), [
        dispatch,
    ]);

    // Scroll back to top
    const innerRef = useRef(null);
    useEffect(() => {
        if (visible && innerRef.current) {
            innerRef.current.scrollTop = 0;
        }
    }, [visible]);

    const setHumanAnswer = useCallback(
        humainId => {
            const success = parseInt(humainId, 10) === parseInt(hid, 10);
            sendEvent('click_answer', 'interactions', `${qid}-${hid}`, success);
            setAnswer(
                qid,
                success, // Do you win
                humans.find(h => h.id === parseInt(hid, 10)), // Always get the real winner
            );
        },
        [hid, setAnswer],
    );

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.visible]: visible && current && ready,
                },
            ])}
        >
            <Background id={qid} color={color} visible={visible} ready={current && ready} />
            <div className={styles.inner} ref={innerRef}>
                <Title
                    title={{ id: 'content.question' }}
                    text={question}
                    color={backgroundColor}
                    visible={visible}
                    ready={current && ready}
                    withQuotes
                />
                <div className={styles.humans}>
                    <Humans
                        items={humans}
                        color={backgroundColor}
                        sideways={width < 860}
                        withHover
                        onClick={setHumanAnswer}
                    />
                </div>
            </div>
        </div>
    );
};

QuestionPage.propTypes = propTypes;

export default QuestionPage;
