/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Dot from '../icons/Dot';

// import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../styles/partials/dots.scss';

const propTypes = {
    items: PropTypes.array, // eslint-disable-line
    className: PropTypes.string,
};

const defaultProps = {
    items: [],
    className: null,
};

const Dots = ({ items, className }) => {
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <div className={styles.inner}>
                {items.map((value, i) => (
                    <div key={`dot-${i + 1}`} className={styles.item}>
                        <Dot {...value} />
                    </div>
                ))}
            </div>
        </div>
    );
};

Dots.propTypes = propTypes;
Dots.defaultProps = defaultProps;

export default Dots;
