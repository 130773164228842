import React, { useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import anime from 'animejs';

import styles from '../../styles/icons/default.scss';

const propTypes = {
    // color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    // color: 'currentColor',
    className: null,
};

const Arrows = ({ className }) => {
    const firstRef = useRef(null);
    const secondRef = useRef(null);

    useLayoutEffect(() => {
        anime.set(firstRef.current, { stroke: 'rgba(255,255,255,1)' });
        anime.set(secondRef.current, { fill: 'rgba(255,255,255,1)' });
        anime
            .timeline({
                loop: true,
                duration: 320,
                direction: 'alternate',
                easing: 'linear',
            })
            .add({
                targets: firstRef.current,
                stroke: 'rgba(255,255,255,1)',
            })
            .add({
                targets: secondRef.current,
                fill: 'rgba(255,255,255,1)',
            })
            .add({
                targets: firstRef.current,
                stroke: 'rgba(255,255,255,0)',
                fill: 'rgba(255,255,255,1)',
            })
            .add({
                targets: secondRef.current,
                fill: 'rgba(255,255,255,0)',
                stroke: 'rgba(255,255,255,1)',
            });
        return () => {
            anime.remove([firstRef.current, secondRef.current]);
        };
    }, []);

    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="31.9px"
            height="29.4px"
            viewBox="0 0 31.9 29.4"
            className={classNames([styles.container, { [className]: className !== null }])}
            xmlSpace="preserve"
        >
            <g>
                <polygon
                    ref={firstRef}
                    fill="none"
                    // stroke={color}
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    points="1.4,28.6 11,14.7 1.4,0.8 8.2,0.8 17.9,14.7 8.2,28.6 "
                />
                <polygon
                    ref={secondRef}
                    // fill={color}
                    stroke="none"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    points="15.4,28.5 25,14.6 15.4,0.7 22.2,0.7 31.9,14.6 22.2,28.5 "
                />
            </g>
        </svg>
    );
};

Arrows.propTypes = propTypes;
Arrows.defaultProps = defaultProps;

export default Arrows;
