import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import MainLayout from './layouts/Main';
import PageComponents from './pages/index';
import ErrorPage from './pages/Error';

import { setPage as setPageAction } from '../actions/SiteActions';
import { setSize as setSizeAction } from '../actions/LayoutActions';

import useWindowSize from '../hooks/useWindowSize';

import styles from '../styles/pages.scss';

const propTypes = {
    pages: PropTypes.array.isRequired, // eslint-disable-line
    location: PropTypes.object.isRequired, // eslint-disable-line
    setPage: PropTypes.func.isRequired,
    setSize: PropTypes.func.isRequired,
};

const defaultProps = {};

const Pages = ({ pages, location, setPage, setSize }) => {
    const size = useWindowSize();
    setSize(size);
    const { width, height } = size;
    const url = location.pathname.replace(/^\/|\/$/g, '');
    const page = pages[url] || pages.find(p => p.slug === url) || pages[0];
    const [currentPage, setCurrentPage] = useState(page);

    useEffect(() => {
        let timeout = null;
        if (page) {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                if (window.scrollTo) {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }
                setCurrentPage(page);
            }, 300);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [page]);

    useEffect(() => {
        setPage(url, page);
    }, [url]);

    // console.log(currentPage.id === page.id);
    // console.log(page.id, 'cp', currentPage, 'p', page); // eslint-disable-line

    return (
        <MainLayout page={currentPage || null} inTransition={page.id !== currentPage.id}>
            <div className={styles.container}>
                {pages.map((p, i) => {
                    let Component = null;
                    let style = {
                        zIndex: i,
                    };
                    const { type } = p;
                    if (type) {
                        Component = PageComponents[type];
                    }
                    if (pages[i + 1]) {
                        style = {
                            ...style,
                            borderColor: pages[i + 1].color,
                        };
                    }
                    const boxShadowSize = 5;
                    const centered = i === page.id;
                    // const half = i === 1;
                    const hidden = i === 1;
                    const reverse = i === 1 || p.type === 'answer';
                    const topOffset = hidden ? 0 : 10;

                    const after = i > page.id;
                    const afterWidth = reverse ? -width + topOffset : width - 30;
                    const afterHeight = reverse ? -height + topOffset : height - 20;
                    const altWidth = after ? afterWidth : 0 - 20;
                    const altHeight = after ? afterHeight : 0 - 20;
                    const desktop = width >= 860;
                    const boxShadowMobile =
                        i === page.id + 1 || centered
                            ? `0 ${
                                  reverse ? boxShadowSize : -boxShadowSize
                              }px 8px rgba(0, 0, 0, 0.3)`
                            : null;
                    const boxShadowDesktop =
                        i === page.id + 1 || centered
                            ? `${
                                  reverse ? boxShadowSize : -boxShadowSize
                              }px 0 8px rgba(0, 0, 0, 0.3)`
                            : null;
                    const defaultZIndex = centered ? 1 : 0;
                    const zIndex = i === page.id + 1 ? 2 : defaultZIndex;
                    const display =
                        i > currentPage.id - 2 && i < currentPage.id + 2 ? 'block' : 'none';

                    style = {
                        ...style,
                        width: desktop && !hidden ? `calc(100% + 20px)` : '100%',
                        height: desktop || hidden ? '100%' : `calc(100% + 20px)`,
                        boxShadow: desktop ? boxShadowDesktop : boxShadowMobile,
                        zIndex,
                        display,
                        visibility: display === 'none' ? 'hidden' : 'visible',
                        transform: desktop
                            ? `translate3d(${centered ? 0 - 20 : altWidth}px, 0px, 0px)`
                            : `translate3d(0px, ${centered ? 0 - 20 : altHeight}px, 0px)`,
                    };

                    return (
                        <div
                            className={classNames([styles.page, styles[p.color]])}
                            key={`page-${p.id}`}
                            style={style}
                        >
                            {Component !== null ? (
                                <Component
                                    url={url}
                                    page={p}
                                    visible={display !== 'none'}
                                    current={p.id === currentPage.id}
                                    ready={currentPage.id === page.id && p.id === currentPage.id}
                                />
                            ) : (
                                <ErrorPage statusCode={404} />
                            )}
                        </div>
                    );
                })}
            </div>
        </MainLayout>
    );
};

Pages.propTypes = propTypes;
Pages.defaultProps = defaultProps;

const WithStateContainer = connect(
    ({ site }) => ({
        pages: site.pages,
    }),
    dispatch => ({
        setPage: (url, page) => dispatch(setPageAction(url, page)),
        setSize: size => dispatch(setSizeAction(size)),
    }),
)(Pages);
export default WithStateContainer;
