import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import useEndDrawAnimation from '../../hooks/useEndDrawAnimation';

import styles from '../../styles/icons/centered.scss';

const propTypes = {
    ready: PropTypes.bool,
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    ready: false,
    color: 'rgba(255,209,0,0)',
    className: null,
};

const Yoohoo = ({ ready, color, className }) => {
    const itemsRef = useRef([]);
    useEndDrawAnimation(ready, itemsRef);
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="772.7px"
            height="150.7px"
            viewBox="0 0 772.7 150.7"
            className={classNames([styles.container, { [className]: className !== null }])}
            xmlSpace="preserve"
        >
            <path
                ref={el => {
                    itemsRef.current[0] = el;
                }}
                fill={color}
                stroke={color}
                strokeWidth="2"
                strokeMiterlimit="10"
                d="M21.6,81.4l16.1-9.8l26.6,15.2l-1.2-30.7l15.3-9.3l0.2,49.2l15.7,25.8l-13.8,8.4l-15.7-25.8L21.6,81.4z"
            />
            <path
                ref={el => {
                    itemsRef.current[1] = el;
                }}
                fill={color}
                stroke={color}
                strokeWidth="2"
                strokeMiterlimit="10"
                d="M128.9,25.5c19.2-6.5,40.3,2,47.6,23.7c7.3,21.6-4.3,41.2-23.5,47.7c-19.3,6.5-40.4-2-47.7-23.6
		C98,51.6,109.6,32.1,128.9,25.5z M148,81.9c9.5-3.2,17.6-13,12.7-27.3c-4.8-14.3-17.3-17.3-26.7-14.1c-9.6,3.2-17.6,13.2-12.8,27.5
		C126,82.2,138.4,85.1,148,81.9z"
            />
            <path
                ref={el => {
                    itemsRef.current[2] = el;
                }}
                fill={color}
                stroke={color}
                strokeWidth="2"
                strokeMiterlimit="10"
                d="M198.2,56.1l-3-46.1l16.1-1l2.9,44.8c0.6,8.8,5.7,12.8,13.4,12.3c7.6-0.5,12.2-5.2,11.7-13.9l-2.9-44.8l16.1-1
		l3,46.1c1.2,18.1-10.9,28.5-26.8,29.5C212.7,83,199.4,74.3,198.2,56.1z"
            />
            <path
                ref={el => {
                    itemsRef.current[3] = el;
                }}
                fill={color}
                stroke={color}
                strokeWidth="2"
                strokeMiterlimit="10"
                d="M317.1,88.1l5.6-28.2l-28.3-5.6l-5.6,28.2L273,79.3l14.1-70.9l15.8,3.1l-5.5,27.8l28.3,5.6l5.5-27.8l15.9,3.2
		L333,91.3L317.1,88.1z"
            />
            <path
                ref={el => {
                    itemsRef.current[4] = el;
                }}
                fill={color}
                stroke={color}
                strokeWidth="2"
                strokeMiterlimit="10"
                d="M409.3,43.6c18.6,8.2,28.4,28.6,19.2,49.6c-9.2,20.9-30.9,27.5-49.5,19.3c-18.7-8.2-28.5-28.7-19.3-49.6
		C368.9,42,390.7,35.4,409.3,43.6z M385.4,98c9.1,4,21.8,2.2,27.8-11.6C419.3,72.6,412.2,62,403,58c-9.2-4.1-21.9-2.2-28,11.7
		C369,83.5,376.2,94,385.4,98z"
            />
            <path
                ref={el => {
                    itemsRef.current[5] = el;
                }}
                fill={color}
                stroke={color}
                strokeWidth="2"
                strokeMiterlimit="10"
                d="M446.1,106.5l4.6-46l16,1.6l-4.5,44.6c-0.9,8.7,3.6,13.6,11.2,14.3c7.6,0.8,12.9-3.1,13.8-11.8l4.5-44.6
		l16,1.6l-4.6,46c-1.8,18.1-15.5,26.3-31.3,24.7C456,135.3,444.3,124.5,446.1,106.5z"
            />
            <path
                ref={el => {
                    itemsRef.current[6] = el;
                }}
                fill={color}
                stroke={color}
                strokeWidth="2"
                strokeMiterlimit="10"
                d="M527.3,111.3l-6.4-45.7l16-2.2l6.2,44.4c1.2,8.7,6.7,12.4,14.2,11.3c7.6-1.1,11.8-6.1,10.6-14.8l-6.2-44.4
		l16-2.2l6.4,45.8c2.5,18-8.8,29.2-24.5,31.4C543.8,137,529.8,129.3,527.3,111.3z"
            />
            <path
                ref={el => {
                    itemsRef.current[7] = el;
                }}
                fill={color}
                stroke={color}
                strokeWidth="2"
                strokeMiterlimit="10"
                d="M606.4,97.9L591.9,54l15.3-5l14.1,42.6c2.8,8.3,8.8,11,16,8.6c7.3-2.4,10.5-8.1,7.8-16.4L631,41.1l15.3-5.1
		l14.5,43.9c5.7,17.2-3.4,30.3-18.5,35.3C627.2,120.2,612,115.1,606.4,97.9z"
            />
            <path
                ref={el => {
                    itemsRef.current[8] = el;
                }}
                fill={color}
                stroke={color}
                strokeWidth="2"
                strokeMiterlimit="10"
                d="M724.6,52.9l-10.1,5.4l-26.7-42l16.3-8.8L724.6,52.9z M722.4,61c4.3-2.3,9.7-0.7,12,3.6
		c2.3,4.2,0.7,9.6-3.6,11.9c-4.3,2.3-9.6,0.7-11.9-3.6C716.6,68.6,718.1,63.3,722.4,61z"
            />
        </svg>
    );
};

Yoohoo.propTypes = propTypes;
Yoohoo.defaultProps = defaultProps;

export default Yoohoo;
