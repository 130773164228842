import React, { useRef, useLayoutEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import anime from 'animejs';

import styles from '../../styles/icons/tetris.scss';

const propTypes = {
    id: PropTypes.number.isRequired,
    ready: PropTypes.bool,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    palette: PropTypes.object, // eslint-disable-line
    className: PropTypes.string,
};

const defaultProps = {
    ready: true,
    palette: {},
    className: null,
};

const Tetris = ({ id, ready, width, height, palette, className }) => {
    const [running, setRunning] = useState(false);
    const [animeTimeline, setTimeline] = useState(null);

    const tRef = useRef(null);
    const offTRef = useRef(null);
    const otherTRef = useRef(null);

    const params = useMemo(() => {
        const random = Math.random();
        const direction = random > 0.5 ? -1 : 1;

        const initialColors = {
            tRef: random < 0.33 ? palette.dark : palette.medium,
            offTRef: random >= 0.33 && random <= 0.66 ? palette.medium : palette.dark,
            otherTRef: random > 0.66 ? palette.dark : palette.veryDark,
        };

        const destinationColors = {
            tRef: random > 0.5 ? palette.light : palette.dark,
            offTRef: random > 0.5 ? palette.dark : palette.light,
            otherTRef: palette.dark,
        };

        return {
            random,
            direction,
            initialColors,
            destinationColors,
        };
    }, [id]);

    // console.log(id, params);

    useLayoutEffect(() => {
        // console.log('SET');
        anime.set(tRef.current, { fill: params.initialColors.tRef, translateX: 0, translateY: 0 });
        anime.set(offTRef.current, {
            fill: params.initialColors.offTRef,
            translateX: 0,
            translateY: 0,
        });
        anime.set(otherTRef.current, {
            fill: params.initialColors.otherTRef,
            translateX: 0,
            translateY: 0,
        });
    });

    // console.log('READY', ready, color);

    useLayoutEffect(() => {
        if (ready) {
            // console.log('ok');
            setRunning(true);
        } else {
            // console.log('not ok');
            setRunning(false);
        }
        return () => {
            // console.log('down');
            setRunning(false);
        };
    }, [ready, width, height, setRunning]);

    useLayoutEffect(() => {
        if (running && animeTimeline === null) {
            // console.log('run it baby!', running);

            const timeline = anime.timeline({
                loop: true,
                duration: 20000,
                direction: 'alternate',
                easing: 'easeOutSine',
                // delay: 300,
            });
            setTimeline(timeline);

            timeline
                .add(
                    {
                        targets: tRef.current,
                        fill: params.destinationColors.tRef,
                        translateX: Math.random() * width * params.direction,
                    },
                    0,
                )
                .add(
                    {
                        targets: offTRef.current,
                        fill: params.destinationColors.offTRef,
                        translateX: -Math.random() * width * params.direction,
                    },
                    0,
                )
                .add(
                    {
                        targets: otherTRef.current,
                        fill: params.destinationColors.otherTRef,
                        translateY: -Math.random() * height * params.direction,
                    },
                    0,
                );
        }

        return () => {
            const items = [tRef.current, offTRef.current, otherTRef.current];
            anime.set(items, { translateX: 0, translateY: 0 });
            anime.remove(items);
            setTimeline(null);
        };
    }, [running, setTimeline]);

    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width={`${width}px`}
            height={`${height}px`}
            viewBox="0 0 910.3 608.8"
            className={classNames([styles.container, { [className]: className !== null }])}
            xmlSpace="preserve"
        >
            <polygon
                ref={tRef}
                fill={params.initialColors.tRef}
                points="0,0 0,304.4 301.7,304.4 301.7,608.8 608.2,608.8 608.2,304.4 910.3,304.4 910.3,0 "
            />
            <polygon
                ref={otherTRef}
                fill={params.initialColors.tRef}
                points="0,0 0,304.4 301.7,304.4 301.7,608.8 608.2,608.8 608.2,304.4 910.3,304.4 910.3,0 "
            />
            <polygon
                ref={offTRef}
                fill={params.initialColors.offTRef}
                points="0,608.8 0,304.4 301.7,304.4 301.7,0 608.2,0 608.2,304.4 910.3,304.4 910.3,608.8 "
            />
        </svg>
    );
};

Tetris.propTypes = propTypes;
Tetris.defaultProps = defaultProps;

export default Tetris;
