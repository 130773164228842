import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Tetris from '../icons/Tetris';

import useWindowSize from '../../hooks/useWindowSize';

import data from '../../data/root';

// import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../styles/partials/background.scss';

const propTypes = {
    id: PropTypes.number.isRequired,
    color: PropTypes.string,
    visible: PropTypes.bool,
    ready: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    color: null,
    visible: false,
    ready: false,
    className: null,
};

const Background = ({ id, color, visible, ready, className }) => {
    const { width, height } = useWindowSize();
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <div className={styles.overlay} />
            {visible ? (
                <Tetris
                    className={styles.tetris}
                    id={id}
                    width={width}
                    height={height}
                    color={data.colors[color].light}
                    palette={data.colors[color]}
                    ready={ready}
                />
            ) : null}
        </div>
    );
};

Background.propTypes = propTypes;
Background.defaultProps = defaultProps;

export default Background;
