import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import Background from '../partials/Background';
import FlippingHuman from '../partials/FlippingHuman';
import TextBlock from '../partials/Text';
import Next from '../partials/Next';

import Bravo from '../icons/Bravo';
import Faux from '../icons/Faux';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../styles/pages/answer.scss';

const propTypes = {
    page: AppPropTypes.page.isRequired,
    visible: PropTypes.bool.isRequired,
    current: PropTypes.bool.isRequired,
    ready: PropTypes.bool.isRequired,
};

const AnswerPage = ({
    page: {
        id,
        qid,
        color,
        question: { answer, images },
    },
    visible,
    current,
    ready,
}) => {
    const answers = useSelector(state => state.site.answers);
    const userAnswer = answers.find(a => a.qid === qid);

    // Scroll back to top
    const innerRef = useRef(null);
    useEffect(() => {
        if (visible && innerRef.current) {
            innerRef.current.scrollTop = 0;
        }
    }, [visible]);

    let text = answer;
    if (userAnswer) {
        text =
            userAnswer && userAnswer.value
                ? `En effet! C'est ${userAnswer.human.name}.<br /><br />«&nbsp;${answer}&nbsp;»`
                : `Hé non! C'est ${userAnswer.human.name}.<br /><br />«&nbsp;${answer}&nbsp;»`;
    }
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.visible]: visible && current && ready,
                },
            ])}
        >
            <Background id={id} color={color} visible={visible} ready={current && ready} />
            <div className={styles.inner} ref={innerRef}>
                <div className={styles.content}>
                    <div className={styles.col}>
                        <div className={styles.banner}>
                            {userAnswer && userAnswer.value ? (
                                <Bravo id={id} ready={current && ready} />
                            ) : (
                                <Faux id={id} ready={current && ready} />
                            )}
                        </div>
                        <TextBlock
                            className={styles.text}
                            text={text}
                            withTopBar
                            withQuotes={!userAnswer}
                        />
                    </div>
                    <div className={styles.col}>
                        <FlippingHuman
                            img={images}
                            visible={visible && current}
                            ready={visible && current && ready}
                        />
                    </div>
                </div>
                <Next />
            </div>
        </div>
    );
};

AnswerPage.propTypes = propTypes;

export default AnswerPage;
