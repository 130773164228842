import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Label from './Label';
import Arrows from '../icons/Arrows';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../styles/partials/next.scss';

const propTypes = {
    text: AppPropTypes.text,
    to: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
};

const defaultProps = {
    text: 'Suivant',
    to: null,
    onClick: null,
    className: null,
};

const NextButton = ({ text, to, onClick, className }) => {
    const page = useSelector(state => state.site.page);
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <div className={styles.inner}>
                <Link className={styles.link} to={`/${to || page.id + 1}`} onClick={onClick}>
                    <span className={styles.text}>
                        <Label>{text}</Label>
                    </span>
                    <Arrows />
                </Link>
            </div>
        </div>
    );
};

NextButton.propTypes = propTypes;
NextButton.defaultProps = defaultProps;

export default NextButton;
