import Answer from './Answer';
import Ending from './Ending';
import Error from './Error';
import Home from './Home';
import Intro from './Intro';
import Question from './Question';
import Students from './Students';

export default {
    answer: Answer,
    ending: Ending,
    error: Error,
    home: Home,
    intro: Intro,
    question: Question,
    students: Students,
};
