import React, { useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import anime from 'animejs';

import yellow from '../../img/humans/soleil/yellow.png';
import pink from '../../img/humans/soleil/pink.png';

// import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../styles/partials/animated-human.scss';

const propTypes = {
    img: PropTypes.object, // eslint-disable-line
    hover: PropTypes.bool,
    withFlash: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    img: null,
    hover: false,
    withFlash: false,
    className: null,
};

const AnimatedHuman = ({ img, hover, withFlash, className }) => {
    const humanRef = useRef(null);
    const pinkRef = useRef(null);
    const yellowRef = useRef(null);

    useLayoutEffect(() => {
        if (hover) {
            const direction = Math.random() > 0.5 ? 1 : -1;
            anime
                .timeline({
                    easing: 'spring(2, 900, 1, 1)',
                })
                .add({
                    targets: humanRef.current,
                    translateX: Math.random() * 8 * direction,
                })
                .add({
                    targets: humanRef.current,
                    translateX: 0,
                });
        } else {
            anime.remove([humanRef.current]);
        }
        return () => {
            anime.remove([humanRef.current]);
        };
    }, [hover]);

    useLayoutEffect(() => {
        if (withFlash) {
            anime
                .timeline({
                    loop: true,
                    duration: 600,
                    delay: Math.random() * 500,
                    direction: 'linear',
                })
                .add({
                    targets: [pinkRef.current],
                    opacity: 1,
                    scale: 1.1,
                })
                .add({
                    targets: [pinkRef.current],
                    opacity: 0,
                    scale: 0,
                })
                .add({
                    targets: [yellowRef.current],
                    opacity: 1,
                    scale: 1.1,
                })
                .add({
                    targets: [yellowRef.current],
                    opacity: 0,
                    scale: 0,
                });
        } else {
            anime.remove([pinkRef.current, yellowRef.current]);
        }
        return () => {
            anime.remove([pinkRef.current, yellowRef.current]);
        };
    }, [withFlash]);

    return (
        <div
            ref={humanRef}
            className={classNames([
                styles.container,
                {
                    [styles.withFlash]: withFlash,
                    [className]: className !== null,
                },
            ])}
        >
            <img className={styles.human} src={hover ? img.hover : img.normal} alt="human" />
            <img ref={yellowRef} className={styles.background} src={yellow} alt="yellowsun" />
            <img ref={pinkRef} className={styles.background} src={pink} alt="yellowsun" />
        </div>
    );
};

AnimatedHuman.propTypes = propTypes;
AnimatedHuman.defaultProps = defaultProps;

export default AnimatedHuman;
