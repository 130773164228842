import React, { useRef, useEffect, useLayoutEffect, useCallback } from 'react';
import anime from 'animejs';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';

import LogoBig from '../icons/LogoBig';
import Urbania from '../icons/Urbania';

import Rat from '../../img/home/rat.png';
import Ketchup from '../../img/home/ketchup.png';
import Margarita from '../../img/home/margarita.png';

import { resetQuiz as resetQuizAction } from '../../actions/SiteActions';

import styles from '../../styles/pages/home.scss';

const propTypes = {
    ready: PropTypes.bool.isRequired,
};

const HomePage = ({ ready }) => {
    const dispatch = useDispatch();
    const resetQuiz = useCallback((q, value, h) => dispatch(resetQuizAction(q, value, h)), [
        dispatch,
    ]);

    const logoRef = useRef(null);
    const urbaniaRef = useRef(null);
    const ratRef = useRef(null);
    const ketchupRef = useRef(null);
    const margaritaRef = useRef(null);

    const history = useHistory();

    // Always reset on home page
    useEffect(() => {
        resetQuiz();
    }, [ready]);

    useLayoutEffect(() => {
        anime.set(logoRef.current, { bottom: '2000px', rotate: -90 });
        anime.set(urbaniaRef.current, { bottom: '0%', left: '-30%', rotate: -360 });

        anime.set(margaritaRef.current, {
            translateX: '1000px',
            opacity: 1,
            rotate: 30,
        });
        anime.set(ketchupRef.current, {
            translateX: '-1000px',
            opacity: 1,
            rotate: -120,
        });
        anime.set(ratRef.current, { translateY: '-1000px', top: 0, opacity: 1, rotate: -10 });
        const easing = 'spring(1, 300, 20, 10)';
        if (ready) {
            anime
                .timeline({
                    loop: false,
                    delay: 1500,
                    easing: 'spring(1, 300, 8, 8)',
                    complete: () => {
                        history.push('/1');
                    },
                })
                .add(
                    {
                        targets: urbaniaRef.current,
                        left: '50%',
                        rotate: 0,
                        easing: 'spring(1, 300, 8, 8)',
                    },
                    0,
                )
                .add(
                    {
                        targets: logoRef.current,
                        bottom: 0,
                        rotate: 0,
                        easing,
                    },
                    300,
                )
                .add(
                    {
                        targets: margaritaRef.current,
                        translateX: '20px',
                        opacity: 1,
                        rotate: 0,
                        easing,
                    },
                    1500,
                )
                .add(
                    {
                        targets: ketchupRef.current,
                        translateX: 0,
                        opacity: 1,
                        rotate: -50,
                        easing,
                    },
                    1500,
                )
                .add(
                    {
                        targets: ratRef.current,
                        translateY: 0,
                        translateX: '120px',
                        opacity: 1,
                        rotate: 0,
                        easing,
                    },
                    1800,
                )
                .add({
                    duration: 1400,
                });
        }
        return () => {
            anime.remove([
                logoRef.current,
                urbaniaRef.current,
                margaritaRef.current,
                ketchupRef.current,
                ratRef.current,
            ]);
        };
    }, [ready]);
    return (
        <div className={styles.container}>
            <Link className={styles.link} to="/1">
                <LogoBig ref={logoRef} className={styles.logo} />
                <Urbania ref={urbaniaRef} ready={ready} className={styles.urbania} />
                <img ref={ratRef} src={Rat} className={styles.rat} alt="rat" />
                <img ref={ketchupRef} src={Ketchup} className={styles.ketchup} alt="ketchup" />
                <img
                    ref={margaritaRef}
                    src={Margarita}
                    className={styles.margarita}
                    alt="margarita"
                />
            </Link>
        </div>
    );
};

HomePage.propTypes = propTypes;

export default HomePage;
