/* eslint-disable react/jsx-props-no-spreading, react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Label from './Label';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../styles/partials/text.scss';

const propTypes = {
    text: AppPropTypes.text,
    withTopBar: PropTypes.bool,
    withBottomBar: PropTypes.bool,
    withQuotes: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    text: null,
    withTopBar: false,
    withBottomBar: false,
    withQuotes: false,
    className: null,
};

const Text = ({ text, withTopBar, withBottomBar, withQuotes, className }) => {
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            {withTopBar ? <div className={styles.topBar} /> : null}
            <p className={styles.text}>
                <span
                    className={styles.quotes}
                    dangerouslySetInnerHTML={{ __html: withQuotes ? '«&nbsp;' : null }}
                />
                <Label isHtml>{text}</Label>
                <span
                    className={styles.quotes}
                    dangerouslySetInnerHTML={{ __html: withQuotes ? '&nbsp;»' : null }}
                />
            </p>
            {withBottomBar ? <div className={styles.bottomBar} /> : null}
        </div>
    );
};

Text.propTypes = propTypes;
Text.defaultProps = defaultProps;

export default Text;
